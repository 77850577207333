import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getSearchState = (state) => ({
  state: state.search,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.search) {
      return state.search.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});

export const getConfiguredSearchFields = createSelector(
  [getSearchState],
  (search) => {
    const records = search?.getIn(['search', 'configuredFields'], List());
    return { data: records };
  }
);

export const getAllSearchableFields = createSelector(
  [getSearchState],
  (search) => {
    return search.getIn(['search', 'allSearchableFields', 'fields'], List());
  }
);
