import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from 'src/component/UI/Button';
import { FormHelperText, InputLabel } from '@mui/material';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';
import { createDeviceGroup } from 'src/module/groupDevices/action';
import { useLocation } from "react-router-dom";

export default function CreateGroupModal ({ deviceIds, open, handleClose }) {
  const dispatch: any = useDispatch();
  const [error, setError] = useState(false);
  const [groupName, setGroupName] = useState('');
  const location = useLocation();
  const createGroup = () => {
    if (groupName != '') {
      const redirect = location.pathname !== '/program/list/wizard';
      dispatch(createDeviceGroup(groupName, [deviceIds], redirect));
      handleClose();
      setGroupName('');
    } else {
      setError(true);
    }

    return;
  };
  const devicesCol = (devices, showAll = false) => {
    if (!devices || !devices.length) {
      return [];
    }
    if (devices.size > 10  && !showAll) {
      const first10 = devices.slice(0, 10);
      return <>
        {first10.map((device, index) => (<div key={index} className='groups-list'><DeviceLinkCombo deviceId={device} /></div>))}
        <>... plus {devices.size-10} more</>
      </>;
    }
    return devices.map((device, index) => (<div key={index} className='groups-list'><DeviceLinkCombo deviceId={device} /></div>));
  };
  const handleGroupNameChange = (event) => {
    setGroupName(event);
    if (event === '') {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <Modal
      data-testid='create-device-group-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby='create-device-group-modal'
    >
      <div className='modal confirm-modal'>
        <h2 id='confirm-modal-title'>Device Group</h2>
        <form onSubmit={createGroup}>
          <div>
            <div>
              <TextField required  label={'Device Group Name'} fullWidth value={groupName} error={error} onChange={(e) => { handleGroupNameChange(e.target.value);}}  />
              {error && <FormHelperText error={Boolean(error)}>This field is required</FormHelperText>}
            </div>
            <div style={{ paddingTop: '5px' }}>
              <InputLabel>Devices</InputLabel>
              <div style={{ height: '170px', overflowY: 'auto' }}>{devicesCol(deviceIds, true)}</div>
            </div>
          </div>

          <div className='button-bar'>
            <Button cta onClick={createGroup} >Save Group</Button>
          </div>
        </form>




      </div>

    </Modal>
  );
}

CreateGroupModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  deviceIds: PropTypes.array.isRequired,
};

CreateGroupModal.defaultProps = {

};
