import './RadialGauge.scss';
import React from "react";
import * as d3 from "d3-shape";
import { scaleLinear } from "d3-scale";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { calcDisplayRange, calcMinAndMax, colorForGauge, getGaugeStatusDisplayValue } from 'src/utils/gaugeHelperFunctions';
import { Badge } from '@mui/material';

export default function LinearGauge (props) {
  const {
    title,
    units,
    theme,
    settingsConfig,
    openSetting,
    onSetPointClick,
    numberOfSettingDiffs,
    disableHamburger
  } = props;
  let {
    value,
    rangeMin,
    rangeMax,
    rangeTrip,
    setPointValue
  } = props;

  if (value > 99) {
    value = Math.ceil(value);
  }
  if (rangeMax > 99) {
    rangeMax = Math.ceil(rangeMax);
  }
  if (rangeMin > 99) {
    rangeMin = Math.ceil(rangeMin);
  }
  if (rangeTrip > 99) {
    rangeTrip = Math.ceil(rangeTrip);
  }
  if (setPointValue > 99) {
    setPointValue = Math.ceil(setPointValue);
  }
  const displayRangeMin = calcDisplayRange(settingsConfig, rangeMin, 'intialLowSetpointEnabled');
  const displayRangeMax = calcDisplayRange(settingsConfig, rangeMax, 'intialHighSetpointEnabled');
  const displayTripMax = calcDisplayRange(settingsConfig, rangeTrip, 'intialTripSetpointEnabled');
  const [max, min, values] = calcMinAndMax(displayRangeMin, displayRangeMax, value, setPointValue, rangeTrip);

  const Gen = d3.area()
    .x((p) => p.x)
    .y0((p) => p.y)
    .y1((p) => p.y1);

  const createBar = (start, end) => {
    return Gen([{ x: 60, y: start, y1: end }, { x: 90, y: start, y1: end }]);
  };

  const offSet = min === 0 ? 0 : 10;
  const startingVal = 200;
  const percentScale = scaleLinear()
    .domain([min, max])// MAX ,MIN
    .range([offSet, startingVal]);
  const calcValue = (val) => {
    return startingVal - percentScale(val);
  };
  const upperRange = displayRangeMax || displayRangeMax === 0 || displayRangeMax === 0 ? calcValue(displayRangeMax) : null;
  const lowerRange = displayRangeMin || displayRangeMin === 0 || displayRangeMin === 0 ? calcValue(displayRangeMin) : null;
  const tripRange = displayTripMax || displayTripMax === 0 || displayTripMax === 0 ? calcValue(displayTripMax) : null;
  const setPoint = setPointValue  || setPointValue === 0 ? calcValue(setPointValue) : null;
  const currentValue = calcValue(value);
  const rangeColors = colorForGauge(theme, values, lowerRange);
  const badgeStyle = {
    "& .MuiBadge-badge": {
      backgroundColor: '#fcd54c',
    }
  };

  const getDisplayValue = (val) => {
    return Number.isInteger(val) ? val : val?.toFixed(1);
  };

  const locationOfUnits = (value, units) => {
    let spacing = (value?.toString().length * 5) + 140;
    if (units?.includes('°')) {
      spacing = spacing - 5;
    }

    return spacing.toString();
  };

  const locationOfSetpointText = (val) => {
    return 15 - val?.toString().length;
  };

  const locationOfText = (val) => {
    let spacing = 45 - (val?.toString().length * 5);
    if (val?.toString().includes('.')) {
      spacing = spacing + 3;
    }
    return spacing.toString();
  };

  return (
    <div className='radial-gauge-container' data-testid={`gauge-${title}`}>
      {!disableHamburger && (displayRangeMin !== null || displayRangeMax !== null || settingsConfig.canDisableAllAlarms) && value !== null ?
        <span
          className='radial-gauge-hamburger'
          onClick={() => openSetting(SettingsModalTypes[`${settingsConfig.type}_MULTIPLE`])}>
          {numberOfSettingDiffs ? <Badge sx={badgeStyle} badgeContent={numberOfSettingDiffs} className='alarmBadge' >
            <div style={{ padding: '2px' }}>
              <MoreVertIcon fontSize='large' />
            </div>
          </Badge> :
            <MoreVertIcon fontSize='large' />
          }
        </span>
        :
        null}
      {settingsConfig.toolTip ? <div className='gauge-info' > <Tooltip title={settingsConfig.toolTip}><InfoIcon style={{ fontSize: 20 }} /></Tooltip></div> : null}

      <p className='radial-gauge-title'>{title}</p>
      <div style={{ paddingLeft: '40px' }}>
        <svg width="200" height="220">
          {/* background */}
          <path
            d={createBar(startingVal, offSet)}
            className='gauge-background'
            strokeOpacity={0.5}
            strokeWidth={0.5}
            fill={'grey'}
          />
          {/* bars */}
          {tripRange ?
            <path
              d={createBar(tripRange ?? (upperRange ?? startingVal), offSet)}
              fill={rangeColors.trip}
              id="trip-path"
            /> : ''}

          {upperRange ?
            <path
              d={createBar(upperRange ?? startingVal, offSet)}
              fill={rangeColors.upper}
            /> : ''}

          <path
            d={createBar(lowerRange ?? startingVal, Math.max(upperRange, tripRange, offSet))}
            fill={rangeColors.mid}
          />
          {lowerRange ?
            <path
              d={createBar(lowerRange, Math.max(upperRange, tripRange, offSet))}
              fill={rangeColors.mid}
            /> : null}

          <path
            d={createBar(startingVal, Math.max(lowerRange, upperRange, tripRange, offSet))}
            fill={rangeColors.low}
            id={'liner-lower'}
          />

          {/* markers section */}

          {/* setpoint */}
          {setPoint ?
            <g onClick={() => onSetPointClick(settingsConfig.settingLabel, setPointValue, 'setting')} style={{ cursor: 'pointer' }}>
              <rect width={'0.15px'} height={'0.15px'} style={{ cursor: 'pointer', opacity: 0 }} >
                <title>{settingsConfig.settingLabel}</title>
              </rect>
              <path
                onClick={() => onSetPointClick(settingsConfig.settingLabel, setPointValue, 'setting')}
                d={`m 0 0 c 0 7.282 5.9063 13.1862 13.1754 13.1862 c 3.2461 -0 6.2227 -1.1848 8.5313 -3.1324 l 11.8465 -10.0535 l -11.8465 -10.0535 c -2.2969 -1.9512 -5.2734 -3.1324 -8.5313 -3.1324 c -7.2891 0 -13.1754 5.9039 -13.1754 13.1863 z m 18.1535 -0 c 0 2.9941 -2.4258 5.4223 -5.4258 5.4223 c -3 0 -5.4258 -2.427 -5.4258 -5.4223 c -0 -2.9965 2.4258 -5.4234 5.4258 -5.4234 c 3 -0 5.4258 2.4281 5.4258 5.4234 z`}
                style={{ cursor: 'pointer' }}
                strokeWidth={0.5}
                className='gauge-set-point'
                transform={`translate(25, ${setPoint})`}
              >
                <title>{settingsConfig.settingLabel}</title>
              </path>
            </g>

            : null}

          {/* trip range */}
          <path
            onClick={() => onSetPointClick(settingsConfig.rangeMaxLabel, rangeTrip, 'trip')}
            d="m 0 0 v -9.4526 c 5.3262 6.1172 5.3394 6.0908 13.5527 9.5054 z"
            fill={'black'}
            style={{ cursor: 'pointer' }}
            strokeWidth={3}
            transform={`translate(50, ${tripRange?? 0})`}
          >
            <title>{settingsConfig.rangeTripLabel}</title>
          </path>
          {/* upper range */}
          <path
            onClick={() => onSetPointClick(settingsConfig.rangeMaxLabel, rangeMax, 'high')}
            d="m 0 0 v -9.4526 c 5.3262 6.1172 5.3394 6.0908 13.5527 9.5054 z"
            fill={'black'}
            style={{ cursor: 'pointer' }}
            strokeWidth={3}
            transform={`translate(50, ${upperRange?? 0})`}
          >
            <title>{settingsConfig.rangeMaxLabel}</title>
          </path>
          {/* lower range */}
          {lowerRange ? <path
            onClick={() => onSetPointClick(settingsConfig.rangeMinLabel, rangeMin, 'low')}
            d="m 0 0 v 8.9625 c 5.05 -5.8 5.0625 -5.775 12.85 -9.0125 z"
            fill={'black'}
            style={{ cursor: 'pointer' }}
            strokeWidth={3}
            transform={`translate(50, ${lowerRange??0})`}
          >
            <title>{settingsConfig.rangeMinLabel}</title>
          </path> : null }
          {/* current value */}
          <path
            d="m 0 0 a 112.5 47.461 90 0 0 0 -4.5 l -30.586 1.5 a 40 16.875 90 0 1 0 1.5 z"
            className="gauge-set-point"
            strokeWidth={0.3}
            style={{
              transition: `transform 0.5s ease-in-out`
            }}
            transform={`translate(100, ${currentValue})`}
          />

          {/* labels */}

          <text x={locationOfText(displayRangeMin)} y={lowerRange + 5} fontSize='12px' fill="black" textAnchor="middle" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => onSetPointClick(settingsConfig.rangeMinLabel, displayRangeMin, 'low')}>
            {displayRangeMin ? getDisplayValue(displayRangeMin) : ''}
            <title>{settingsConfig.rangeMinLabel}</title>
          </text>
          <text x={locationOfText(displayRangeMax)} y={upperRange} fontSize='12px' fill="black" textAnchor="middle" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => onSetPointClick(settingsConfig.rangeMaxLabel, displayRangeMax, 'high')}>
            {displayRangeMax ? getDisplayValue(displayRangeMax) : ''}
            <title>{settingsConfig.rangeMaxLabel}</title>
          </text>
          <text x={locationOfText(displayTripMax)} y={tripRange} fontSize='12px' fill="black" textAnchor="middle" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => onSetPointClick(settingsConfig.rangeTripLabel, displayTripMax, 'trip')}>
            {displayTripMax ? getDisplayValue(displayTripMax) : ''}
            <title>{settingsConfig.rangeTripLabel}</title>
          </text>
          <text x={locationOfSetpointText(setPointValue)} y={setPoint + 5} fontSize='12px' fill="black" textAnchor="middle" fontWeight="bold" style={{ cursor: 'pointer' }} onClick={() => onSetPointClick(settingsConfig.settingLabel, setPointValue, 'setting')}>
            {setPointValue ? getDisplayValue(setPointValue) : ''}
            <title>{settingsConfig.settingLabel}</title>
          </text>
          <text x="125" y={value === null ? 120 : currentValue + 5} fontSize='24px' fill="black" textAnchor="middle" fontWeight="bold">
            {getGaugeStatusDisplayValue(value)}
          </text>
          <text x={locationOfUnits(value, units)} y={currentValue + 5} fontSize='12px' fill="black" textAnchor="middle" fontWeight="bold">
            {value !== null ? units : null}
          </text>

        </svg>
      </div>
    </div>
  );

}

LinearGauge.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  units: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeTrip: PropTypes.number,
  setPointValue: PropTypes.number,
  theme: PropTypes.string,
  settingsConfig: PropTypes.object,
  openSetting: PropTypes.func,
  onSetPointClick: PropTypes.func,
  numberOfSettingDiffs: PropTypes.number,
  disableHamburger: PropTypes.bool
};

LinearGauge.defaultProps = {
  title: "",
  value: null,
  units: "℃",
  theme: "default",
  rangeMin: null,
  rangeMax: null,
  rangeTrip: null,
  settingsConfig: {
    canDisableAllAlarms: false,
    intialHighSetpointEnabled: true,
    intialLowSetpointEnabled: true,
    rangeMinDisabledLabel: "",
    rangeMaxDisabledLabel: "",
  },
  numberOfSettingDiffs: 0,
  disableHamburger: false
};
