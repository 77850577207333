import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/dashboard/';

export const { fetchRecipes, FETCH_RECIPES }: any = createTypedAction(
  `${PREFIX}FETCH_RECIPES`
);

export const { fetchRecipesSuccess, FETCH_RECIPES_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_RECIPES_SUCCESS`,
  ['recipes', yup.immutable().map().required()]
);

export const { fetchRecipesFailed, FETCH_RECIPES_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_RECIPES_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
export const { fetchDeviceSettingMap, FETCH_DEVICE_SETTING_MAP }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SETTING_MAP`
);

export const { fetchDeviceSettingMapSuccess, FETCH_DEVICE_SETTING_MAP_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SETTING_MAP_SUCCESS`,
  ['settingDeviceMap', yup.immutable().map().required()]
);

export const { fetchDeviceSettingMapFailed, FETCH_DEVICE_SETTING_MAP_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_DEVICE_SETTING_MAP_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchRecipeById, FETCH_RECIPE_BY_ID }: any = createTypedAction(
  `${PREFIX}FETCH_RECIPE_BY_ID`,
  ['recipeId', yup.string().required()],
);

export const { fetchRecipeByIdSuccess, FETCH_RECIPE_BY_ID_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_RECIPE_BY_ID_SUCCESS`,
  ['recipe', yup.immutable().map().required()]
);

export const { fetchRecipeByIdFailed, FETCH_RECIPE_BY_ID_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_RECIPE_BY_ID_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateRecipe, UPDATE_RECIPE, updateRecipeQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_RECIPE`,
  (state) => {
    const label = state.recipe.get('alarm_group') ? 'alarm group' : 'criticality';
    return `update recipe ${label} = ${state.recipe.get('alarm_group') ?? state.recipe.get('criticality')} `;
  },
  ['recipeId', yup.string().required()],
  ['recipe', yup.immutable().map().required()]
);

export const { updateRecipeSuccess, UPDATE_RECIPE_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_RECIPE_SUCCESS`,
  ['recipe', yup.immutable().map().required()]
);

export const { updateRecipeFailed, UPDATE_RECIPE_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_RECIPE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addRecipe, ADD_RECIPE }: any = createTypedAction(
  `${PREFIX}ADD_RECIPE`,
  ['recipe', yup.immutable().map().required()]
);

export const { addRecipeSuccess, ADD_RECIPE_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_RECIPE_SUCCESS`,
  ['recipe', yup.immutable().map().required()]
);

export const { addRecipeFailed, ADD_RECIPE_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_RECIPE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteRecipe, DELETE_RECIPE, deleteRecipeQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_RECIPE`,
  () => {
    return `delete recipe`;
  },
  ['recipeId', yup.immutable().map().required()]
);

export const { deleteRecipeSuccess, DELETE_RECIPE_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_RECIPE_SUCCESS`,
  ['recipeId', yup.immutable().map().required()]
);

export const { deleteRecipeFailed, DELETE_RECIPE_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_RECIPE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateRecipeCondition, UPDATE_RECIPE_CONDITION, updateRecipeConditionQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_RECIPE_CONDITION`,
  (state) => {
    return `update condition ${state.recipeCondition.get('property')} to  '${state.recipeCondition.get('value') ?? state.recipeCondition.get('comparison')}'`;
  },
  ['recipeId', yup.string().required()],
  ['recipeCondition', yup.immutable().map().required()]
);

export const { updateRecipeConditionSuccess, UPDATE_RECIPE_CONDITION_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_RECIPE_CONDITION_SUCCESS`,
  ['recipe', yup.immutable().map().required()]
);

export const { updateRecipeConditionFailed, UPDATE_RECIPE_CONDITION_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_RECIPE_CONDITION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { addRecipeCondition, ADD_RECIPE_CONDITION, addRecipeConditionQueueFormatter }: any = createTypedAction(
  `${PREFIX}ADD_RECIPE_CONDITION`,
  (state) => {
    return `add new condition: ${state.recipeCondition.get('property')} = '${state.recipeCondition.get('value')}'`;
  },
  ['recipeId', yup.string().required()],
  ['recipeCondition', yup.immutable().map().required()]
);

export const { addRecipeConditionSuccess, ADD_RECIPE_CONDITION_SUCCESS }: any = createTypedAction(
  `${PREFIX}ADD_RECIPE_CONDITION_SUCCESS`,
  ['recipe', yup.immutable().map().required()]
);

export const { addRecipeConditionFailed, ADD_RECIPE_CONDITION_FAILED }: any = createTypedAction(
  `${PREFIX}ADD_RECIPE_CONDITION_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { openAddGroupModal, OPEN_ADD_GROUP_MODAL }: any = createTypedAction(
  `${PREFIX}OPEN_ADD_GROUP_MODAL`,
);

export const { closeAddGroupModal, CLOSE_ADD_GROUP_MODAL }: any = createTypedAction(
  `${PREFIX}CLOSE_ADD_GROUP_MODAL`,
  ['err', yup.immutable().map().nullable()]
);
