import { getTabId } from "src/utils/utils";

export default function createActionEnhancerMiddleware () {
  return ({ getState }) => next => action => {
    const state = getState();
    const tabId= getTabId();

    action.currentClientId = state.authentication.getIn(['tabs', tabId, 'clientId']) || state.authentication.getIn(['tabs'])?.first().clientId || null;
    return next(action);
  };
}
