import React from 'react';
import './RegulatoryBodyReport.scss';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import { DataGrid, gridSortedRowIdsSelector, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

import DeviceLink from 'src/component/UI/DeviceLink';
import { getReportPDFLoading, getReportData, getReportLoading } from 'src/module/device/selector';
import { Box } from '@mui/material';
import { formatDate } from 'src/utils/utils';
import LogoSpinner from 'src/component/UI/LogoSpinner';
import moment from 'moment';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import classNames from 'classnames';
import { generateTestingReportPdf } from 'src/module/device/action';
import LoadingSpinner from 'src/component/UI/LoadingSpinner';
import variables from 'src/style/variable/variables.module.scss';

export default function RegulatoryBodyReport ({ filters }) {
  const data = useSelector(getReportData('regulator'));
  const loading = useSelector(getReportLoading);
  const pdfLoading = useSelector(getReportPDFLoading);
  const displayedIcon = pdfLoading ? (<LoadingSpinner color={variables.primaryLight}/>) : <FileDownloadOutlinedIcon className='custom-toolbar-download-icon' />;

  const columns = [
    {
      field: 'unit',
      headerName: 'Unit',
      minWidth: 50,
      flex: 1
    },
    {
      field: 'parent_tag',
      headerName: 'Controller',
      minWidth: 100,
      renderCell: (params) =>  (<DeviceLink label={params.value} controllerTag={params.value} />),
      flex: 1
    },
    {
      field: 'tag',
      headerName: 'Circuit',
      minWidth: 100,
      renderCell: (params) =>  (<DeviceLink label={params.value} circuitTag={params.value} controllerTag={params.row.parent_tag}/>),
      flex: 1
    },
    {
      field: 'line_description',
      headerName: 'Line Description',
      minWidth: 125,
      flex: 1
    },
    {
      field: 'measured_current',
      headerName: 'Measured Current',
      minWidth: 125,
      flex: 1
    },
    {
      field: 'measured_voltage',
      headerName: 'Measured Voltage',
      minWidth: 125,
      flex: 1
    },
    {
      field: 'circuit_inspection_passed',
      headerName: 'Circuit Inspection',
      minWidth: 125,
      renderCell: (params) => {
        if (params.value) {
          return 'Passed';
        }
        if (params.row.circuit_function_created_at) {
          return 'Failed';
        }
        return '';
      },
      flex: 1
    },
    {
      field: 'circuit_function_created_at',
      headerName: 'Inspection Date',
      minWidth: 140,
      valueFormatter: (params) => params.value ? formatDate(params.value) : '',
      flex: 1
    },
    {
      field: 'insulation_passed',
      headerName: 'Insulation Inspection',
      minWidth: 150,
      renderCell: (params) => {
        if (params.value) {
          return 'Passed';
        }
        if (params.row.insulation_created_at) {
          return 'Failed';
        }
        return '';
      },
      flex: 1
    },
    {
      field: 'insulation_created_at',
      headerName: 'Insulation Date',
      minWidth: 140,
      type: 'date',
      valueFormatter: (params) => params.value ? formatDate(params.value) : '',
      flex: 1
    },
  ];

  const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);
  const dispatch = useDispatch();

  const handlePdfGenerationSubmit = () => {
    if (!pdfLoading) {
      dispatch(generateTestingReportPdf('regulatory', filters.selectedFilters, null, filters.startDate ? moment(filters.startDate).format('YYYY-MM-DD') : null, filters.endDate ? moment(filters.endDate).format('YYYY-MM-DD') : null));
    }
  };

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

    return (
      <GridToolbarContainer>
        <Box sx={{ flexGrow: 1 }} /> {/* Spacer to push the export icon to the right */}

        <div
          className='custom-toolbar-option'
          onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
        >
          <FileDownloadOutlinedIcon className='custom-toolbar-download-icon'/>
          <div className={classNames('custom-toolbar-text', 'custom-toolbar-text-reg ')}>Download CSV</div>
        </div>
        <div
          className='custom-toolbar-option'
          onClick={handlePdfGenerationSubmit}
        >
          <div className={classNames('custom-toolbar-text', 'custom-toolbar-text-reg ')}>
            {displayedIcon}
            {'Download PDF'}
          </div>
        </div>
      </GridToolbarContainer>
    );
  };
  return (
    <>
      {loading && <div style={{ width: '300px', height: '500px', margin: 'auto', paddingTop: '200px' }}>
        <LogoSpinner size={200} />
      </div> }

      {(data && !loading) &&
      <Paper style={{ marginTop: '20px' }}>
        <h2>Regulatory Reports</h2>

        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={data?.toJS()}
            columns={columns}
            sx={{
              border: 'none', // Removes the outer border
              '& .MuiDataGrid-columnSeparator': {
                display: 'none', // Removes the separator lines between column headers
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}

            density='compact'
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>}
    </>
  );
}

RegulatoryBodyReport.propTypes = {
  filters: PropTypes.object
};
RegulatoryBodyReport.defaultProps = {

};
