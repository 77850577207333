import { createSelector } from 'reselect';
import { List } from 'immutable';
import { langLookUpText } from 'src/utils/langLookUp';
import { getTabId } from 'src/utils/utils';

export const getCheckSheetState = (state) => ({
  state: state.checkSheet,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.checkSheet) {
      return state.checkSheet.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});

export const getCheckSheetTasks = checkSheetType => createSelector(
  [getCheckSheetState],
  (checkSheets) => {
    const tasks = checkSheets.getIn(['checkSheets', 'tasks', checkSheetType]) || List();
    return tasks.map(x => { return { order: x.get('order'), label: langLookUpText(x.get('task')), key: x.get('task'), text_input_only: x.get('text_input_only') }; })
      .sort((a, b) => { a.order < b.order; });
  }
);
export const getCheckSheetTasksLoading = createSelector(
  [getCheckSheetState],
  (checkSheets) => {
    return checkSheets.getIn(['checkSheets', 'tasks', 'loading'], true);
  }
);

export const getCheckSheetReport = id => createSelector(
  [getCheckSheetState],
  (checkSheets) => {
    return checkSheets.getIn(['checkSheets', 'data', id, 'record']) || List();
  }
);

export const getSaveCheckSheetReportLoading = checkSheetType => createSelector(
  [getCheckSheetState],
  (checkSheets) => {
    return checkSheets.getIn(['checkSheets', 'submit', checkSheetType, 'loading'], false);
  }
);

export const getCheckSheetReportLoading = createSelector(
  [getCheckSheetState],
  (checkSheets) => {
    return checkSheets.getIn(['checkSheets', 'loading'], false);
  }
);

export const getCheckSheetReportIsOpen = checkSheetType => createSelector(
  [getCheckSheetState],
  (checkSheets) => {
    return checkSheets.getIn(['checkSheets', 'submit', checkSheetType, 'open'], false);
  }
);
