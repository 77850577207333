import createSelector from '../createSelectorCreator';
import { List, Map } from 'immutable';
import { langLookUpText } from 'src/utils/langLookUp';
import { getTabId } from 'src/utils/utils';

// export const getDeviceState = (state) =>
//   state.device;

export const getDeviceState = (state) => ({
  state: state.device,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.device) {
      return state.device.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  },
  get: (keyPath, defaultValue = null) => {
    return state.device.get(keyPath, defaultValue);
  },
});

export const getRootState = (state) =>
  state;

// Base selector for clientID
export const selectCurrentClientId = (state) => state.authentication.getIn(['tabs', getTabId(), 'clientId']);

export const getDeviceByTag = (parentTag, childTag) => createSelector(
  [getDeviceState],
  (device) => {
    if (childTag && childTag !== 'undefined') {
      const deviceFromStore = device.getIn(['devices', 'data'], Map()).find(d => (
        ((d.getIn(['record', 'info', 'parent_type']) === 'controller' && d.getIn(['record', 'info', 'parent_tag']) === parentTag)
          || d.getIn(['record', 'info', 'parent_type']) !== 'controller')
        && d.getIn(['record', 'info', 'tag']) === childTag
      )) || Map();
      return deviceFromStore.getIn(['record', 'info'], Map());
    } else {
      const deviceFromStore = device.getIn(['devices', 'data'], Map()).find(d => (d.getIn(['record', 'info', 'tag']) === parentTag)) || Map();
      return deviceFromStore.getIn(['record', 'info'], Map());
    }
  }
);

export const getDeviceById = (deviceId) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'info'], Map());
  }
);

export const getDeviceTags = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTags', 'data'], List());
  }
);
export const getPanelDataFromDirectory = panelId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['panelDirectory', panelId], Map({}));
  }
);

export const getCommLoops = createSelector(
  [getDeviceState],
  (device) => {
    const commLoopIds = device.getIn(['devices', 'commLoops', 'data'], List());
    let commLoops = List();
    commLoopIds.forEach(id => {
      commLoops = commLoops.push(device.getIn(['devices', 'data', id, 'record', 'info'], Map()));
    });
    return commLoops;
  }
);

export const getCommLoopsPageInfo = createSelector(
  [getDeviceState],
  (device) => {
    return Map({
      page: device.getIn(['devices', 'commLoops', 'page'], 0),
      total: device.getIn(['devices', 'commLoops', 'total'], 0),
      pageSize: device.getIn(['devices', 'commLoops', 'pageSize'], 0),
      totalPages: device.getIn(['devices', 'commLoops', 'totalPages'], 0),
      loading: device.getIn(['devices', 'commLoops', 'loading'], false)
    });
  }
);
export const getBreakdownPageInfo = commLoopId => createSelector(
  [getDeviceState],
  (device) => {
    return Map({
      page: device.getIn(['devices', 'breakdown', commLoopId, 'page'], 0) ?? 0,
      total: device.getIn(['devices', 'breakdown', commLoopId, 'total'], 0) ?? 0,
      pageSize: device.getIn(['devices', 'breakdown', commLoopId, 'pageSize'], 10) ?? 10,
      totalPages: device.getIn(['devices', 'breakdown', commLoopId, 'totalPages'], 0) ?? 0,
      loading: device.getIn(['devices', 'breakdown', commLoopId, 'loading'], false) ?? false
    });
  }
);
export const getCoomLoopDeviceBreakdown = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'communicationInfo'], List());
  }
);
export const getCircuitTags = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTags', 'data'], List()).filter(device => device.get('type') === 'circuit');
  }
);

export const getControlPanelTags = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['controlPanelTags', 'data'], List());
  }
);

export const getCommLoopTags = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['commLoopTags', 'data'], List());
  }
);

export const getLoadingDeviceTags = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTags', 'loading'], false);
  }
);

export const getSettingsModal = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (!deviceId) {
      return device.getIn(['devices', 'settingsModal'], Map({ loading: false, 'isOpen': false, id: null }));
    }
    else {
      return device.getIn(['devices', 'data', deviceId, 'settingsModal'], Map({ loading: false, 'isOpen': false, id: null }));
    }
  }
);

export const getSettingsDiff = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (deviceId) {
      return device.getIn(['devices', 'data', deviceId, 'record', 'settingsDiff'], List());
    }
  }
);

export const getSettingsDiffMap = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (deviceId) {
      const list = device.getIn(['devices', 'data', deviceId, 'record', 'settingsDiff'], List());
      const aMap = {};
      list?.forEach(x => aMap[langLookUpText(x.get('setting'))] = x);
      return Map(aMap);
    }
  }
);

export const getLoadingDevicesWithGroupedAlarms = createSelector(
  [getDeviceState, selectCurrentClientId],
  (device) => {
    return device.getIn(['loading'], false);
  }
);

export const getDevicesActiveWorkTickets = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {

    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'activeWorkTickets', 'data'], List());
    let workTickets = List();
    ids.forEach(id => {
      const data = root.workTicket.getIn(['workTickets', clientId, 'data', id, 'record']);
      if (data) {
        workTickets = workTickets.push(data);
      }
    });
    return workTickets;
  }
);

export const getDevicesArchivedWorkTickets = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'archivedWorkTickets', 'data'], List());
    let workTickets = List();
    ids.forEach(id => {
      const data = root.workTicket.getIn(['workTickets', clientId, 'data', id, 'record']);
      if (data) {
        workTickets = workTickets.push(data);
      }
    });
    return workTickets;
  }
);

export const getDevicesActiveNotes = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'activeNotes', 'data'], List());
    let notes = List();
    ids?.forEach(id => {
      const data = root.note.getIn(['notes', clientId, 'data', id, 'record']);
      if (data) {
        notes = notes.push(data);
      }
    });
    return notes;
  }
);

export const getDevicesArchivedNotes = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'archivedNotes', 'data'], List());
    let notes = List();
    ids.forEach(id => {
      const data = root.note.getIn(['notes', clientId, 'data', id, 'record']);
      if (data) {
        notes = notes.push(data);
      }
    });
    return notes;
  }
);

export const getActiveAlarms = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'activeAlarms', 'data'], List());
    let alarms = List();
    ids.forEach(id => {
      const data = root.alarm.getIn(['alarms', clientId, 'data', id, 'record']);
      if (data) {
        alarms = alarms.push(data);
      }
    });
    return alarms;
  }
);

export const getPanelActiveAlarms = panelId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!panelId) {
      return List();
    }
    const ids = root.device.getIn(['panels', clientId, 'data', panelId, 'record', 'activeAlarms', 'data'], List());
    let alarms = List();
    ids.forEach(id => {
      const data = root.alarm.getIn(['alarms', clientId, 'data', id, 'record']);
      if (data) {
        alarms = alarms.push(data);
      }
    });
    return alarms;
  }
);

export const getArchivedAlarms = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'archivedAlarms', 'data'], List());
    let alarms = List();
    ids.forEach(id => {
      const data = root.alarm.getIn(['alarms', clientId, 'data', id, 'record']);
      if (data) {
        alarms = alarms.push(data);
      }
    });
    return alarms;
  }
);

export const getAlarmSummary = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'alarmSummary'], '');
  }
);

export const getLoadingActiveWorkTickets = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeWorkTickets', 'loading'], false);
  }
);

export const getNumActiveWorkTicketsPages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeWorkTickets', 'totalPages'], 1);
  }
);

export const getTotalActiveWorkTickets = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeWorkTickets', 'total'], 0);
  }
);
//
export const getLoadingArchivedWorkTickets = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedWorkTickets', 'loading'], false);
  }
);

export const getNumArchivedWorkTicketsPages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedWorkTickets', 'totalPages'], 1);
  }
);

export const getTotalArchivedWorkTickets = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedWorkTickets', 'total'], 0);
  }
);
export const getHiddenCircuits = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'hiddenCircuits'], []);
  }
);

export const getLoadingActiveNotes = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeNotes', 'loading'], false);
  }
);

export const getNumActiveNotesPages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeNotes', 'totalPages'], 1);
  }
);

export const getTotalActiveNotes = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeNotes', 'total'], 0);
  }
);

export const getLoadingArchivedNotes = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedNotes', 'loading'], false);
  }
);

export const getNumArchivedNotesPages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedNotes', 'totalPages'], 1);
  }
);

export const getTotalArchivedNotes = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedNotes', 'total'], 0);
  }
);

export const getLoadingActiveAlarms = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeAlarms', 'loading'], false);
  }
);

export const getNumActiveAlarmPages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeAlarms', 'totalPages'], 1);
  }
);

export const getTotalActiveAlarms = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeAlarms', 'total'], 0);
  }
);

export const getLoadingArchivedAlarms = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedAlarms', 'loading'], false);
  }
);

export const getNumArchivedAlarmPages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedAlarms', 'totalPages'], 1);
  }
);

export const getTotalArchivedAlarms = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'archivedAlarms', 'total'], 0);
  }
);

export const getLoadingActiveAlarmsOnPanel = panelId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['panels', 'data', panelId, 'record', 'activeAlarms', 'loading'], false);
  }
);

export const getNumActiveAlarmOnPanelPage = panelId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['panels', 'data', panelId, 'record', 'activeAlarms', 'totalPages'], 1);
  }
);

export const getTotalActiveAlarmsOnPanel = panelId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['panels', 'data', panelId, 'record', 'activeAlarms', 'total'], 0);
  }
);

export const getNotes = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return Map({
        data: List(),
        total: 0
      });
    }
    const notesIds = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'notes', 'data'], List());
    let notes = List();
    notesIds.map(x => {
      const note = root.note.getIn(['notes', clientId, 'data', x, 'record'], null);
      if (note) {
        notes = notes.push(note);
      }
    });
    const notesMap = Map({
      data: notes,
      total: notes.size
    });
    return notesMap;
  }
);

export const getTotalNotes = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'notes', 'total'], 0);
  }
);

export const getLoadingNotes = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'notes', 'loading'], false);
  }
);

export const getNumNotePages = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'note', 'totalPages'], 1);
  }
);

export const getNotifications = deviceId => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return Map({
        data: List(),
        total: 0
      });
    }
    const workTicketIds = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', 'workTickets', 'data'], List());
    let workTickets = List();
    workTicketIds.map(x => {
      workTickets = workTickets.push(root.workTicket.getIn(['workTickets', clientId, 'data', x, 'record']));
    });
    const workTicketsMap = Map({
      data: workTickets,
      total: workTickets.size
    });
    return workTicketsMap;
  }
);

export const getNumberOfCircuitsOfController = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', device.get('id'), 'record', 'circuits'], List()).size;
  }
);

export const getDeviceTree = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTree', 'data'], Map());
  }
);

export const getIsDeviceInCommFail = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'activeAlarms', 'isControllerInCommFail'], false) || device.getIn(['devices', 'data', deviceId, 'record', 'activeAlarms', 'isChannelInCommFail'], false);
  }
);

export const getLoadingDeviceTree = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTree', 'loading'], false);
  }
);

export const getChildDevices = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    let children = List();
    device.getIn(['devices', 'data', deviceId, 'record', 'childDevices'], List()).map(childId => {
      children = children.push(device.getIn(['devices', 'data', childId, 'record', 'info'], Map()));
    });
    return children;
  }
);

export const getDevice = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'info'], Map());
  }
);

export const getDevices = devices => createSelector(
  [getDeviceState],
  (device) => {
    let deviceList = List();
    devices?.forEach(deviceId => {
      const d = device.getIn(['devices', 'data', deviceId, 'record', 'info'], null);
      if (d) {
        deviceList = deviceList.push(d);
      }
    });
    return deviceList;
  }
);


export const getLoadingDeviceInfo = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'loading'], false);
  }
);

export const getSiblingsInfo = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['siblingsInfo', 'data', deviceId], List());
  }
);

export const getDeviceStatus = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (device.get('devices') && device.get('devices').size > 0) {
      return device.getIn(['devices', 'data', deviceId, 'record', 'info', 'status'], Map()) || Map();
    }
    else {
      return Map();
    }
  }
);

export const getDeviceSettings = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (device.get('devices') && device.get('devices').size > 0) {
      return device.getIn(['devices', 'data', deviceId, 'record', 'info', 'settings'], Map()) || Map();
    }
    else {
      return Map();
    }
  }
);

export const getDeviceSettingsSnapshotStatus = createSelector(
  [getDeviceState],
  (device) => {
    const snapshotStatus = device.getIn(['snapshot'], Map());
    let numDevices = 0;
    let numCompleted = 0;
    let snapshotDevices = List();

    snapshotStatus.keySeq().forEach((deviceId) => {
      numDevices++;
      const snapshotDevice = snapshotStatus.get(deviceId);
      if (snapshotDevice.get('done')) {
        numCompleted++;
      }
      snapshotDevices = snapshotDevices.push(snapshotDevice);
    });

    const progress = (numDevices > 0) ? numCompleted / numDevices : 0;

    return Map({
      snapshotDevices: snapshotDevices.sort((a: any, b: any) => a.get('tag') > b.get('tag') ? 1:-1),
      numDevices,
      numCompleted,
      progress
    });
  }
);

export const getApplySnapshotStatus = createSelector(
  [getDeviceState],
  (device) => {
    const deviceUpgradeStatus = device.getIn(['deviceUpgradeStatus'], Map());


    return deviceUpgradeStatus;
  }
);

export const getEnumeratedDeviceSettings = createSelector(
  [getDeviceState, selectCurrentClientId],
  (filter) => {
    const settings = filter.get('settings', Map()).toJS();
    const devices = Object.keys(settings);
    const settingsRaw = devices.reduce((acc, device) => [...acc, ...settings[device]], []);
    const uniqueSettings = [... new Set(settingsRaw)];
    return List(uniqueSettings);
  }
);

export const getEnumeratedDeviceSettingsByType = createSelector(
  [getDeviceState, selectCurrentClientId],
  (filter) => {
    return filter.get('settings', Map());
  }
);

export const getStatistics = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'statistics'], Map());
  }
);

export const getDashboardStats = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['dashboardStats', 'data'], Map());
  }
);

export const getDeviceConfigFields = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (!deviceId) {
      return Map({ settings: Map(), status: Map() });
    }
    let configFields = device.getIn(['devices', 'data', deviceId, 'record', 'deviceConfigFields'], Map({ settings: Map(), status: Map() }));
    if (!configFields) {
      return Map({ settings: Map(), status: Map() });
    }

    if (!configFields.get('settings')) {
      configFields = configFields.set('settings', Map());
    }

    if (!configFields.get('status')) {
      configFields = configFields.set('status', Map());
    }

    return configFields;
  }
);

export const getDeviceConfigFieldsGeneric = model => createSelector(
  [getDeviceState],
  (device) => {
    if (!model) {
      return Map({ settings: Map(), status: Map() });
    }
    let configFields = device.getIn(['devices', 'deviceConfigFields', model], Map({ settings: Map(), status: Map() }));
    if (!configFields) {
      return Map({ settings: Map(), status: Map() });
    }

    if (!configFields.get('settings')) {
      configFields = configFields.set('settings', Map());
    }

    if (!configFields.get('status')) {
      configFields = configFields.set('status', Map());
    }

    return configFields;
  }
);

export const getDeviceFiles = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    const files = device.getIn(['devices', 'data', deviceId, 'record', 'files', 'records'], Map());
    return files.valueSeq().toArray();
  }
);

export const getDeviceFilesLoading = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', 'files', 'loading'], false);
  }
);

export const getControlPanel = id => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTree', 'data', 'panels'], Map()).find(x => x.get('control_panel_id') === id);
  }
);

export const getSensorData = (timeFrame, rollUp) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['sensorHistory', timeFrame, rollUp], Map()) || Map();
  }
);

export const getSensorDateLoading = () => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['sensorHistory', 'loading'], false);
  }
);

export const getAllDevices = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data'], Map());
  }
);
export const getAllPanels = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['deviceTree', 'data', 'panels'], Map());
  }
);

export const getDeviceSensorHistory = (deviceId, timeFrame, rollUp) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'sensorHistory', timeFrame, rollUp], Map({}));
  }
);

export const getDeviceSensorLoading = (deviceId) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'sensorHistory', 'loading'], false);
  }
);

export const getDeviceActions = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (!deviceId) {
      return null;
    }
    const configFields = device.getIn(['devices', 'data', deviceId, 'record', 'deviceActions'], null);
    return configFields;
  }
);

export const getAllProgrammingDiscrepancies = createSelector(
  [getDeviceState],
  (device) => {
    const device_ids = device.getIn(['programmingDiscrepancies', 'data'], List());
    let data = List();
    device_ids.map(id => {
      data = data.push({
        device_id: id,
        device_tag: device.getIn(['devices', 'data', id, 'record', 'info', 'tag'], ''),
        device: device.getIn(['devices', 'data', id, 'record', 'info'], Map()),
        diffArray: device.getIn(['devices', 'data', id, 'record', 'settingsDiff'], List()),
      });
    });

    return data;
  }
);

export const getProgrammingDiscrepanciesPageInfo = createSelector(
  [getDeviceState],
  (device) => {
    return Map({
      page: device.getIn(['programmingDiscrepancies', 'page'], 0),
      total: device.getIn(['programmingDiscrepancies', 'total'], 0),
      pageSize: device.getIn(['programmingDiscrepancies', 'pageSize'], 0),
      totalPages: device.getIn(['programmingDiscrepancies', 'totalPages'], 0),
      loading: device.getIn(['programmingDiscrepancies', 'loading'], false)

    });
  }
);

export const getDeviceAuditTrail = deviceId => createSelector(
  [getDeviceState],
  (device) => {
    if (!deviceId) {
      return null;
    }
    return device.getIn(['devices', 'data', deviceId, 'record', 'auditTrail'], Map());
  }
);

export const getSynchronizingDevices = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'loading'], false);
  }
);
export const getSynchronizingDeviceTag = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'deviceTag'], null);
  }
);
export const getSynchronizingDeviceId = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'deviceId'], null);
  }
);

export const getSynchronizingAttemptNumber = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'attemptNumber'], 0);
  }
);

export const getShowSynchronizingDevicesPopUp = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'showPopup'], false);
  }
);

export const getSyncedAddresses = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'createdDevices'], []);
  }
);
export const getSyncedTags = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'info'], []);
  }
);

export const getSyncedIsError = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'error'], false);
  }
);


export const getDeviceSyncProgress = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'synchronization', 'currentStage'], 0) / device.getIn(['devices', 'synchronization', 'totalStages'], 2);
  }
);
export const getAlarmCheckSheetReports = (deviceId, checkSheetType) => createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    if (!deviceId) {
      return List();
    }
    const ids = root.device.getIn(['devices', clientId, 'data', deviceId, 'record', checkSheetType, 'data'], List());
    let reports = List();
    ids.forEach(id => {
      const data = root.checkSheet.getIn(['checkSheets', clientId, 'data', id, 'record']);
      if (data && data.get('check_sheet_type') === checkSheetType) {
        reports = reports.push(data);
      }
    });
    return reports;
  }
);

export const getDeviceCheckSheetTotal = (deviceId, checkSheetType) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', checkSheetType, 'total'], 0);
  }
);
export const getDeviceCheckSheetTotalPages = (deviceId, checkSheetType) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', checkSheetType, 'totalPages'], 0);
  }
);
export const getDeviceCheckSheetLoading = (deviceId, checkSheetType) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'record', checkSheetType, 'loading'], false);
  }
);
export const getReportData = (reportType) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['reports', reportType], List([]));
  }
);
export const getReportLoading = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['reports', 'loading'], false);
  }
);

export const getReportPDFLoading = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['reports', 'pdf', 'loading'], false);
  }
);
export const getModbusRequstData = (deviceId) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['modbus', deviceId, 'data'], null);
  }
);

export const getModbusRequstLoading = createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['modbus', 'loading'], false);
  }
);

export const getLoadingModbusProperties = (deviceId) => createSelector(
  [getDeviceState],
  (device) => {
    return device.getIn(['devices', 'data', deviceId, 'properties'], Map());
  }
);
