import { List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as noteActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  notes: Map()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('notes', Map())
      .set('lotoPage', Map())
      .set('nuisancePage', Map())
      .set('notePage', Map())

  );
}

export const noteReducer = createTypedReducer(initialState, {
  [noteActions.FETCH_NOTES] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const page = `${action.note}Page`;
      nextState.setIn([page, 'loading'], true);
    });
  },

  [noteActions.FETCH_NOTES_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      let newDataIds = List();
      action.notes.map((note) => {
        newDataIds = newDataIds.push(note.get('id'));
        nextState.setIn(['notes', 'data', note.get('id')], {
          record: note,
          lastFetch: fetchDate,
        });
      });
      const page = `${action.noteType}Page`;
      nextState.setIn([page, 'records'], newDataIds);
      nextState.setIn([page, 'total'], action.total);
      nextState.setIn([page, 'totalPages'], action.totalPages);
      nextState.setIn([page, 'loading'], false);
    });
  },

  [noteActions.FETCH_NOTES_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const page = `${action.noteType}Page`;
      nextState.setIn([page, 'loading'], false);
    });
  },

  [noteActions.UPDATE_NOTE_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const noteId = action.note.get('id');
      const page = `${action.note.get('note')}Page`;
      if (action.note.get('archived_at')) {
        nextState.getIn(['notes', 'data']).delete(noteId);
        nextState.setIn([page, 'records'], nextState.getIn([page, 'records'])?.filter(x => x !== noteId));
        nextState.setIn([page, 'total'],  nextState.getIn([page, 'total'])-1);
      } else {
        nextState.mergeIn(['notes', 'data', noteId, 'record'], action.note);
      }

      return nextState;
    });
  },
  [noteActions.ADD_NOTE_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const page = `${action.note.get('note')}Page`;
      nextState.setIn(['notes', 'data', action.note.get('id'), 'record'], action.note);
      nextState.setIn(['notes', 'data', action.note.get('id'), 'lastFetch'], new Date());
      const noteList = state.getIn([page, 'records'], List()).push(action.note.get('id'));
      nextState.setIn([page, 'records'], noteList);
      nextState.setIn([page, 'total'], nextState.getIn([page, 'total'])+1);
      return nextState;
    });
  },


  [noteActions.LOAD_NOTES_FROM_DEVICE] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      action.notes.map((note) => {
        nextState.setIn(['notes', 'data', note.get('id')], {
          record: note,
          lastFetch: fetchDate,
        });
      });
      return nextState;
    });
  },
  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default noteReducer;
