import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as freezeLogsActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  freezeLogs: Map({
    data: Map(),
    records: List(),
    loading: false,
    total: 0,
    totalPages: 0
  }),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('freezeLogs', Map({
      data: Map(),
      records: List(),
      loading: false,
      total: 0,
      totalPages: 0
    }))
  );
}

export const freezeLogReducer = createTypedReducer(initialState, {

  [freezeLogsActions.FETCH_FREEZE_LOGS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['freezeLogs', 'loading'], true)
    );
  },

  [freezeLogsActions.FETCH_FREEZE_LOGS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      let newDataIds = List();
      nextState.setIn(['freezeLogs', 'data'], Map({}));
      action.freezeLogs.map((freezeLog) => {
        newDataIds = newDataIds.push(freezeLog.get('id'));
        nextState.setIn(['freezeLogs', 'data', freezeLog.get('id')], {
          record: freezeLog,
          lastFetch: fetchDate,
        });
      });
      nextState.setIn(['freezeLogs', 'records'], newDataIds);
      nextState.setIn(['freezeLogs', 'total'], action.total);
      nextState.setIn(['freezeLogs', 'totalPages'], action.totalPages);
      nextState.setIn(['freezeLogs', 'loading'], false);
    });
  },

  [freezeLogsActions.FETCH_FREEZE_LOGS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['freezeLogs', 'loading'], false)
    );
  },

  [freezeLogsActions.ADD_FREEZE_LOG_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['freezeLogs', 'data', action.freezeLog.get('id'), 'record'], action.freezeLog);
      const records = nextState.getIn(['freezeLogs', 'records'], List()).push(action.freezeLog.get('id'));
      nextState.setIn(['freezeLogs', 'records'], records);
      nextState.setIn(['freezeLogs', 'total'], nextState.getIn(['freezeLogs', 'total'])+1);
      nextState.setIn(['freezeLogs', 'loading'], false);
      return nextState;
    });
  },


  [freezeLogsActions.UPDATE_FREEZE_LOG_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const freezeLogId = action.freezeLog.get('id');
      return nextState.setIn(['freezeLogs', 'data', freezeLogId, 'record'], action.freezeLog);
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default freezeLogReducer;
