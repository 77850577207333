import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as deviceGroupsActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  groups: Map({
    data: Map(),
    records: List(),
    loading: false,
    total: 0,
    totalPages: 0
  }),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('groups', Map({}))
  );
}

export const deviceGroupsReducer = createTypedReducer(initialState, {

  [deviceGroupsActions.FETCH_DEVICE_GROUPS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['groups', 'loading'], true);
    });
  },

  [deviceGroupsActions.FETCH_DEVICE_GROUPS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      let newDataIds = List();
      nextState.setIn(['groups', 'data'], Map({}));
      action.groups.map((group) => {
        newDataIds = newDataIds.push(group.get('id'));
        nextState.setIn(['groups', 'data', group.get('id')], {
          record: group,
          lastFetch: fetchDate,
        });
      });
      nextState.setIn(['groups', 'records'], newDataIds);
      nextState.setIn(['groups', 'total'], action.total);
      nextState.setIn(['groups', 'totalPages'], action.totalPages);
      nextState.setIn(['groups', 'loading'], false);
    });
  },

  [deviceGroupsActions.FETCH_DEVICE_GROUPS_LABELS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      action.groups.map((group) => {
        const curGroup = nextState.getIn(['groups', 'data', group.get('id'), 'record'], Map());
        const mergedGroup = curGroup.state?.merge(group) || group;
        nextState.setIn(['groups', 'data', group.get('id')], {
          record: mergedGroup,
          lastFetch: fetchDate,
        });
      });
    });
  },

  [deviceGroupsActions.FETCH_DEVICE_GROUPS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['groups', 'loading'], false)
    );
  },

  [deviceGroupsActions.DELETE_DEVICE_GROUP_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState = nextState.deleteIn(['groups', 'data',  action.groupId]);
      const newIds = nextState.getIn(['groups',  'records'], []);
      nextState = nextState.setIn(['groups',  'records'], newIds.filter(x => x !== action.groupId));
      nextState.setIn(['loading'], false);

      return nextState;
    });
  },
  [deviceGroupsActions.FETCH_DEVICE_GROUP] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['groups', 'loading'], true);

      return nextState;
    });
  },
  [deviceGroupsActions.FETCH_DEVICE_GROUP_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState = nextState.setIn(['groups', 'data', action.group.get('id'), 'record'], action.group)
        .setIn(['groups', 'loading'], false);

      return nextState;
    });
  },
  [deviceGroupsActions.CREATE_DEVICE_GROUP_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const currentList = nextState.getIn(['groups', 'records'], []);
      nextState = nextState.setIn(['groups', 'data', action.id, 'record'], Map({
        group_name: action.groupName,
        id: action.id,
        devices: action.deviceIds
      }))
        .setIn(['groups', 'records'], currentList.push(action.id))
        .setIn(['groups', 'loading'], false);

      return nextState;
    });
  },


  [deviceGroupsActions.FETCH_DEVICE_GROUP_FAILED] (state,) {
    return state.withMutations((nextState) => {
      nextState.setIn(['groups', 'loading'], false);
      return nextState;
    });
  },


  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default deviceGroupsReducer;
