import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DataTable from 'src/component/UI/DataTable';
import { fetchCommLoopsDeviceBreakdown } from 'src/module/device/action';
import { getBreakdownPageInfo, getCoomLoopDeviceBreakdown } from 'src/module/device/selector';

import { formatNumberByCommas } from 'src/utils/utils';
import DeviceLink from 'src/component/UI/DeviceLink';

export default function DeviceBreakDeviceList ({ deviceId }) {

  const pageInfo = useSelector(getBreakdownPageInfo(deviceId));
  const breakdown = useSelector(getCoomLoopDeviceBreakdown(deviceId));
  const [page, setPage] = useState(pageInfo.get('page', 0));
  const [pageSize, setPageSize] = useState(pageInfo.get('pageSize', 10));
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);

  const dispatch: any = useDispatch();

  const fetchPage = (_page, _pageSize, _filter, _sort) => {
    setPage(_page);
    setPageSize(_pageSize);
    setFilter(_filter);
    setSort(_sort);
  };


  useEffect(() => {
    const paginationDetails = { page, pageSize, filter, sort };
    dispatch(fetchCommLoopsDeviceBreakdown(paginationDetails, deviceId));
  }, [page, pageSize, filter, sort, deviceId]);

  const dataColumns = [
    {
      id: 'tag',
      Header: 'Device Tag',
      accessor: (row: any) => row.get('tag'),
      sortable: true,
      Cell: (row: any) => {
        return <DeviceLink
          label={row.cell.row.original.get('tag')}
          controllerTag={row.cell.row.original.get('parent_tag')}
          circuitTag={row.cell.row.original.get('tag')}
        />;
      }
    },
    {
      id: 'totalSent',
      Header: 'Total Messages Sent',
      accessor: (row: any) => row.getIn(['total_messages']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'totalDropped',
      Header: 'Dropped Messages',
      accessor: (row: any) => row.getIn(['dropped_messages']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'totalSkipped',
      Header: 'Skipped Messages',
      accessor: (row: any) => row.getIn(['skipped_messages']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'modbusExceptions',
      Header: 'Modbus Errors',
      accessor: (row: any) => row.getIn(['modbus_exceptions']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? formatNumberByCommas(row.cell.value) : 0; }
    },
    {
      id: 'commFailRatio',
      Header: 'Comm Fail Ratio',
      accessor: (row: any) => {
        const errors = row.getIn(['modbus_exceptions']);
        const total = row.getIn(['total_messages']);
        let percentage: any = 0;
        if (total > 0 && errors > 0) {
          percentage = (errors * 100) / total;
          return Number.isInteger(percentage) ? `${parseInt(percentage)}%` : `${percentage?.toFixed(2)}%`;
        }
        return `0%`;
      },
      disableSortBy: true
    },
    {
      id: 'avgMessageTime',
      Header: 'Average Message Time',
      accessor: (row: any) => row.getIn(['avg_message_time']),
      sortable: true,
      Cell: (row: any) => { return row.cell.value ? `${formatNumberByCommas(parseInt(row.cell.value))} ms` : `0 ms`; }
    }
  ];

  return (
    <div className='comm-loops-list' data-testid='comm-loops-list'>
      <DataTable
        columns={dataColumns}
        data={breakdown}
        pagination
        numPages={pageInfo.get('totalPages', 0)}
        totalRecords={pageInfo.get('total', 0)}
        loading={pageInfo.get('loading', false)}
        fetchPage={fetchPage}
        tableId='deviceBreakdown'
      />
    </div>
  );
}

DeviceBreakDeviceList.propTypes = {
  deviceId: PropTypes.string

};

DeviceBreakDeviceList.defaultProps = {
};
