import './BatchConfirmScreen.scss';

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, MenuItem, Paper, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';

import { initiateRunBatch, updateBatchSchedule, validateBatch } from 'src/module/batchSchedule/action';
import { getBatch, getLoadingBatchSchedules } from 'src/module/batchSchedule/selector';
import Button from 'src/component/UI/Button';
import { getRecipe } from 'src/module/recipe/selector';
import PermissionFence from 'src/component/PermissionFence';
import { getDisplayValueForRecipeSetting, getDisplayLabelForRecipeSetting } from 'src/utils/utils';
import TaskScheduler from 'src/component/UI/TaskScheduler';
import { useParams } from 'react-router';
import { goBackInHistory } from 'src/module/navigation/action';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Select from '@mui/material/Select';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@mui/icons-material/Send';
import BatchDeviceList from './BatchDeviceList';
import { Map } from 'immutable';
import { getTablePagePreferences, getTemperatureUnit } from 'src/module/authentication/selector';

export default function BatchConfirm (props) {
  const {
    statusFilter,
    setStatusFilter
  } = props;
  const dispatch: any = useDispatch();
  const temperatureUnit = useSelector(getTemperatureUnit);
  const params = useParams();
  const batchId = params.batch_id;
  const batch = useSelector(getBatch(batchId));
  const recipe = useSelector(getRecipe(batch.getIn(['recipe_id'])));
  const recipeSettings = recipe ? Object.values(recipe?.getIn(['settings'], Map()).toJS()) : null;
  const [taskSchedulerModalOpen, setTaskSchedulerModalOpen] = useState(false);
  const batchLoading = useSelector(getLoadingBatchSchedules);
  const [open, setOpen] = React.useState(false);
  const userPagePreference = useSelector(getTablePagePreferences('batch-confirm'));

  const handleBatchSubmit = () => {
    dispatch(initiateRunBatch(batchId));
  };

  const closeTaskSchedulerModal = () => {
    setTaskSchedulerModalOpen(false);
  };
  const handleScheduleBatch = () => {
    setTaskSchedulerModalOpen(true);
  };
  const handleRevalidateBatch = () => {
    dispatch(validateBatch(batchId, { pageSize: userPagePreference, page: 0 }, statusFilter));
  };
  const handleScheduleBatchSubmit = (dateTime) => {
    dispatch(updateBatchSchedule(batchId, { scheduled_at: dateTime }));
  };
  const getSettingModificationText = (setting) => {
    const settingType = setting?.updateType;
    if (settingType === '+') {
      return 'increased by';
    }
    if (settingType === '-') {
      return 'decreased by';
    }
    return 'changed to';
  };
  return (
    <div id='batch-confirm-screen' className='page' data-testid='grouped-devices-screen'>

      <div style={{ position: 'relative' }}>
        <Button onClick={() => dispatch(goBackInHistory())} className="back-button" compact icon={<ArrowBackIosOutlinedIcon style={{ color: 'white', fontSize: 50 }} />} >Back</Button>
        <h2>Mass Programming Preview</h2>

      </div>
      <div>
        <Paper className='confirm'>
          <div className='recipe-conditions'>
            {
              recipe && recipe.get('name') ? <p style={{ fontWeight: 'bold', }}>{`Applying Template '${recipe.get('name')}':`}</p> : null
            }
            {
              recipeSettings ? recipeSettings
                .map((setting: any, idx) =>
                  <div key={'recipe-condition-container-' + String(idx)}>
                    Devices <b key={'recipe-condition-label-' + String(idx)} className='recipe-value'>{getDisplayLabelForRecipeSetting(setting.label)}</b>
                    will be {getSettingModificationText(setting)}<b key={'recipe-condition-value-' + String(idx)} className='recipe-value'>{getDisplayValueForRecipeSetting(setting.label, setting.value, temperatureUnit)}</b>
                    {
                      setting.label === 'forcedOn' ?
                        <Tooltip
                          key={'recipe-condition-tool-tip-' + String(idx)}
                          data-testid='recipe-condition-tool-tip'
                          title={<div style={{ whiteSpace: 'pre-line' }} >{'Devices without forced-on capability will have their maintain temp set to 300'}</div>}
                          enterTouchDelay={0}>
                          <InfoIcon key={'recipe-condition-tooltip-icon-' + String(idx)} style={{ fontSize: 20, cursor: 'pointer' }} />
                        </Tooltip> : null
                    }
                  </div>
                )
                : null
            }
          </div>
          <div className='confirm-buttons'>
            <div className='options'>
              <PermissionFence can={['program-device']}>
                <div className='custom-options'>
                  <FormControl style={{ padding: 0 }}>
                    <Select
                      labelId="controlled-open-select-label"
                      className='select-button'
                      id="controlled-open-select"
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() =>  batch.get('numErrors') === 0 ? handleBatchSubmit() : handleRevalidateBatch()}// clicking on run batch when dropdown is closed
                      value={batch.get('numErrors') === 0 ? 0 : 10}
                      label="Age"
                      onChange={() => setOpen(false)}
                    >
                      <MenuItem className="batch-options" value={0} onClick={handleBatchSubmit} disabled={batchLoading ||  batch.get('numErrors') !== 0}>
                        <span>Run Now</span>
                        <SendIcon />
                      </MenuItem>
                      <MenuItem className="batch-options" value={10} onClick={handleRevalidateBatch} disabled={batchLoading} >
                        <span>Re-Validate</span>
                        <PublishedWithChangesIcon />
                      </MenuItem>
                      <MenuItem className="batch-options" value={20} onClick={handleScheduleBatch} disabled={batchLoading ||  batch.get('numErrors') !== 0} >
                        <span>Schedule Run</span>
                        <ScheduleSendIcon />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className='section' onClick={() => setOpen(true)}>
                    <ExpandMoreIcon fontSize={'large'} />
                  </div>
                </div>
              </PermissionFence>
            </div>
          </div>

          <BatchDeviceList batchId={batchId} statusFilter={statusFilter} setStatusFilter={setStatusFilter}/>

          <TaskScheduler
            open={taskSchedulerModalOpen}
            title="Schedule Mass Program"
            handleClose={closeTaskSchedulerModal}
            handleSubmit={handleScheduleBatchSubmit}
            saveButtonText='Schedule'
          />
        </Paper>
      </div>
    </div>
  );
}

BatchConfirm.propTypes = {
  statusFilter: PropTypes.string,
  setStatusFilter: PropTypes.func
};

BatchConfirm.defaultProps = {};
