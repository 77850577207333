import './MaintenanceScreen.scss';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { fromJS, List, Map } from 'immutable';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import UndoIcon from '@mui/icons-material/Undo';
import UnitSelector from 'src/component/UI/UnitSelector';
import CheckCircleIcon from '@mui/icons-material/CheckOutlined';
import ValidateIcon from '@mui/icons-material/BorderColorOutlined';
import AlertIcon from '@mui/icons-material/ReportProblemOutlined';
import Button from 'src/component/UI/Button';
import NotificationNumberModal from './NotificationNumberModal';
import AlarmNoteModal from './AlarmNoteModal';
import { formatAlarmLabel } from 'src/component/AlarmsList';
import DataTable from 'src/component/UI/DataTable';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';

import { getSettingsModal } from 'src/module/device/selector';
import { setSettingsModalOpen, setSettingsModalClose } from 'src/module/device/action';
import { fetchAlarmStats, fetchDevicesInAlarmWithoutNotification, validateAlarms, dismissAlarms, undoAlarms, dismissAlarmsByGroup } from 'src/module/alarm/action';
import { getDevicesInAlarmWithoutNotification, getDevicesInAlarmLoading, getAlarmStats, getCriticalAlarmsWithoutNotification } from 'src/module/alarm/selector';
import { getIntegrityChecks } from 'src/module/facility/selector';
import { getDevicesSelectedFilters, getIsAllFiltersEmpty } from 'src/module/filter/selector';
import { fetchIntegrityChecks } from "src/module/facility/action";
import { getPluralWorkTicketLabel, getSingularWorkTicketLabel, getCriticalityLabels, getTemperatureUnit } from 'src/module/authentication/selector';
import { celsiusToFahrenheitConvertor, formatDate, isWhitelabel } from 'src/utils/utils';
import { Tooltip } from '@mui/material';
import LoadingPlaceholder from 'src/component/UI/LoadingPlaceholder';
import PermissionFence from 'src/component/PermissionFence';
import toast from 'src/utils/toast';
import { NavLink } from 'react-router-dom';
import variables from 'src/style/variable/variables.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import DeviceLinkCombo from 'src/component/UI/DeviceLinkCombo';

export default function MaintenanceScreen () {
  const dispatch = useDispatch();

  const settingsModal = useSelector(getSettingsModal(null));
  const criticalAlarmsWithoutNotification = useSelector(getCriticalAlarmsWithoutNotification);
  const isAllFiltersEmpty = useSelector(getIsAllFiltersEmpty);
  const devicesInAlarmWithoutNotification = useSelector(getDevicesInAlarmWithoutNotification);
  const integrityChecks = useSelector(getIntegrityChecks);
  const alarmStats = useSelector(getAlarmStats);
  const criticalityLabels = useSelector(getCriticalityLabels);
  const loading = useSelector(getDevicesInAlarmLoading);
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const singularWorkTicketLabel = useSelector(getSingularWorkTicketLabel);
  const temperatureUnit = useSelector(getTemperatureUnit);
  const selectedFilters = useSelector(getDevicesSelectedFilters);

  const [activeRowId, setActiveRowId] = useState(null);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [alarmNoteModalOpen, setAlarmNoteModalOpen] = useState(false);
  const [alarms, setAlarms] = useState(List());
  const [controllerId, setControllerId] = useState(null);
  const [circuitId, setCircuitId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [devicesInAlarmWithoutNotificationByCriticality, setDevicesInAlarmWithoutNotificationByCriticality] = useState(List());
  const [alarmGroupCollapsed, setAlarmGroupCollapsed] = useState(List());
  const [hasActionOccurred, setHasActionOccurred] = useState(false);
  const [criticality, setCriticality] = useState(null);
  const [criticalitylabel, setCriticalityLabel] = useState('');
  const [alarmGroupOptions, setAlarmGroupOptions] = useState([{ label: '', value: '' }]);

  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);

  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  const criticalities = [];

  for (let i = criticalityLabels.size - 1; i >= 0; i--) {
    criticalities.push({ label: criticalityLabels.get(i), value: i });
  }

  useEffect(() => {
    // init alarm group collapse state
    const alarmGroupCollapsedArray = [true];

    for (let i = 1; i < criticalityLabels.size; i++) {
      alarmGroupCollapsedArray[i] = false;
    }

    setAlarmGroupCollapsed(List(alarmGroupCollapsedArray));
  }, [criticalityLabels]);

  useEffect(() => {
    const optionalFilters = {
      controllerId,
      deviceId
    };

    dispatch(fetchAlarmStats(selectedFilters, Map(optionalFilters)));
    dispatch(fetchIntegrityChecks(selectedFilters));
    dispatch(fetchDevicesInAlarmWithoutNotification(selectedFilters, Map(optionalFilters)));
  }, [selectedFilters]);

  useEffect(() => {
    if (!devicesInAlarmWithoutNotification) {
      return;
    }

    const grouped = [];
    devicesInAlarmWithoutNotification.forEach((device) => {
      const criticality = device.get('criticality');
      if (!grouped[criticality]) {
        grouped[criticality] = List();
      }

      grouped[criticality] = grouped[criticality].push(device);
    });

    setDevicesInAlarmWithoutNotificationByCriticality(List(grouped));
  }, [devicesInAlarmWithoutNotification]);

  const toggleNotificationModal = (rowId=null, alarms=null, deviceId=null) => {
    setNotificationModalOpen(!notificationModalOpen);
    setActiveRowId(rowId);
    setDeviceId(deviceId);
    setAlarms(alarms);
  };

  const handleNotificationSubmit = () => {
    return (notificationNumber, description) => {
      const alarmIds = [];

      alarms.forEach((alarm: any) => {
        alarmIds.push(alarm.get('id'));
      });
      dispatch(validateAlarms(notificationNumber, alarmIds, description, activeRowId, selectedFilters, deviceId));
      toggleNotificationModal();
      setHasActionOccurred(true);
    };
  };

  const makeToggleAlarmNoteModal = (rowId=null, alarms=null, controllerId=null, circuitId=null, deviceId=null) => {
    return () => {
      toggleAlarmNoteModal(rowId, alarms, controllerId, circuitId, deviceId);
    };
  };
  const undo = (rowId=null, alarms=null, controllerId=null, circuitId=null) => {
    const alarmIds = [];

    alarms.forEach((alarm: any) => {
      alarmIds.push(alarm.get('id'));
    });
    dispatch(undoAlarms(controllerId, circuitId, alarmIds, rowId));
  };

  const toggleAlarmNoteModal = (rowId=null, alarms=null, controllerId=null, circuitId=null, deviceId=null) => {
    setAlarmNoteModalOpen(!alarmNoteModalOpen);
    setActiveRowId(rowId);
    setControllerId(controllerId);
    setCircuitId(circuitId);
    setDeviceId(deviceId);
    setAlarms(alarms);
  };

  const handleAlarmNote = () => (alarmNote, expiry, subject) => {
    const alarmIds = [];

    alarms.forEach((alarm: any) => {
      alarmIds.push(alarm.get('id'));
    });
    const pageInfo = {};
    dispatch(dismissAlarms(alarmNote, controllerId, circuitId, alarmIds, expiry, subject, selectedFilters, pageInfo, activeRowId, deviceId));

    toggleAlarmNoteModal();
    setHasActionOccurred(true);
  };


  const noteSummaryData = [
    { label: 'Nuisance Alarms', value: alarmStats.getIn(['notesByHtc', 'nuisance'], 'N/A') },
    { label: 'LOTO Circuits', value: alarmStats.getIn(['notesByHtc', 'loto'], 'N/A') },
    { label: 'Offline Circuits', value: alarmStats.getIn(['notesByHtc', 'offline'], 'N/A') },
    { label: 'Other', value: alarmStats.getIn(['notesByHtc', 'other'], 'N/A') },
  ];

  const integrityChecksData = [
    { label: 'Alarms w/o Simplification', value: integrityChecks?.get('alarms_without_simplification', 'N/A').toString() },
    { label: 'Alarms w/o Device', value: integrityChecks?.get('alarms_without_device', 'N/A').toString()  },
    { label: 'Circuits w/o Unit', value: integrityChecks?.get('circuits_without_unit', 'N/A').toString()  },
    { label: 'Duplicate Circuit Tags', value: integrityChecks?.get('duplicate_circuit_tags', 'N/A').toString()  },
    { label: 'Circuits w/o Controller', value: integrityChecks?.get('circuits_without_controller', 'N/A').toString()  },
    { label: 'Controllers w/o Unit', value: integrityChecks?.get('controllers_without_unit', 'N/A').toString()  },
    { label: 'Notes w/o Device', value: integrityChecks?.get('notes_without_device', 'N/A').toString() },
    { label: `${pluralWorkTicketLabel} w/o Device`, value: integrityChecks?.get('sap_notifications_without_device', 'N/A').toString() },
  ];

  const raisedAtSortFunction = (_a, _b, sort) => {
    const _aMostRecentDate = _a.get('alarms').maxBy(alarm => new Date(alarm.get('alarm_raised_at')).getTime());
    const _bMostRecentDate = _b.get('alarms').maxBy(alarm => new Date(alarm.get('alarm_raised_at')).getTime());

    const a = _aMostRecentDate?.get('alarm_raised_at') ?? null;
    const b = _bMostRecentDate?.get('alarm_raised_at') ?? null;

    if (a && b) {
      return (a < b) ? sort : sort*-1;
    } else if (!a) {
      return sort*-1;
    } else {
      return sort*1;
    }
  };

  const criticalAlarmsAscSortFunction = (_a, _b) => {
    const a = _a.getIn(['alarms', 0, 'alarm_group']);
    const b = _b.getIn(['alarms', 0, 'alarm_group']);

    if (a && b) {
      return (a < b) ? 1 : -1;
    } else if (!a) {
      return -1;
    } else {
      return 1;
    }
  };

  const criticalAlarmsDescSortFunction = (_a, _b) => {
    const a = _a.getIn(['alarms', 0, 'alarm_group']);
    const b = _b.getIn(['alarms', 0, 'alarm_group']);

    if (a && b) {
      return (a < b) ? 1 : -1;
    } else if (!b) {
      return -1;
    } else {
      return 1;
    }
  };

  const htcAscSortFunction = (a, b) => {
    return parseInt(a.get('heat_trace_circuit_num')) > parseInt(b.get('heat_trace_circuit_num')) ? 1 : -1;
  };

  const htcDescSortFunction = (a, b) => {
    return parseInt(a.get('heat_trace_circuit_num')) < parseInt(b.get('heat_trace_circuit_num')) ? 1 : -1;
  };

  const deviceTagAscSortFunction = (a, b) => {
    return (a.get('controller_tag' ) || a.get('raw_tag')) > (b.get('controller_tag' ) || b.get('raw_tag')) ? 1 : -1;
  };

  const deviceTagDescSortFunction = (a, b) => {
    return (a.get('controller_tag' ) || a.get('raw_tag')) < (b.get('controller_tag' ) || b.get('raw_tag')) ? 1 : -1;
  };

  const makeToggleAlarmGroup = (criticality) => {
    return () => {
      setAlarmGroupCollapsed(alarmGroupCollapsed.set(criticality, !alarmGroupCollapsed.get(criticality)));
    };
  };

  const formatAlarmCell = (alarm) => {
    const noteExpiredAt = alarm.get('note_expired_at');
    const label = formatAlarmLabel(alarm.get('description'));

    return (
      noteExpiredAt ? (
        <Tooltip title='Note Expired' className='warning-text'>
          <div className='cell-with-icon'><span className='icon'><AlertIcon width={16} height={16} /></span><span className='label'>{label}</span></div>
        </Tooltip>
      ) : label
    );
  };

  useEffect(() => {
    dispatch(setSettingsModalClose(null));
  }, []);

  const makeToggleDismissAlarmsGroupModal = () => {
    return () => {
      toggleDismissAlarmsGroupModal();
    };
  };

  const toggleDismissAlarmsGroupModal = () => {
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(null, SettingsModalTypes.DISMISS_ALARM_GROUP));
    }
    else {
      dispatch(setSettingsModalClose(null));
    }
  };
  const handleDismissAlarmsGroupSubmit = (rec: any) => {
    dispatch(dismissAlarmsByGroup(rec.get('alarmGroup'), criticality, selectedFilters));
    setHasActionOccurred(true);
  };

  const alarmGroupsArr = [], alarmGroupOpts = [];

  const openAlarmGroupDismissalModal = (critical) => {
    setCriticality(critical);
    const records: any = devicesInAlarmWithoutNotificationByCriticality.get(critical);

    // building alarm group options array for settings modal to show in drop down
    records?.map(record => {
      const alarmsList = record.get('alarms');
      alarmsList?.map(alarm => {
        if (alarm.get('alarm_group') && !alarmGroupsArr.includes(alarm.get('alarm_group'))) {
          alarmGroupsArr.push(alarm.get('alarm_group'));
        }
      });
    });
    alarmGroupsArr?.map(group => alarmGroupOpts.push({ label: formatAlarmLabel(group), value: group }));
    setAlarmGroupOptions(alarmGroupOpts);
    toggleDismissAlarmsGroupModal();
  };
  const criticalityOptions = [];
  for (let i=0; i < criticalityLabels.size; i++) {
    criticalityOptions.push({ label: criticalityLabels.get(i), value: `${i}` });
  }

  useEffect(() => {
    criticalities.map( x => {
      if (x.value === criticality) {
        setCriticalityLabel(x.label);
      }
    });
  }, [criticality]);

  useEffect(() => {
    if (hasActionOccurred && criticalAlarmsWithoutNotification === 0 && isAllFiltersEmpty ) {
      toast.success(<div>Nice work! All critical alarms have been validated. Now just send the report!
        <NavLink className='finishReport' to={'report/send'} > Finish Report </NavLink></div>, {
        autoClose: false,
      });
    }
  }, [hasActionOccurred, criticalAlarmsWithoutNotification]);
  const columns = [];

  columns.push({
    id: 'tag',
    Header: 'Device Tag',
    accessor: (row: any) => row.get('circuit_tag'),
    sortFunction: deviceTagAscSortFunction,
    sortDescFunction: deviceTagDescSortFunction,
    maxWidth: '200px',
    Cell: (row: any) => { return (
      <DeviceLinkCombo device={row.cell.row.original} />
    ); },
    ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
  });

  columns.push(
    {
      id: 'heat_trace_circuit_num',
      Header: 'HTC',
      width: '80px',
      accessor: (row: any) => row.get('heat_trace_circuit_num'),
      sortFunction: htcAscSortFunction,
      sortDescFunction: htcDescSortFunction,
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'alarms',
      Header: 'Critical Alarms',
      accessor: (row: any) => row.get('alarms'),
      minWidth: '220px',
      sortFunction: criticalAlarmsAscSortFunction,
      sortDescFunction: criticalAlarmsDescSortFunction,
      Cell: (row: any) => row.cell.value.map((alarm, index) => (<div key={index} className='alarm-list'>{formatAlarmCell(alarm)}</div>)),
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'temperature',
      Header: 'Alarm Temp',
      accessor: (row: any) => row.get('alarms'),
      Cell: (row: any) => row.cell.value.map((alarm, index) => (<div key={index} className='alarm-list'>{temperatureUnit === 'F' && alarm.get('actual_temperature') ? celsiusToFahrenheitConvertor(alarm.get('actual_temperature')) : alarm.get('actual_temperature')}</div>)),
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'current',
      Header: 'Alarm Current',
      accessor: (row: any) => row.get('alarms'),
      Cell: (row: any) => { return row.cell.value.map((alarm, index) => (<div key={index} className='alarm-list'>{alarm.get('actual_current')}</div>)); },
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'setting',
      Header: 'Setting',
      accessor: (row: any) => row.get('alarms'),
      Cell: (row: any) => { return row.cell.value.map((alarm, index) => {
        const alarmName = alarm?.get('alarm');
        let value = alarm.get('setting');
        if (alarmName?.includes('temp')) {
          if (temperatureUnit === 'F' && alarm.get('setting')) {
            value = celsiusToFahrenheitConvertor(alarm.get('setting'));
          }
        }
        return (<div key={index} className='alarm-list'>{value}</div>);
      }); },
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'real_temperature',
      Header: 'Actual Temp',
      disableSortBy: true,
      accessor: (row: any) => row.get('alarms'),
      Cell: (row: any) => {
        let realTempRendered = false;
        return row.cell.value.map((alarm, index) => {
          const alarmName = alarm?.get('alarm');
          let realTemp = alarm.get('real_temperature');
          if (!alarmName) {
            return 'N/A';
          } else if (alarmName?.includes('temp') && !realTempRendered) {
            realTemp = realTemp && temperatureUnit === 'F' ? celsiusToFahrenheitConvertor(realTemp) : realTemp;
            realTempRendered = true;
            return (<div key={index} className='alarm-list'>{realTemp}</div>);
          }
        }); },
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'real_current',
      Header: 'Actual Current',
      disableSortBy: true,
      accessor: (row: any) => row.get('alarms'),
      Cell: (row: any) => {
        let realCurrentRendered = false;
        return row.cell.value.map((alarm, index) => {
          const alarmName = alarm?.get('alarm');
          const description = alarm?.get('description');
          const realCurrent = alarm.get('real_current');
          if (!alarmName) {
            return 'N/A';
          } else if (description?.includes('Load Current') && !realCurrentRendered) {
            realCurrentRendered = true;
            return (<div key={index} className='alarm-list'>{realCurrent}</div>);
          }
        }); },
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : ''
    },
    {
      id: 'alarm_raised_at',
      Header: 'Alarm Raised At',
      accessor: (row: any) => row.get('alarms'),
      sortFunction: (v1, v2) => raisedAtSortFunction(v1, v2, 1),
      sortDescFunction: (v1, v2) => raisedAtSortFunction(v1, v2, -1),
      Cell: (row: any) => { return row.cell.value.map((alarm, index) => (<div key={index} className='alarm-list'>{formatDate(alarm.get('alarm_raised_at'))}</div>)); },
      ClassName: (row: any) => row.original.get('invalidated') ? 'line-through' : '',
      minWidth: '220px'
    },
    {
      Header: ' ',
      accessor: (row: any) => row.get('index'),
      maxWidth: '60px',
      disableSortBy: true,
      Cell: (row: any) => row.cell.row.original.get('invalidated') ?
        <div className='maintenance-actions'>
          <PermissionFence can={['edit-eht-note', 'edit-device']}>
            <Button onClick={() => undo(row.cell.value, row.cell.row.original.get('alarms'), row.cell.row.original.get('controller_id'), row.cell.row.original.get('circuit_id'))} icon={<UndoIcon style={{ fontSize: 14 }} />} />
          </PermissionFence>
        </div>
        : (
          <div className='maintenance-actions'>
            <PermissionFence can={['edit-eht-note', 'edit-device']}>
              <Button onClick={makeToggleAlarmNoteModal(row.cell.value, row.cell.row.original.get('alarms'), row.cell.row.original.get('controller_id'), row.cell.row.original.get('circuit_id'), row.cell.row.original.get('device_id'))} icon={<ValidateIcon style={{ fontSize: 14 }} />} />
            </PermissionFence>
            <PermissionFence can={['edit-sap-notification', 'edit-device']}>
              <Button onClick={() => toggleNotificationModal(row.cell.value, row.cell.row.original.get('alarms'),  row.cell.row.original.get('device_id'))} icon={<CheckCircleIcon style={{ fontSize: 14 }} />} />
            </PermissionFence>
          </div>
        )
    }
  );

  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';

  return (
    <div className='page' id='maintenance'>
      <Helmet>
        <title>{title} - Maintenance</title>
      </Helmet>
      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === SettingsModalTypes.DISMISS_ALARM_GROUP}
        handleClose={makeToggleDismissAlarmsGroupModal()}
        handleSubmit={handleDismissAlarmsGroupSubmit}
        title={`Batch reset alarms by group having criticality ${criticalitylabel}`}
        loading={settingsModal?.get('loading')}
        fields={
          fromJS({
            alarmGroup: {
              type: 'select',
              label: 'Alarm Group',
              options: alarmGroupOptions,
              errorText: 'Required',
            }
          })
        }
      />

      {(notificationModalOpen) ?
        <NotificationNumberModal open={notificationModalOpen} handleClose={() => toggleNotificationModal()} handleSubmit={handleNotificationSubmit()} /> : ''}
      {(alarmNoteModalOpen) ?
        <AlarmNoteModal open={alarmNoteModalOpen} alarms={alarms} circuitId={circuitId} controllerId={controllerId} deviceId={deviceId} handleClose={makeToggleAlarmNoteModal()} handleSubmit={handleAlarmNote()} /> : ''}
      <Grid container spacing={2}>

        <Grid item xs={12} md={12}>
          <Paper>
            <UnitSelector />
          </Paper>
        </Grid>


        <Grid item xs={12} md={6} lg={6}>
          <Paper>
            <h2>Circuit Status Summary</h2>
            <DataTable
              columns={[
                {
                  id: 'circuit-status-label',
                  Header: '',
                  accessor: row => row.label,
                },
                {
                  id: 'circuit-status-value',
                  Header: '',
                  accessor: row => row.value,
                  width: '54px'
                }
              ]}
              data={List(noteSummaryData)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper>
            <h2>Integrity Checks</h2>
            <DataTable
              columns={[
                {
                  id: 'integrity-checks-label',
                  Header: '',
                  accessor: row => row.label,
                },
                {
                  id: 'integrity-checks-value',
                  Header: '',
                  accessor: row => row.value ?? 'N/A',
                  width: '80px'
                }
              ]}
              data={List(integrityChecksData)}
            />
          </Paper>
        </Grid>

        <div className='maintenance-title-bar'>
          <h2>{`Alarms Without ${singularWorkTicketLabel}`}</h2>
        </div>
        {loading ?
          <LoadingPlaceholder /> :
          criticalities.map((criticality, idx) => {
            const collapsed = alarmGroupCollapsed.get(criticality.value, false);
            return (
              <Grid key={idx} item xs={12} md={12} lg={12}>
                <Paper>
                  {showRotateMessage ? <><h3>Priority: {criticality.label}</h3><DeviceRotate/></> :
                    <h3 className='expandable' onClick={makeToggleAlarmGroup(criticality.value)}>Priority: {criticality.label}&nbsp;<span className='expand-arrow'>{collapsed ? '▲' : '▼'}</span></h3>}
                  {!collapsed && !showRotateMessage ? <>
                    <PermissionFence can={['edit-eht-note', 'edit-device']}>
                      {(devicesInAlarmWithoutNotificationByCriticality.get(criticality.value)) ? <Button cta className='group-dismiss-button' onClick={() => openAlarmGroupDismissalModal(criticality.value)}>Batch Reset Alarms</Button> : null}
                    </PermissionFence>
                    <DataTable
                      stickyHeader
                      columns={columns}
                      data={devicesInAlarmWithoutNotificationByCriticality.get(criticality.value) || List()}
                      pagination
                      paginationPerPage={25}
                      tableId={`maintenanceScreenTable${idx}`}
                    /></> : null
                  }
                </Paper>
              </Grid>
            );
          })
        }

      </Grid>
    </div>
  );
}
