import { Map, merge } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as checkSheetActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  checkSheets: Map(),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.set('checkSheets', Map())
  );
}

export const checkSheetReducer = createTypedReducer(initialState, {
  [checkSheetActions.FETCH_CHECK_SHEET_TASKS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['checkSheets', 'tasks', 'loading'], true)
    );
  },
  [checkSheetActions.FETCH_CHECK_SHEET_TASKS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['checkSheets', 'tasks', action.checkSheetType], action.tasks)
        .setIn(['checkSheets', 'tasks', 'loading'], false)
    );
  },
  [checkSheetActions.FETCH_CHECK_SHEET_TASKS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['checkSheets', 'tasks', 'loading'], false)
    );
  },

  [checkSheetActions.UPDATE_CHECK_SHEET_TYPE_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'data', action.checkSheetId, 'record', 'check_sheet_type'], action.checkSheetType);
    });
  },
  [checkSheetActions.FETCH_CHECK_SHEET] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'loading'], true);
    });
  },
  [checkSheetActions.FETCH_CHECK_SHEETS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      action.data.map((checkSheet) => {
        nextState.setIn(['checkSheets', 'data', checkSheet.get('id')], {
          record: checkSheet,
          lastFetch: fetchDate,
        });
      });
    });
  },

  [checkSheetActions.FETCH_CHECK_SHEET_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      const curr = nextState.getIn(['checkSheets', 'data', action.checkSheetId, 'record']);
      const combinedRecord = merge(Map(curr), action.data);
      nextState.setIn(['checkSheets', 'data', action.checkSheetId], {
        record: combinedRecord,
        lastFetch: fetchDate,
      });
      nextState.setIn(['checkSheets', 'loading'], false);
    });
  },
  [checkSheetActions.FETCH_CHECK_SHEET_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'loading'], false);
    });
  },
  [checkSheetActions.SAVE_CHECK_SHEET] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit',  action.checkSheetType, 'loading'], true);
    });
  },
  [checkSheetActions.SAVE_CHECK_SHEET_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'loading'], false);
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'open'], false);
      const fetchDate = new Date();

      nextState.setIn(['checkSheets', 'data', action.data.get('id')], {
        record: action.data,
        lastFetch: fetchDate,
      });

    });
  },
  [checkSheetActions.SAVE_CHECK_SHEET_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'loading'], false);

    });
  },
  [checkSheetActions.OPEN_CHECK_SHEET] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'loading'], false);
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'open'], true);
    });
  },
  [checkSheetActions.CLOSE_CHECK_SHEET] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'loading'], false);
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'open'], false);
    });
  },
  [checkSheetActions.UPDATE_CHECK_SHEET_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'loading'], false);
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'open'], false);
      const fetchDate = new Date();

      nextState.setIn(['checkSheets', 'data', action.data.get('id')], {
        record: action.data,
        lastFetch: fetchDate,
      });

    });
  },
  [checkSheetActions.UPDATE_CHECK_SHEET_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['checkSheets', 'submit', action.checkSheetType, 'loading'], false);

    });
  },





  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default checkSheetReducer;
