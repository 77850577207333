import yup from '../../utils/yup';
import createTypedAction from '../createTypedAction';

export const PREFIX = '@@fox/authentication/';

export const { authenticate, AUTHENTICATE }: any = createTypedAction(
  `${PREFIX}AUTHENTICATE`,
  ['service', yup.string().required()],
  ['serviceParams', yup.object().required()]
);
export const { multiFactorAuthenticate, MULTI_FACTOR_AUTHENTICATE }: any = createTypedAction(
  `${PREFIX}MULTI_FACTOR_AUTHENTICATE`,
  ['code', yup.string().required()],
  ['isOTP', yup.boolean().required()],

);
export const { resendMultiFactorAuthenticate, RESEND_MULTI_FACTOR_AUTHENTICATE }: any = createTypedAction(
  `${PREFIX}RESEND_MULTI_FACTOR_AUTHENTICATE`,
  ['option', yup.string().required()],
);
export const { authenticateWithToken, AUTHENTICATE_WITH_TOKEN }: any = createTypedAction(
  `${PREFIX}AUTHENTICATE_WITH_TOKEN`,
  ['service', yup.string().required()],
  ['serviceParams', yup.object().required()]
);

export const { authenticateFailed, AUTHENTICATE_FAILED }: any = createTypedAction(
  `${PREFIX}AUTHENTICATE_FAILED`,
  ['service', yup.string().required()],
  ['errors', yup.immutable().list().required()]
);

export const { authenticateMfaRequired, AUTHENTICATE_MFA_REQUIRED }: any = createTypedAction(
  `${PREFIX}AUTHENTICATE_MFA_REQUIRED`,
  ['options', yup.immutable().list()],
  ['OTP_URL', yup.string()],
);

export const { authenticated, AUTHENTICATED }: any = createTypedAction(
  `${PREFIX}AUTHENTICATED`,
  ['user', yup.immutable().map().required()],
  ['client', yup.immutable().map().required()],
  ['clients', yup.immutable().map().required()],
  ['abilities', yup.immutable().list().required()],
  ['features', yup.immutable().list().required()],
  ['hsIdentificationAccessToken', yup.string()]
);

export const { requestPasswordReset, REQUEST_PASSWORD_RESET }: any = createTypedAction(
  `${PREFIX}REQUEST_PASSWORD_RESET`,
  ['email', yup.string().required()]
);

export const { validatePasswordJwt, VALIDATE_PASSWORD_JWT }: any = createTypedAction(
  `${PREFIX}VALIDATE_PASSWORD_JWT`,
  ['jwt', yup.string().required()]
);

export const { validatePasswordJwtSuccess, VALIDATE_PASSWORD_JWT_SUCCESS }: any = createTypedAction(
  `${PREFIX}VALIDATE_PASSWORD_JWT_SUCCESS`,
  ['jwt', yup.string().required()],
  ['user', yup.immutable().map().required()]
);

export const { validatePasswordJwtFailed, VALIDATE_PASSWORD_JWT_FAILED }: any = createTypedAction(
  `${PREFIX}VALIDATE_PASSWORD_JWT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { resetPassword, RESET_PASSWORD }: any = createTypedAction(
  `${PREFIX}RESET_PASSWORD`,
  ['jwt', yup.string().required()],
  ['password', yup.string().required()]
);

export const { resetPasswordSuccess, RESET_PASSWORD_SUCCESS }: any = createTypedAction(
  `${PREFIX}RESET_PASSWORD_SUCCESS`
);

export const { resetPasswordFailed, RESET_PASSWORD_FAILED }: any = createTypedAction(
  `${PREFIX}RESET_PASSWORD_FAILED`,
  ['err', yup.immutable().map()]
);

export const { changePassword, CHANGE_PASSWORD }: any = createTypedAction(
  `${PREFIX}CHANGE_PASSWORD`,
  ['oldPassword', yup.string().required()],
  ['newPassword', yup.string().required()]
);

export const { changePasswordFailed, CHANGE_PASSWORD_FAILED }: any = createTypedAction(
  `${PREFIX}CHANGE_PASSWORD_FAILED`,
  ['err', yup.immutable().map()]
);

export const { changePasswordSuccess, CHANGE_PASSWORD_SUCCESS }: any = createTypedAction(
  `${PREFIX}CHANGE_PASSWORD_SUCCESS`,
);

export const { validateInvite, VALIDATE_INVITE }: any = createTypedAction(
  `${PREFIX}VALIDATE_INVITE`,
  ['jwt', yup.string().required()]
);

export const { validateInviteFailed, VALIDATE_INVITE_FAILED }: any = createTypedAction(
  `${PREFIX}VALIDATE_INVITE_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { validateInviteSuccess, VALIDATE_INVITE_SUCCESS }: any = createTypedAction(
  `${PREFIX}VALIDATE_INVITE_SUCCESS`,
  ['user', yup.immutable().map().required()]
);

export const { clear, CLEAR }: any = createTypedAction(
  `${PREFIX}CLEAR`
);

export const { initialize, INITIALIZE }: any = createTypedAction(
  `${PREFIX}INITIALIZE`
);

export const { rehydrate, REHYDRATE }: any = createTypedAction(
  `${PREFIX}REHYDRATE`
);

export const { rehydrateSuccess, REHYDRATE_SUCCESS }: any = createTypedAction(
  `${PREFIX}REHYDRATE_SUCCESS`,
  ['user', yup.immutable().map().required()],
  ['client', yup.immutable().map().required()],
  ['clients', yup.immutable().map().required()],
  ['abilities', yup.immutable().list().required()],
  ['features', yup.immutable().list().required()],
  ['hsIdentificationAccessToken', yup.string()]
);

export const { rehydrateFailure, REHYDRATE_FAILURE }: any = createTypedAction(
  `${PREFIX}REHYDRATE_FAILURE`,
  ['error', yup.object().nullable()]
);

export const { refreshAuthSuccess, REFRESH_AUTH_SUCCESS }: any = createTypedAction(
  `${PREFIX}REFRESH_AUTH_SUCCESS`,
  ['clients', yup.immutable().map().required()],
  ['abilities', yup.immutable().list().required()],
  ['features', yup.immutable().list().required()],
  ['hsIdentificationAccessToken', yup.string()]

);

export const { refreshAuthToken, REFRESH_AUTH_TOKEN }: any = createTypedAction(
  `${PREFIX}REFRESH_AUTH_TOKEN`
);

export const { refreshAuthTokenSuccess, REFRESH_AUTH_TOKEN_SUCCESS }: any = createTypedAction(
  `${PREFIX}REFRESH_AUTH_TOKEN_SUCCESS`,
  ['clients', yup.immutable().map().required()],
  ['abilities', yup.immutable().list().required()],
  ['features', yup.immutable().list().required()]
);

export const { refreshAuthTokenFailure, REFRESH_AUTH_TOKEN_FAILURE }: any = createTypedAction(
  `${PREFIX}REFRESH_AUTH_TOKEN_FAILURE`,
  ['error', yup.object().nullable()]
);

export const { oauthStage2, OAUTH_STAGE_2 }: any = createTypedAction(
  `${PREFIX}OAUTH_STAGE_2`,
  ['jwt', yup.string().required()],
  ['clientId', yup.string().required()],
);

export const { oauthStage2Success, OAUTH_STAGE_2_SUCCESS }: any = createTypedAction(
  `${PREFIX}OAUTH_STAGE_2_SUCCESS`,
  ['clientId', yup.string().required()]
);

export const { oauthStage2Failure, OAUTH_STAGE_2_FAILURE }: any = createTypedAction(
  `${PREFIX}OAUTH_STAGE_2_FAILURE`,
  ['error', yup.object().nullable()]
);

export const { receiveExternalError, RECEIVE_EXTERNAL_ERROR }: any = createTypedAction(
  `${PREFIX}RECEIVE_EXTERNAL_ERROR`,
  ['errorCode', yup.string().required()]
);
export const { updateTab, UPDATE_TAB }: any = createTypedAction(
  `${PREFIX}UPDATE_TAB`,
  ['tabId', yup.string().required()],
  ['clientId', yup.string().required()]
);


export const { fetchClient, FETCH_CLIENT }: any = createTypedAction(
  `${PREFIX}FETCH_CLIENT`
);

export const { fetchClientSuccess, FETCH_CLIENT_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_CLIENT_SUCCESS`,
  ['client', yup.immutable().map().required()]
);

export const { fetchClientFailed, FETCH_CLIENT_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_CLIENT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { updateClient, UPDATE_CLIENT, updateClientQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_CLIENT`,
  (state) => {
    return `update ${state.name} client, preference ${JSON.stringify(state.preferences)}`;
  },
  ['name', yup.string().required()],
  ['preferences', yup.immutable().map().required()]
);

export const { updateClientSuccess, UPDATE_CLIENT_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_CLIENT_SUCCESS`,
  ['client', yup.immutable().map().required()]
);

export const { updateClientFailed, UPDATE_CLIENT_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_CLIENT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { changeClient, CHANGE_CLIENT }: any = createTypedAction(
  `${PREFIX}CHANGE_CLIENT`,
  ['clientId', yup.string().required()]
);

export const { changeClientSuccess, CHANGE_CLIENT_SUCCESS }: any = createTypedAction(
  `${PREFIX}CHANGE_CLIENT_SUCCESS`,
  ['client', yup.immutable().map().required()]
);

export const { clientUpdated, CLIENT_UPDATED }: any = createTypedAction(
  `${PREFIX}CLIENT_UPDATED`,
  ['client', yup.immutable().map().required()]
);

export const { changeClientFailed, CHANGE_CLIENT_FAILED }: any = createTypedAction(
  `${PREFIX}CHANGE_CLIENT_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchUserNotificationPrefs, FETCH_USER_NOTIFICATION_PREFS }: any = createTypedAction(
  `${PREFIX}FETCH_USER_NOTIFICATION_PREFS`
);

export const { fetchUserNotificationPrefsSuccess, FETCH_USER_NOTIFICATION_PREFS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_USER_NOTIFICATION_PREFS_SUCCESS`,
  ['notificationPrefs', yup.immutable().map().required()]
);

export const { fetchUserNotificationPrefsFailed, FETCH_USER_NOTIFICATION_PREFS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_USER_NOTIFICATION_PREFS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { updateUserNotificationPrefs, UPDATE_USER_NOTIFICATION_PREFS, updateUserNotificationPrefsQueueFormatter }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_NOTIFICATION_PREFS`,
  (state) => {
    return `unsubscribe to ${state.notificationKey} notifications`;
  },
  ['notificationKey', yup.string().required()]
);

export const { updateUserNotificationPrefsSuccess, UPDATE_USER_NOTIFICATION_PREFS_SUCCESS }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_NOTIFICATION_PREFS_SUCCESS`,
  ['notificationPref', yup.immutable().map().required()]
);

export const { updateUserNotificationPrefsFailed, UPDATE_USER_NOTIFICATION_PREFS_FAILED }: any = createTypedAction(
  `${PREFIX}UPDATE_USER_NOTIFICATION_PREFS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { deleteUserNotificationPrefs, DELETE_USER_NOTIFICATION_PREFS, deleteUserNotificationPrefsQueueFormatter }: any = createTypedAction(
  `${PREFIX}DELETE_USER_NOTIFICATION_PREFS`,
  (state) => {
    return `subscribe to ${state.notificationKey} notifications`;
  },
  ['notificationKey', yup.string().required()],
);

export const { deleteUserNotificationPrefsSuccess, DELETE_USER_NOTIFICATION_PREFS_SUCCESS }: any = createTypedAction(
  `${PREFIX}DELETE_USER_NOTIFICATION_PREFS_SUCCESS`,
  ['notificationKey', yup.string().required()],
);

export const { deleteUserNotificationPrefsFailed, DELETE_USER_NOTIFICATION_PREFS_FAILED }: any = createTypedAction(
  `${PREFIX}DELETE_USER_NOTIFICATION_PREFS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);


export const { fetchUserNotifications, FETCH_USER_NOTIFICATIONS }: any = createTypedAction(
  `${PREFIX}FETCH_USER_NOTIFICATIONS`
);

export const { fetchUserNotificationsSuccess, FETCH_USER_NOTIFICATIONS_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_USER_NOTIFICATIONS_SUCCESS`,
  ['notifications', yup.immutable().map().required()]
);

export const { fetchUserNotificationsFailed, FETCH_USER_NOTIFICATIONS_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_USER_NOTIFICATIONS_FAILED`,
  ['err', yup.immutable().map().nullable()]
);

export const { fetchUserByEmail, FETCH_USER_BY_EMAIL }: any = createTypedAction(
  `${PREFIX}FETCH_USER_BY_EMAIL`,
  ['email', yup.string().required()]
);
export const { fetchUserByEmailSuccess, FETCH_USER_BY_EMAIL_SUCCESS }: any = createTypedAction(
  `${PREFIX}FETCH_USER_BY_EMAIL_SUCCESS`,
  ['user', yup.immutable().map().required()]
);
export const { fetchUserByEmailFailed, FETCH_USER_BY_EMAIL_FAILED }: any = createTypedAction(
  `${PREFIX}FETCH_USER_BY_EMAIL_FAILED`,
  ['err', yup.immutable().map().nullable()]
);
export const { setUnverifiedContactNumber, SET_UNVERIFIED_CONTACT_NUMBER }: any = createTypedAction(
  `${PREFIX}SET_UNVERIFIED_CONTACT_NUMBER`,
  ['userId', yup.string().required()],
  ['contactNumber', yup.string().required()]
);
export const { logout, LOGOUT }: any = createTypedAction(
  `${PREFIX}LOGOUT`
);
