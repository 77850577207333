import './DeviceProperties.scss';
import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Paper from '@mui/material/Paper';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import EditableCell from 'src/component/UI/EditableCell';
import { fetchCommLoops, updateDevice } from 'src/module/device/action';
import { useSelector, useDispatch } from 'react-redux';
import DeviceLink from 'src/component/UI/DeviceLink';
import { fetchIntegrations } from 'src/module/integration/action';
import { getIntegrations } from 'src/module/integration/selector';
import { celsiusToFahrenheitConvertor, fahrenheitToCelsiusConvertor } from 'src/utils/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import variables from 'src/style/variable/variables.module.scss';
import { getCommLoops, getLoadingModbusProperties } from 'src/module/device/selector';
import { deviceTypeConfig } from 'src/utils/deviceTypeConfig';
import { langLookUpText } from 'src/utils/langLookUp';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import LocationModal from './LocationModal';
import toast from 'src/utils/toast';

function Section ({ children, section, sectionKey }) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <thead key={`${section}-${sectionKey}`}>
        <tr>
          <th className='expandable' colSpan={2} onClick={() => setCollapsed(!collapsed)}>{langLookUpText(section)}  &nbsp;<span className='expand-arrow'>{collapsed ? '▲' : '▼'}</span></th>
        </tr>
      </thead>
      <tbody>
        {collapsed ? children : null}
      </tbody>
    </>
  );
}

export default function DeviceProperties (props) {
  const dispatch = useDispatch();
  const {
    device,
    permission,
    configFields,
    temperatureUnit
  } = props;
  const deviceConfig = deviceTypeConfig[device?.get('type')];
  const integrations = useSelector(getIntegrations);
  const loadingModbusProperties: any = useSelector(getLoadingModbusProperties(device?.get('id')));
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const [collapsed, setCollapsed] = useState(isMobile);
  const is900Series = (device?.get('device_factory') === 'NVent920' || device?.get('device_factory') === 'NVent910');
  let frogName = null, frogId = null;
  const frogClients = [{ label: 'None', value: 'null' }];
  integrations?.forEach(integration => {
    if (integration.get('type') === 'frog') {
      frogId = integration?.get('id');
      frogName = integration?.getIn(['settings', 'name']) || frogId.substring(0, 5);
      frogClients.push({ label: frogName, value: frogId });
    }
  });
  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);
  useEffect(() => {
    dispatch(fetchIntegrations());
    dispatch(fetchCommLoops());
  }, []);

  const handleUpdateDeviceField = (deviceId: string, field: string | number) => {
    return (value) => {
      const rec: any = {};
      const cleanedValue = value === 'null' ? null : value;
      _.setWith(rec, field, cleanedValue, Object);
      dispatch(updateDevice(deviceId, Map(rec)));
    };
  };

  const handleUpdateDeviceNumericField = (deviceId: string, field: string) => {
    return (value) => {
      let cleanedValue;
      if (typeof value === 'string' && value?.includes('.')) {
        const floatValue = parseFloat(value);
        cleanedValue = Math.round(floatValue * 10) / 10;
      } else {
        cleanedValue = parseInt(value, 10);
      }

      if (isNaN(cleanedValue)) {
        cleanedValue = null;
        toast.error(`${langLookUpText(field)} must be a integer!`);
      }

      handleUpdateDeviceField(deviceId, field)(cleanedValue);
    };
  };

  const handleUpdateDeviceTemperatureField = (deviceId: string, field: string) => {
    return (value) => {
      let cleanedValue = value;
      if (field.includes('Temp') && temperatureUnit === 'F' && cleanedValue) {
        cleanedValue = fahrenheitToCelsiusConvertor(cleanedValue);
      }

      handleUpdateDeviceNumericField(deviceId, field)(cleanedValue);
    };
  };


  const formatHtcAddress = () => {
    const htcAddress = device?.get('device_address') || null;

    if (htcAddress !== null) {
      try {
        const deviceFactory = device?.get('device_factory');
        return deviceFactory === 'NVentNGC40' || deviceFactory === 'NVentNGC40IO' ? htcAddress.toString(16) : htcAddress;
      } catch {
        return htcAddress || 'N/A';
      }
    }

    return 'N/A';
  };

  const designedControlModes = configFields?.getIn(['settings', 'deviceOperatingStatus', 'options'],
    List(
      [
        { label: 'Disabled', value: 0 },
        { label: 'Enabled', value: 1 },
        { label: 'Forced-On', value: 2 },
      ]
    )).toJS();

  const designedRtdConfigOptions = configFields?.getIn(['settings', 'rtdConfig', 'options'],
    List()).toJS();

  const comms = useSelector(getCommLoops);
  const commLoops = [{ label: 'None', value: 'null' }];
  comms?.forEach((loop: any) => {
    const id = loop?.get('id');
    const label = loop?.get('tag');
    commLoops.push({ label, value: id });
  });
  const readOnly = (val: string) => {
    return <>{device?.getIn(val, 'N/A') as string || 'N/A'}</>;
  };
  // read only for circuit except for 920s
  const readOnlyCircuits = (val: string) => {
    if (is900Series) {
      return <EditableCell
        onChange={handleUpdateDeviceField(device?.get('id'), val)}
        value={device?.getIn(val, null)}
        emptyCellLabel='N/A'
        permission={permission}
      />;
    }
    return <>{device?.getIn(val, 'N/A') as string || 'N/A'}</>;
  };
  const tempReadOnly = (val: string) => {
    const value = temperatureUnit === 'F' && device?.getIn(val) ? celsiusToFahrenheitConvertor(device?.getIn(val)) : device?.getIn(val) || 'N/A';
    return <>{value}</>;
  };

  const editTemperatureValueSelector = (val) => {
    const value = temperatureUnit === 'F' && device?.getIn(val) ? celsiusToFahrenheitConvertor(device?.getIn(val)) : device?.getIn(val) || null;
    return (
      <EditableCell
        id={`edit-temperature-id`}
        onChange={handleUpdateDeviceTemperatureField(device?.get('id') as string, val.join('.'))}
        value={value}
        emptyCellLabel={'N/A'}
        permission={permission}
      />
    );
  };

  const booleanOptions = [{ value: 0, label: 'No' }, { value: 1, label: 'Yes' }];

  const booleanSelector = (val: Array<string>) => {
    return (
      <EditableCell
        id={`boolean-options-id`}
        onChange={handleUpdateDeviceField(device?.get('id') as string, val.join('.'))}
        value={device?.getIn(val) == null ? null : device?.getIn(val) && device?.getIn(val) !== 0 ? 1 : 0}
        emptyCellLabel={'N/A'}
        options={booleanOptions}
        permission={permission}
      />
    );
  };

  const selector = (val: Array<string>, options: any) => {
    return (
      <EditableCell
        id={`selector-id`}
        onChange={handleUpdateDeviceField(device?.get('id') as string, val.join('.'))}
        value={device?.getIn(val, null) !== null ? device?.getIn(val, null):  null}
        emptyCellLabel='N/A'
        options={options}
        permission={permission}
      />
    );
  };

  const numericSelector = (val: Array<string>, options: any) => {
    return (
      <EditableCell
        id={`selector-id`}
        onChange={handleUpdateDeviceNumericField(device?.get('id') as string, val.join('.'))}
        value={device?.getIn(val, null) !== null ? device?.getIn(val, null):  null}
        emptyCellLabel='N/A'
        options={options}
        permission={permission}
      />
    );
  };

  const offlineSelector = (val: Array<string>) => {
    return (
      <EditableCell
        id={`boolean-options-id`}
        onChange={handleUpdateDeviceField(device?.get('id') as string, val.join('.'))}
        value={device?.getIn(val) == null ? null : device?.getIn(val) && device?.getIn(val) !== '0' ? '1' : '0'}
        emptyCellLabel={'N/A'}
        options={booleanOptions}
        permission={'program-device'}
      />
    );
  };

  const deviceLink = (val: string) => {
    return (<DeviceLink label={device?.getIn(val, 'N/A')} controllerTag={device?.getIn(val, 'N/A')} />);
  };



  const [showLocationModal, setShowLocationModal] = useState(false);


  const googleLink = (val: Array<string>) => {
    const latitude = device?.get(val[0]);
    const longitude = device?.get(val[1]);
    if (latitude && longitude) {
      const url = `https://www.google.com/maps/@${latitude},${longitude}/?t=k&q=${latitude},${longitude}`;
      return <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left'  }}>
        <a href={url} target="_blank" rel="noopener noreferrer">{`${latitude}, ${longitude}`}</a>
        <div style={{ cursor: 'pointer', paddingLeft: '5px' }} onClick={() => setShowLocationModal(true)}><EditIcon style={{ fill: variables.greyLight }} /></div>
      </div >;
    }
    return <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>N/A
      <div style={{ cursor: 'pointer', paddingLeft: '5px' }} onClick={() => setShowLocationModal(true)}><EditIcon style={{ color: variables.greyLight }} /></div>
    </div>;
  };

  const overrideElements = {
    tempReadOnly,
    readOnly,
    readOnlyCircuits,
    formatHtcAddress,
    booleanSelector,
    deviceLink,
    selector,
    googleLink,
    numericSelector,
    offlineSelector,
    editTemperatureValueSelector
  };
  const selectorOptions = {
    frogClients,
    commLoops,
    designedControlModes,
    designedRtdConfigOptions
  };

  const overrideElementLogic = (element) => {
    if (element.overrideElement === 'selector') {
      return selector(element.selector, selectorOptions[element.options]);
    }
    return overrideElements[element.overrideElement](element.selector);
  };

  return (
    <Paper>
      {isMobile ?
        <h3 className='expandable' onClick={() => setCollapsed(!collapsed)}>{deviceConfig?.title} &nbsp;<span className='expand-arrow'>{collapsed ? '▲' : '▼'}</span></h3>
        :
        <h3>{deviceConfig?.title}</h3>
      }
      {!collapsed ?
        <div id='device-properties' data-testid='device-properties'>
          <table className='device-info'>

            {Object.keys(deviceConfig?.properties)?.map((section, sectionKey) => {
              return (<Section key={sectionKey} section={section} sectionKey={{ sectionKey }}>
                {Object.keys(deviceConfig?.properties[section]).map((element, key) => {
                  return (
                    <tr key={`${sectionKey}-${key}`}>
                      <th>{is900Series && element === 'controllerTag' ? 'Comm Loop' : langLookUpText(element)}</th>
                      <td className="device-property-value" data-testid={langLookUpText(element).replace(/ /g, "-")}>
                        {deviceConfig?.properties[section][element].overrideElement ?
                          overrideElementLogic(deviceConfig?.properties[section][element])
                          :
                          <>
                            <EditableCell
                              id={element.replace(/ /g, "-")}
                              onChange={handleUpdateDeviceField(device?.get('id'), deviceConfig?.properties[section][element].selector.join('.'))}
                              value={device?.getIn(deviceConfig?.properties[section][element].selector, null)}
                              emptyCellLabel='N/A'
                              permission={permission}
                              loading={loadingModbusProperties.getIn([deviceConfig?.properties[section][element]?.selector.join('') ?? '', 'loading'], false)}
                            />
                          </>
                        }
                      </td>
                    </tr>
                  );
                })}
              </Section>);
            })}

          </table>
        </div>
        : null}
      {showLocationModal && <LocationModal deviceId={device.get('id')} latitude={device?.get('latitude')} longitude={device?.get('longitude')} onClose={() => setShowLocationModal(false)}/>}
    </Paper>
  );
}

DeviceProperties.propTypes = {
  permission: PropTypes.string,
  configFields: PropTypes.object,
  device: ImmutablePropTypes.map,
  temperatureUnit: PropTypes.string
};

Section.propTypes = {
  children: PropTypes.node,
  section: PropTypes.string.isRequired,
  sectionKey: PropTypes.any,
};

DeviceProperties.defaultProps = {
  permission: String,
  configFields: Map(),
  device: Map(),
  temperatureUnit: 'C'
};
