import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as facilityActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { IMPORT_DATA, IMPORT_DATA_SUCCESS, IMPORT_DATA_FAILED } from '../import/action';
import toast from 'src/utils/toast';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  integrityChecks: Map(),
  guide: Map(),
  reportSchedules: Map()

});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState
      .set('integrityChecks', Map({
        data: Map()
      }))
      .set('guide', Map())
      .set('reportSchedules', Map())
      .set('metrics', Map())
  );
}

export const facilityReducer = createTypedReducer(initialState, {

  [facilityActions.SEND_REPORT_SUCCESS] (state, action) {
    toast.success('EHT Report was sent successfully!');
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['guide'], Map())
    );
  },

  [facilityActions.SEND_REPORT_FAILED] (state) {
    toast.error('There was an error sending the report.');
    return state;
  },


  [facilityActions.FETCH_REPORT_SCHEDULES] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['reportSchedules', 'loading'], true)
    );
  },

  [facilityActions.FETCH_REPORT_SCHEDULES_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['reportSchedules'], Map({
        loading: false,
        data: action.reportSchedules
      }))
    );
  },

  [facilityActions.FETCH_REPORT_SCHEDULES_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['reportSchedules', 'loading'], false)
    );
  },


  [facilityActions.ADD_REPORT_SCHEDULE_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['reportSchedules', 'data'], nextState.getIn(['reportSchedules', 'data'], List()).push(action.reportSchedule))
    );
  },

  [facilityActions.UPDATE_REPORT_SCHEDULE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['reportSchedules', 'data'], List())
        .findIndex( (item) => item.get('id') === action.reportSchedule.get('id') );
      return (index !== -1) ? nextState.setIn(['reportSchedules', 'data', index], action.reportSchedule) : nextState;
    });
  },

  [facilityActions.DELETE_REPORT_SCHEDULE_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      const index = nextState.getIn(['reportSchedules', 'data'], List())
        .findIndex( (item) => item.get('id') === action.reportScheduleId );
      return (index !== -1) ? nextState.deleteIn(['reportSchedules', 'data', index]) : nextState;
    });
  },

  [IMPORT_DATA] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['guide', 'uploads', `${action.importFile.name}-${action.importFile.lastModified}`], {
        file: { name: action.importFile.name, lastModified: action.importFile.lastModified },
        fileObject: action.importFile,
        status: 'pending',
        importType: action.importType || 'otherData'
      })
    );
  },

  [IMPORT_DATA_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      return nextState
        .setIn(['guide', 'uploads', `${action.importFile.name}-${action.importFile.lastModified}`, 'status'], 'success')
        .setIn(['guide', 'uploads', `${action.importFile.name}-${action.importFile.lastModified}`, 'importType'], action.importType)
        .setIn(['guide', 'uploads', `${action.importFile.name}-${action.importFile.lastModified}`, 'subType'], action.subType);
    });
  },

  [IMPORT_DATA_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['guide', 'uploads', `${action.importFile.name}-${action.importFile.lastModified}`, 'status'], action.status)
    );
  },
  [facilityActions.FETCH_METRICS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['metrics', 'loading'], true)
    );
  },
  [facilityActions.FETCH_METRICS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['metrics', 'data'], action.metrics)
        .setIn(['metrics', 'loading'], false)
    );
  },
  [facilityActions.FETCH_METRICS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['metrics', 'loading'], false)
    );
  },

  [facilityActions.FETCH_INTEGRITY_CHECKS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['integrityChecks', 'loading'], true)
    );
  },
  [facilityActions.FETCH_INTEGRITY_CHECKS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['integrityChecks', 'data'], action.integrityChecks);
      nextState.setIn(['integrityChecks', 'loading'], false);
    });
  },
  [facilityActions.FETCH_INTEGRITY_CHECKS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['integrityChecks', 'loading'], false)
    );
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default facilityReducer;
