import { addSelectedFiltersToQuery } from 'src/utils/utils';
import { request } from '../api';
import { Map } from 'immutable';

export const getDevices = (action, deviceTag, circuitTag) => {
  const device = deviceTag ? encodeURIComponent(deviceTag) : '_';
  const circuit = circuitTag ? encodeURIComponent(circuitTag) : '';

  return request(action, {
    payload: {
      url: circuit !== 'undefined' ? `/devices/tag/${device}/${circuit}` : `/devices/tag/${device}`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const replaceCircuitCard = (action, deviceId, newAddress) => {

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/replace-card`,
      options: {
        method: 'PATCH',
        data: { newAddress }
      }
    }
  });
};

export const getDevicesAuditTrail = (action) => {
  const {
    filter,
    sort,
  } = action.pageInfo.toJS();
  const page = (isNaN(action.pageInfo.get('page'))) ? null : action.pageInfo.get('page');
  const pageSize = (isNaN(action.pageInfo.get('pageSize'))) ? null : action.pageInfo.get('pageSize');

  const query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }

  return request(action, {
    payload: {
      url: `/devices/${action.deviceId}/audit-trail`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const acknowledgeProgrammingDiscrepancy = (action) => {
  const {
    discrepancyId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/programming-audit/${discrepancyId}/acknowledge`,
      options: {
        method: 'PATCH',
      }
    }
  });
};

export const getCommLoops = (action) => {

  const {
    paginationDetails = { page: 0, pageSize: 10, filter: null, sort: null }
  } = action;


  const {
    filter,
    sort,
  } = paginationDetails;
  const page = (isNaN(action.paginationDetails?.page)) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails?.pageSize)) ? null : action.paginationDetails.pageSize;

  const query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }

  return request(action, {
    payload: {
      url: `/devices/comm-loops`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getCommLoopsDeviceBreakdown = (action) => {
  const {
    paginationDetails = { page: 0, pageSize: 10, filter: null, sort: null }
  } = action;


  const {
    filter,
    sort,
  } = paginationDetails;
  const page = (isNaN(action.paginationDetails?.page)) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails?.pageSize)) ? null : action.paginationDetails.pageSize;

  const query: any = {};
  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }
  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }

  return request(action, {
    payload: {
      url: `/devices/${action.commLoopId}/children-comm-stats`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDevicesSensorHistory = (action) => {
  const {
    timeFrame,
    rollUp
  } = action;
  const query = {
    timeFrame,
    rollUp
  };
  return request(action, {
    payload: {
      url: `/devices/${action.deviceId}/sensor-history`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDevicesWithGroupedAlarms = (action, deviceTag, childTag) => {
  const device = deviceTag ? encodeURIComponent(deviceTag) : '_';
  const child = childTag ? encodeURIComponent(childTag) : '';
  const query: any = { groupAlarms: true };

  return request(action, {
    payload: {
      url: `/devices/tag/${device}/${child}`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDeviceTags = (action) => {
  const {
    filter,
    deviceType,
    deviceId
  } = action;

  const query: any = {};

  if (filter) {
    query.filter = filter;
  }

  if (deviceType) {
    query.type = deviceType;
  }

  if (deviceId) {
    query.deviceId = deviceId;
  }

  return request(action, {
    payload: {
      url: `/devices/tags`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getSiblingsInfo = (action) => {
  const {
    deviceId
  } = action;

  const query: any = {};

  if (deviceId) {
    query.deviceId = deviceId;
  }

  return request(action, {
    payload: {
      url: `/devices/siblings-info`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getUnits = (action) => {
  return request(action, {
    payload: {
      url: `/devices/units`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getSystems = (action) => {
  return request(action, {
    payload: {
      url: `/devices/systems`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getCommodities = (action) => {
  return request(action, {
    payload: {
      url: `/devices/commodities`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getLocations = (action) => {
  return request(action, {
    payload: {
      url: `/devices/locations`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getPriorities = (action) => {
  return request(action, {
    payload: {
      url: `/devices/priorities`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDeviceConfigFields = (action, deviceId) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/config-fields`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDeviceConfigFieldsGeneric = (action, models) => {
  return request(action, {
    payload: {
      url: `/devices/config-fields/model/${models.join(',')}`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getStatistics = (action, deviceId) => {

  return request(action, {
    payload: {
      url: `/eht-circuits/${deviceId}/statistics`,
      options: {
        method: 'GET'
      }
    }
  });
};
export const getExport = (action, deviceId) => {

  return request(action, {
    payload: {
      url: `/eht-circuits/${deviceId}/export`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const addCommLoop = (action) => {
  const { commLoop } = action;

  return request(action, {
    payload: {
      url: `/devices/comm-loops`,
      options: {
        method: 'POST',
        data: commLoop
      },
    }
  });
};

export const getCommLoopTags = (action) => {

  return request(action, {
    payload: {
      url: `/devices/comm-loops/tags`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const uploadFile = (action, importFile, deviceId) => {
  try {
    const options: any = {
      method: 'POST'
    };

    const formData = new FormData();
    formData.append('file', importFile, importFile.name);
    action.file = {
      name: importFile.name,
      lastModified: importFile.lastModified
    };

    options.data = formData;
    options.headers = {
      'Content-Type': 'multipart/form-data'
    };

    return request(action, {
      payload: {
        url: `/devices/${deviceId}/file`,
        options
      }
    });
  } catch (error) {
    console.error(`Failed to upload file: ${error}`); // eslint-disable-line no-console
  }
};

export const deleteDevice = (action, deviceId) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}`,
      options: {
        method: 'DELETE',
      },
    }
  });
};

export const deleteFile = (action, deviceId) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/file/${action.fileId}`,
      options: {
        method: 'DELETE'
      }
    }
  });
};
export const getFiles = (action, deviceId) => {

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/files`,
      options: {
        method: 'GET',
      },
    }
  });
};
export const updateFileDescription = (action, deviceId) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/file/${action.fileId}`,
      options: {
        method: 'PATCH',
        data: { description: action.description }
      }
    }
  });
};
export const updateFileSubType = (action, deviceId) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/file/${action.fileId}`,
      options: {
        method: 'PATCH',
        data: { subType: action.subType }
      }
    }
  });
};

export const updateDeviceFileContents = (action, importFile, deviceId, uploadId, version) => {
  const data = new FormData();
  data.append('file', importFile);
  data.append('version', version);

  const options = {
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'PUT',
  };

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/file/${uploadId}`,
      options
    }
  });
};

export const getFile = (action, deviceId) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/file/${action.fileId}`,
      options: {
        method: 'GET',
        responseType: 'arraybuffer',
        dataType: 'blob'
      }
    }
  });
};

export const getIntegrityChecks = (action) => {
  const {
    selectedFilters,
  } = action;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);

  return request(action, {
    payload: {
      url: `/devices/integrity-checks`,
      query,
      options: {
        method: 'GET'
      },
    }
  });
};


export const getDevice = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDevicesProgrammingDiscrepancies = (action) => {
  const {
    selectedFilters,
    paginationDetails,
    selectedSetting
  } = action;

  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);

  const {
    filter,
    sort,
  } = paginationDetails;
  const page = (isNaN(action.paginationDetails.page)) ? null : action.paginationDetails.page;
  const pageSize = (isNaN(action.paginationDetails.pageSize)) ? null : action.paginationDetails.pageSize;

  if (page !== null && pageSize !== null) {
    query.page = page;
    query.pageSize = pageSize;
  }

  if (sort) {
    query.order = sort;
  }

  if (filter) {
    query.filter = filter;
  }

  if (selectedSetting) {
    query.setting = selectedSetting;
  }
  return request(action, {
    payload: {
      url: `/devices/programming-audit`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDeviceInfo = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/info`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const getDeviceTree = (action) => {
  const {
    selectedFilters,
  } = action;
  let query = {};
  query = addSelectedFiltersToQuery(Map(selectedFilters), query);

  return request(action, {
    payload: {
      url: `/eht-controllers/tree`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
export const getPanelByQrCode = (action) => {
  const {
    id,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/qr-panel/${id}`,
      options: {
        method: 'GET'
      }
    }
  });
};
export const getHiddenCircuits = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/hidden-circuits`,
      options: {
        method: 'GET'
      }
    }
  });
};
export const unhideCircuit = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/unhide-circuits`,
      options: {
        method: 'POST'
      }
    }
  });
};

export const fetchDeviceSettings = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/fetch-settings`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const fetchDeviceStatus = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/fetch-status`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const fetchDeviceAlarms = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/fetch-alarms`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const fetchEnumeratedDeviceSettings = (action) => {
  return request(action, {
    payload: {
      url: `/devices/enumerate-device-settings`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const snapshotDeviceSettings = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/snapshot-settings`,
      options: {
        method: 'POST',
        data: {
          useLastKnownSettings: !!action.useLastKnownSettings
        }
      }
    }
  });
};

export const upgradeDevice = (action) => {
  const {
    deviceId,
    upgradeDeviceFactory,
    commLoopId,
    address,
  } = action;

  return request(action, {
    payload: {
      url: `/upgrades/${deviceId}/upgrade`,
      options: {
        method: 'POST',
        data: {
          upgrade_device_factory: upgradeDeviceFactory,
          comm_loop_id: commLoopId,
          address: address,
        }
      }
    }
  });
};

export const fetchDeviceSettingsDiff = (action) => {
  const {
    deviceId,
  } = action;

  return request(action, {
    payload: {
      url: `/devices/${deviceId}/settings-diff`,
      options: {
        method: 'GET'
      }
    }
  });
};

export const updateDeviceDesignedSettings = (action) => {
  const { deviceId } = action;
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/designed-values`,
      options: {
        method: 'PATCH'
      },
    }
  });
};

export const updateDevice = (action, deviceId, config) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}`,
      options: {
        method: 'PATCH',
        data: config
      },
    }
  });
};

export const updateDeviceSettings = (action, deviceId, settings) => {
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/settings`,
      options: {
        method: 'PATCH',
        data: settings
      },
    }
  });
};

export const getDashboardStats = (action) => {
  const {
    selectedFilters
  } = action;
  let query: any = {};
  query = addSelectedFiltersToQuery(selectedFilters, query);

  return request(action, {
    payload: {
      url: `/devices/dashboard-stats`,
      query,
      options: {
        method: 'GET'
      },
    }
  });
};


export const getSensorHistory = (action) => {
  const {
    selectedFilters,
    timeFrame,
    rollUp
  } = action;

  let query: any = {};
  query = addSelectedFiltersToQuery(Map(selectedFilters), query);
  if (timeFrame) {
    query.timeFrame = timeFrame;
  }
  if (rollUp) {
    query.rollUp = rollUp;
  }
  return request(action, {
    payload: {
      url: `/devices/sensor-history`,
      query,
      options: {
        method: 'GET'
      },
    }
  });
};

export const dispatchDeviceAction = (action) => {
  const deviceId = action.deviceId;
  const deviceAction = action.deviceAction;
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/action/${deviceAction}`,
      options: {
        method: 'POST'
      }
    }
  });
};

export const fetchDeviceActions = (action) => {
  const deviceId = action.deviceId;
  return request(action, {
    payload: {
      url: `/devices/${deviceId}/action`,
      options: {
        method: 'GET'
      }
    }
  });
};


export const generateTestingReport = (action) => {
  const {
    selectedFilters,
    deviceId,
    reportType,
    startDate,
    endDate
  } = action;

  let query: any = {};
  if (startDate) {
    query.startDate = action.startDate;
  }
  if (endDate) {
    query.endDate = action.endDate;
  }
  query = addSelectedFiltersToQuery(selectedFilters, query);

  query.reportType = reportType;
  if (deviceId) {
    query.deviceId = deviceId;
  }
  return request(action, {
    payload: {
      url: `/devices/generate-testing-report`,
      query,
      options: {
        method: 'GET'
      }
    }
  });
};
export const sendModbusRequest = (action) => {
  const payload: any = {
    url: `/devices/${action.data.deviceId}/poll?fn=${action.data.functionCode}&addr=${action.data.address}&length=${action.data.length}`,
    options: {
      method: 'GET'
    }
  };

  return request(action, { payload });
};
export const createCsvReport = (action) => {
  const {
    selectedFilters,
    deviceId,
    reportType,
    startDate,
    endDate
  } = action;

  let query: any = {};
  if (startDate) {
    query.startDate = action.startDate;
  }
  if (endDate) {
    query.endDate = action.endDate;
  }
  query = addSelectedFiltersToQuery(selectedFilters, query);

  query.reportType = reportType;
  if (deviceId) {
    query.deviceId = deviceId;
  }

  return request(action, {
    payload: {
      url: `/check-sheets/pdf_report/${reportType}`,
      query,
      options: {
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf',
          'Content-Type': 'application/pdf'
        }
      }
    }
  });
};
