import 'src/screen/Protected/Wizards/Wizards.scss';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import CircleOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Button from 'src/component/UI/Button';
import { Modal } from '@mui/material';
import { fetchDeviceGroup } from 'src/module/groupDevices/action';
import AllRecipes from 'src/component/UI/Recipes/AllRecipes';
import { clearLoading, createBatchFromGroup } from 'src/module/batchSchedule/action';
import { getLoadingBatchSchedules } from 'src/module/batchSchedule/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { getSettingsModal } from 'src/module/device/selector';
import { setSettingsModalClose, setSettingsModalOpen } from 'src/module/device/action';

import GroupedDevicesScreen from '../DeviceScreen/GroupedDevicesScreen';
import { getIsAddGroupModalOpen } from 'src/module/recipe/selector';
import { closeAddGroupModal, openAddGroupModal } from 'src/module/recipe/action';
import DeviceGroupsList from '../DeviceGroupsScreen/DeviceGroupsList';

export default function UpgradeDeviceScreen () {
  const dispatch = useDispatch();
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const isAddGroupModalOpen = useSelector(getIsAddGroupModalOpen);

  const loadingBatch: any = useSelector(getLoadingBatchSchedules);
  const settingsModal = useSelector(getSettingsModal(null));


  useEffect(() => {
    dispatch(closeAddGroupModal());
    dispatch(clearLoading());
  }, []);

  const [sectionCollapsed, setSectionCollapsed] = useState(Map({
    step1: false,
    step2: true,
    step3: true
  }));

  const [completedSteps, setCompletedSteps] = useState(Map({
    step1: false,
    step2: false,
    step3: false
  }));

  useEffect(() => {
    dispatch(setSettingsModalClose(null));
  }, []);

  useEffect(() => {
    if (selectedDeviceGroup) {
      dispatch(fetchDeviceGroup(selectedDeviceGroup));
    }
  }, [selectedDeviceGroup]);

  const onSelectedGroup = (groupId) => {
    if (groupId && groupId !== 'null') {
      setSelectedDeviceGroup(groupId);
      setStepCompleted('step1');
    }
    else {
      setSelectedDeviceGroup('');
      setStepIncomplete('step1');
    }
  };

  const onSelectedRecipe = (event) => {
    const recipeId = event?.target?.value;
    if (recipeId && recipeId !== 'null') {
      setSelectedRecipe(recipeId);
      setStepCompleted('step2');
    }
    else {
      setStepIncomplete('step2');
    }
  };

  const makeToggleSection = (section) => {
    return () => {
      toggleSectionCollapsed(section);
    };
  };


  const toggleSectionCollapsed = (section: string) => {
    setSectionCollapsed(sectionCollapsed.set(section, !sectionCollapsed.get(section)));
  };

  const setStepCompleted = (step: string) => {
    setCompletedSteps(completedSteps.set(step, true));
  };

  const setStepIncomplete = (step: string) => {
    setCompletedSteps(completedSteps.set(step, false));
  };

  const handleStep1Submit = () => {
    setStepCompleted('step1');
    setSectionCollapsed(Map({ step1: true, step2: false, step3: true }));
  };

  const handleStep2Submit = () => {
    setStepCompleted('step2');
    setSectionCollapsed(Map({ step1: true, step2: true, step3: false }));
  };

  const handleStep3Submit = () => {
    setStepCompleted('step3');
    dispatch(createBatchFromGroup(selectedDeviceGroup, selectedRecipe));
  };

  const stepCompletedStatusIcon = (completed) => {
    return completed ? (<CheckCircleOutlinedIcon className='complete' />) : (<CircleOutlinedIcon />);
  };

  const toggleCreateRecipeModal = () => {
    if (!settingsModal?.get('isOpen')) {
      dispatch(setSettingsModalOpen(null, SettingsModalTypes.ADD_RECIPE));
    }
    else {
      dispatch(setSettingsModalClose(null));
    }
  };

  return (
    <div id='mass-program-wizard' className='guide-wizard page'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('step1') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('step1')}>
              <div >
                {stepCompletedStatusIcon(completedSteps.get('step1'))}
                <span>Step 1 - Select Device Group</span>
              </div>
              <span className='expand-arrow'>{sectionCollapsed.get('step1') ? '▲' : '▼'}</span>
            </h2>
            {!sectionCollapsed.get('step1') ? <div className='inner-section'>
              <div className='select-device-bar'>
              </div>
              <DeviceGroupsList forMassProgram={true} onSelect={(id) => {onSelectedGroup(id);} } selectedGroup={selectedDeviceGroup}/>
              <div className='button-bar'>
                <Button onClick={() => dispatch(openAddGroupModal())} >Create a Group</Button>

                <Button disabled={!selectedDeviceGroup} onClick={handleStep1Submit} cta>Next</Button>
              </div>
            </div> : null}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('step2') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('step2')}>
              <div>
                { stepCompletedStatusIcon(completedSteps.get('step2')) }
                <span>Step 2 - Apply Settings Template</span>
              </div>
              <span className='expand-arrow'>{sectionCollapsed.get('step2') ? '▲' : '▼'}</span>
            </h2>
            {!sectionCollapsed.get('step2') ? <div className='inner-section'>
              <AllRecipes radio onRadioChanged={onSelectedRecipe} selectedRecipe={selectedRecipe} expandLast={false} allowAdd={false} allowEditName={false} />
              <div className='button-bar'>
                <Button onClick={toggleCreateRecipeModal} icon={<AddIcon style={{ fontSize: 14 }} />}>Add Template</Button>
                <Button onClick={handleStep2Submit} disabled={!completedSteps.get('step2')} cta>Next</Button>
              </div>
            </div> : null}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <h2 className={classNames('expandable', sectionCollapsed.get('step3') ? 'collapsed' : 'expanded')} onClick={makeToggleSection('step3')}>
              <div>
                {stepCompletedStatusIcon(completedSteps.get('step3'))}
                <span>Step 3 - Review</span>
              </div>
              <span className='expand-arrow'>{sectionCollapsed.get('step3') ? '▲' : '▼'}</span>
            </h2>
            {!sectionCollapsed.get('step3') ? <div className='inner-section'>
              <h3 style={{ color: 'black' }}>You will now be asked to review your choices before finalizing the Mass Program</h3>
              <div className='button-bar'>
                <Button loading={loadingBatch} onClick={handleStep3Submit} disabled={!(completedSteps.get('step1') && completedSteps.get('step2'))} cta>Review</Button>
              </div>
            </div> : null}
          </Paper>
        </Grid>
      </Grid>

      <Modal
        data-testid='add-device-group-modal'
        open={isAddGroupModalOpen}
        onClose={() => dispatch(closeAddGroupModal())}
        aria-labelledby='add-device-group-modal-title'
      >
        <div className='modal add-device-group-modal' >
          <h2 style={{ textAlign: 'center' }}>Create a Device Group</h2>
          <GroupedDevicesScreen />
          <div className='button-bar'>
            <Button onClick={() => dispatch(closeAddGroupModal())} >Close</Button>
          </div>
        </div>

      </Modal>
    </div>
  );
}
