import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { getTabId } from 'src/utils/utils';

// export const getFreezeLogState = (state) =>
//   state.freezeLog;
export const getFreezeLogState = (state) => ({
  state: state.alarm,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.freezeLog) {
      return state.freezeLog.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});

export const getFreezeLogs = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    const allData = freezeLog?.getIn(['freezeLogs', 'data'], Map());
    const pageIds = freezeLog?.getIn(['freezeLogs', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);

export const getLoadingFreezeLogs = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    return freezeLog.getIn(['freezeLogs', 'loading'], false);
  }
);

export const getNumFreezeLogsPages = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    return freezeLog.getIn(['freezeLogs', 'totalPages'], 0);
  }
);

export const getTotalFreezeLogs = createSelector(
  [getFreezeLogState],
  (freezeLog) => {
    return freezeLog.getIn(['freezeLogs', 'total'], 0);
  }
);
