import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getWeatherForecastState = (state) =>
  state.weather;

export const selectCurrentClientId = (state) => state.authentication.getIn(['tabs', getTabId(), 'clientId']);

export const getWeatherForecast = createSelector(
  [getWeatherForecastState, selectCurrentClientId],
  (weather, clientId) => {
    return weather.getIn(['weatherForecasts', clientId, 'data'], Map()) || Map();
  }
);
