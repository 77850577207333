import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getRecipeState = (state) => ({
  state: state.recipe,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.recipe) {
      return state.recipe.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});
export const getRecipe = (id) => createSelector(
  [getRecipeState],
  (recipe) => {
    return recipe?.getIn(['recipes', 'data', id, 'record'], null);
  }
);

export const getRecipes = createSelector(
  [getRecipeState],
  (recipe) => {
    const allData = recipe?.getIn(['recipes', 'data'], List());
    const pageIds = recipe?.getIn(['recipes', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return List(pageData);
  }
);
export const getDeviceWithSetting = (setting) => createSelector(
  [getRecipeState],
  (recipe) => {
    if (setting.includes('.')) {
      const [temp, num, property] = setting.split('.');
      return recipe.getIn(['recipes', 'settingsMap', temp, num, property], List());
    }
    return recipe.getIn(['recipes', 'settingsMap', setting], List());
  }
);

export const getLoadingRecipes = createSelector(
  [getRecipeState],
  (recipe) => {
    return recipe.getIn(['recipes', 'loading']) || false;
  }
);
export const getIsAddGroupModalOpen = createSelector(
  [getRecipeState],
  (recipe) => {
    return recipe.getIn(['modal', 'addGroup']) || false;
  }
);
