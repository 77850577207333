import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getRootState = (state) =>
  state;
export const selectCurrentClientId = (state) => state.authentication.getIn(['tabs', getTabId(), 'clientId']);


export const getCircuitState = (state) => ({
  state: state.circuit,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.circuit) {
      return state.circuit.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});


export const getCircuits = createSelector(
  [getRootState, selectCurrentClientId],
  (root, clientId) => {
    let list = List();
    root.circuit.getIn(['circuits', clientId, 'data'], List()).map( id => {
      const deviceInfo = root.device.getIn(['devices', clientId, 'data', id, 'record', 'info'], null);
      if (deviceInfo) {
        list = list.push(deviceInfo);
      }
    });
    return list;
  }
);

export const getLoadingCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['circuits',  'loading'], false);
  }
);


export const getNumCircuitPages = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['circuits',  'totalPages'], 0);
  }
);

export const getTotalCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['circuits',  'total'], 0);
  }
);


export const getForcedOnCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits',  'data'], List());
  }
);

export const getLoadingForcedOnCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits',  'loading'], false);
  }
);

export const getNumForcedOnCircuitPages = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits',  'totalPages'], 0);
  }
);

export const getTotalForcedOnCircuits = createSelector(
  [getCircuitState],
  (circuit) => {
    return circuit.getIn(['forcedOnCircuits',  'total'], 0);
  }
);
