import './RecipeScreen.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Tooltip, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import RecipeCondition from './RecipeCondition';
import { List, Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AddIcon from '@mui/icons-material/Add';
import Button from 'src/component/UI/Button';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ConfirmModal from 'src/component/UI/ConfirmModal';
import { addRecipeCondition, deleteRecipe, updateRecipe } from 'src/module/recipe/action';
import { recipeConditionOptions } from 'src/utils/utils';
import PermissionFence from 'src/component/PermissionFence';
import EditIcon from '@mui/icons-material/Edit';
import SettingsModal from 'src/component/UI/SettingsModal/SettingsModal';
import { fromJS } from 'immutable';
import { getSettingsModal } from 'src/module/device/selector';
import { SettingsModalTypes } from 'src/component/UI/SettingsModal/SettingsModalTypes';
import { setSettingsModalClose, setSettingsModalOpen } from 'src/module/device/action';
import PropTypes from 'prop-types';
import variables from 'src/style/variable/variables.module.scss';

export default function Recipe (props) {
  const {
    recipe,
    isLast,
    radio,
    radioSelected,
    onRadioChanged,
    allowEditName
  } = props;
  const dispatch = useDispatch();
  const settingsModal = useSelector(getSettingsModal(null));
  const [conditions, setConditions] = useState(List([]));
  const [labelOptions, setLabelOptions] = useState(List([]));
  const [deleteRecipeModalOpen, setDeleteRecipeModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const accordionRef = useRef(null);
  const recipesTemp = [];

  const loadConditions = () => {
    const recipeSettingsValues = Object.values(recipe.getIn(['settings'], Map()).toJS());
    recipeSettingsValues.map((val: any) => {
      if (typeof val === 'object') {         // for temperature sensors label format
        Object.entries(val).forEach(([key, value]) => {
          Object.entries(value).forEach(([k, v]) => {
            recipesTemp.push({
              value: v,
              label: `temperatureSensors.${key}.${k}`
            });
          });
        });
      }
      else {
        recipesTemp.push(val);
      }
    });
    setConditions(List(recipeSettingsValues.sort((a: any, b: any) => {
      return a.order - b.order;
    })));
  };

  useEffect(() => {
    loadConditions();
    const labels = conditions.map(x => x.label);
    const filteredOptions = recipeConditionOptions.map((x: any) => {
      x.disabled = labels?.includes(x.value);
      return x;
    });
    setLabelOptions(List(filteredOptions));
  }, [recipe]);

  const addCondition = () => {
    const options = recipeConditionOptions;
    const aConditionNotUsedYet = options.find(conOpt => !conditions.some(con => con.label === conOpt.value));
    if (aConditionNotUsedYet) {
      const addRecipe = {
        property: aConditionNotUsedYet.value,
        value: ''
      };
      dispatch(addRecipeCondition(recipe.get('id'), Map(addRecipe)));

    }

    return;
  };

  const onRadioChecked = (e) => {
    e.stopPropagation();
    onRadioChanged(e);
  };

  const buildTitleBarActions = (isRadio) => {
    if (isRadio) {
      return (<>
        <Radio
          checked={radioSelected}
          onChange={onRadioChecked}
          value={recipe.get('id', '')}
          onClick={(e) => e.stopPropagation()}
          name="radio-buttons"
        />
      </>);
    }

    return (
      <>
        <Tooltip title="Schedule Mass Program" placement="top">
          <div>
            <NavLink to={`/devices/list/program/${recipe.get('id', '')}`}>
              <Button icon={<SettingsSuggestIcon color="primary" style={{ fontSize: 24 }} />} />
            </NavLink>
          </div>
        </Tooltip>
        <Tooltip title="Delete Template" placement="top">
          <div>
            <Button onClick={(e) => handleDeleteRecipe(e)} icon={<DeleteIcon className='deleteButton' style={{ color: variables.gaugeDanger, zIndex: 5 }} />} />
          </div>
        </Tooltip>
      </>);
  };

  const buildTitleBar = (showButtons: boolean) => {
    return (
      <div className='recipeTitle' data-testid={`recipe`}>
        <div data-testid={`recipe-${recipe?.get('id')}`}>
          {recipe?.get('name')}
          {allowEditName ? <Button onClick={(e) => handleEditRecipeTitle(e)} icon={<EditIcon className='deleteButton' style={{ zIndex: 5 }} />} /> : null}
        </div>
        {showButtons ?
          <div style={{ display: 'flex', gap: '10px' }}>
            {
              buildTitleBarActions(radio)
            }
          </div>
          : null
        }
      </div>
    );
  };

  const handleDeleteRecipe = (event) => {
    event.stopPropagation();
    toggleDeleteRecipeModal();
  };
  const handleEditRecipeTitle = (event) => {
    event.stopPropagation();
    toggleCreateRecipeModal();
  };
  const deleteMessage = `You want to delete recipe "${recipe.get('name')}" ?`;
  const toggleDeleteRecipeModal = () => {
    setDeleteRecipeModalOpen(!deleteRecipeModalOpen);
  };
  const deleteRecipeModalSubmit = () => {
    dispatch(deleteRecipe(recipe?.get('id')));
    toggleDeleteRecipeModal();
  };
  const toggleCreateRecipeModal = () => {
    if (!settingsModal?.get('isOpen')) {
      const id = SettingsModalTypes.EDIT_RECIPE_TITLE + recipe.get('id');
      dispatch(setSettingsModalOpen(null, id));
    }
    else {
      dispatch(setSettingsModalClose(null));
    }
  };

  const handleEditTitle = (rec: any) => {
    const updated = recipe.set('name', rec.get('name'));
    dispatch(updateRecipe(recipe.get('id'), updated));
    dispatch(setSettingsModalClose(null));
  };
  const editRecipeModal = () => {
    const id = SettingsModalTypes.EDIT_RECIPE_TITLE + recipe.get('id');

    return (
      <SettingsModal
        open={settingsModal?.get('isOpen') && settingsModal?.get('id') === id}
        handleClose={() => toggleCreateRecipeModal()}
        handleSubmit={handleEditTitle}
        title='Edit Template Name'
        loading={settingsModal?.get('loading')}
        settings={
          fromJS({
            name: recipe?.get('name')
          })
        }
        fields={
          fromJS({
            name: {
              type: 'text',
              label: 'Template Name',
              errorText: 'Required',
            }
          })
        }
      />
    );
  };

  const expandRequested = (e) => {
    if (radio) {
      const rect = accordionRef.current.getBoundingClientRect(); // Get bounding rect;
      const delta = e.clientX - rect.left;
      // only expand accordion if clicked on left hand side
      if (delta < 150) {
        setIsExpanded(!isExpanded);
      }
      else {
        onRadioChanged({ target: { value: recipe?.get('id') } });
      }
      return;
    }
    setIsExpanded(!isExpanded);
  };

  return (<>
    <ConfirmModal
      title='Are you sure?'
      message={deleteMessage}
      open={deleteRecipeModalOpen}
      handleClose={() => toggleDeleteRecipeModal()}
      handleReject={() => toggleDeleteRecipeModal()}
      handleConfirm={deleteRecipeModalSubmit}
    />
    {editRecipeModal()}
    <PermissionFence can={['edit-recipe']} noPermissionComponent={(
      <Paper>
        {buildTitleBar(false)}
      </Paper>
    )}>
      <Accordion expanded={isExpanded} defaultExpanded={isLast}>
        <AccordionSummary
          ref={accordionRef}
          onClick={expandRequested}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ flexDirection: "row-reverse" }}
        >
          {buildTitleBar(true)}
        </AccordionSummary>

        <AccordionDetails>
          <div className='recipeDetails'>
            <div className='inner'>
              <p className='title1'>Configure template conditions</p>
              {conditions.map((condition, index) => (
                <RecipeCondition key={index} condition={condition} recipeId={recipe.get('id')} labelOptions={labelOptions} />
              ))}

              <div className='addConditionButton'>
                <Button onClick={() => addCondition()} cta color="primary" variant="contained" icon={<AddIcon style={{ color: 'white', fontSize: 24, marginRight: '25px' }} />} >Add Condition</Button>
              </div>

            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </PermissionFence>
  </>);
}

Recipe.propTypes = {
  recipe: ImmutablePropTypes.map,
  isLast: PropTypes.bool,
  radio: PropTypes.bool,
  radioSelected: PropTypes.bool,
  allowEditName: PropTypes.bool,
  onRadioChanged: PropTypes.func
};

Recipe.defaultProps = {
  radio: false,
  allowEditName: true
};
