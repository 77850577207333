import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getDeviceGroupState = (state) => ({
  state: state.groups,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.groups) {
      return state.groups.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});

export const getDeviceGroups = createSelector(
  [getDeviceGroupState],
  (deviceGroup) => {
    const allData = deviceGroup?.getIn(['groups', 'data'], Map());
    const pageIds = deviceGroup?.getIn(['groups', 'records'], List());
    let pageData = List();
    pageIds.map((id) => {
      pageData = pageData.push(allData.getIn([id, 'record']));
    });
    return pageData;
  }
);

export const getLoadingDeviceGroups = createSelector(
  [getDeviceGroupState],
  (deviceGroup) => {
    return deviceGroup.getIn(['groups', 'loading'], false);
  }
);

export const getNumDeviceGroupsPages = createSelector(
  [getDeviceGroupState],
  (deviceGroup) => {
    return deviceGroup.getIn(['groups', 'totalPages'], 0);
  }
);

export const getTotalDeviceGroups = createSelector(
  [getDeviceGroupState],
  (deviceGroup) => {
    return deviceGroup.getIn(['groups', 'total'], 0);
  }
);

export const getDeviceGroupsLabels = createSelector(
  [getDeviceGroupState],
  (deviceGroup) => {
    const data = deviceGroup.getIn(['groups', 'data'], List([]));
    let result = List();
    data.forEach((value) => {
      const id = value?.record.get('id');
      const name = value?.record.get('name');
      result = result.push(Map({ id, name }));
    });
    return result;
  }
);

export const getDeviceGroup = (id) =>  createSelector(
  [getDeviceGroupState],
  (deviceGroup) => {
    return deviceGroup.getIn(['groups', 'data', id, 'record'], Map());
  }
);
