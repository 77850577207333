import { Map, List, fromJS } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as authenticationActions from './action';
import * as userActions from 'src/module/user/action';
import toast from '../../utils/toast';
import { getTabId } from 'src/utils/utils';

export const initialState = Map({
  user: Map(),
  errors: List(),
  messages: List(),
  isFailed: false,
  isAuthenticating: false,
  isAuthenticated: false,
  passwordResetToken: '',
  isValidatingJWT: false,
  jwtUser: null,
  initialized: false,
  notificationPreferences: Map({ notifications: {} }),
  hsIdentificationAccessToken: ''
});

function setAuthenticatingState (state) {
  return state.withMutations((nextState) =>
    nextState.set('isAuthenticated', false)
      .set('isFailed', false)
      .set('errors', List())
      .set('isAuthenticating', true)
  );
}

export const authenticationReducer = createTypedReducer(initialState, {
  [authenticationActions.REHYDRATE_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('isAuthenticated', true)
        .set('user', action.user)
        .set('clients', action.clients)
        .set('abilities', action.abilities)
        .set('features', action.features)
        .set('hsIdentificationAccessToken', action.hsIdentificationAccessToken)
    );
  },

  [authenticationActions.REFRESH_AUTH_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('isAuthenticated', true)
        .set('clients', action.clients)
        .set('abilities', action.abilities)
        .set('features', action.features)
        .set('hsIdentificationAccessToken', action.hsIdentificationAccessToken)
    );
  },

  [authenticationActions.CHANGE_PASSWORD_SUCCESS] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['user', 'userMustResetPassword'], false);
    }
    );
  },

  [authenticationActions.REFRESH_AUTH_TOKEN_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('isAuthenticated', true)
        .set('clients', action.clients)
        .set('abilities', action.abilities)
        .set('features', action.features)
    );
  },

  [authenticationActions.CLEAR] () {
    return initialState;
  },

  [authenticationActions.INITIALIZE] (state) {
    return state.withMutations((nextState) =>
      nextState.set('initialized', true)
    );
  },

  [authenticationActions.AUTHENTICATE] (state) {
    return setAuthenticatingState(state);
  },

  [authenticationActions.AUTHENTICATE_WITH_TOKEN] (state) {
    return setAuthenticatingState(state);
  },

  [authenticationActions.AUTHENTICATE_FAILED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('isAuthenticated', false)
        .set('isFailed', true)
        .set('isAuthenticating', false)
        .set('errors', List(action.errors))
    );
  },
  [authenticationActions.AUTHENTICATE_MFA_REQUIRED] (state, action) {
    return state.withMutations((nextState) => {
      if (action.options) {
        nextState = nextState.set('MFAOptions', action.options);
      }
      nextState.set('isAuthenticated', false)
        .set('isFailed', false)
        .set('isAuthenticating', false)
        .set('isMFARequired', true)
        .set('OTP_URL', action.OTP_URL);

    });
  },

  [authenticationActions.AUTHENTICATED] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('isAuthenticated', true)
        .set('user', action.user)
        .set('clients', action.clients)
        .set('abilities', action.abilities)
        .set('features', action.features)
        .set('isAuthenticating', false)
        .set('isMFARequired', false)
        .set('hsIdentificationAccessToken', action.hsIdentificationAccessToken)
    );
  },

  [authenticationActions.REQUEST_PASSWORD_RESET] (state) {
    return state.withMutations((nextState) =>
      nextState.set('passwordResetToken', '')
    );
  },

  [authenticationActions.VALIDATE_PASSWORD_JWT] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('passwordResetToken', action.jwt)
        .set('isValidatingJWT', true)
    );
  },

  [authenticationActions.VALIDATE_PASSWORD_JWT_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.set('passwordResetToken', false)
        .set('isValidatingJWT', false)
    );
  },

  [authenticationActions.VALIDATE_PASSWORD_JWT_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('passwordResetToken', action.jwt)
        .set('jwtUser', action.user)
        .set('isValidatingJWT', false)
    );
  },

  [authenticationActions.VALIDATE_INVITE_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.set('jwtUser', action.user)
    );
  },

  [authenticationActions.RESET_PASSWORD_SUCCESS] (state) {
    return state.withMutations((nextState) =>
      nextState.set('passwordResetToken', 'SUCCESS')
        .set('messages', List([Map({ message: 'Your password was successfully set!' })]))
    );
  },

  [authenticationActions.RECEIVE_EXTERNAL_ERROR] (state, action) {
    let message;
    switch (action.errorCode) {
      case 'AUTHENTICATE_USER_ERROR':
        message = `Sorry, we couldn't find an account associated with your credentials.`;
        break;
      case 'MISCONFIGURED_INTEGRATION':
        message = `It looks like the SSO integration is not configured properly. Contact customer support for assistance.`;
        break;
      default:
        message = `An unknown error occurred. Please contact support and try again later.`;
    }

    return state.withMutations((nextState) =>
      nextState.set('errors', List([Map({ message })]))
    );
  },

  [authenticationActions.FETCH_CLIENT_SUCCESS] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) =>
      nextState.setIn(['client', tabId], action.client)
        .setIn(['tabs', tabId], { clientId: action.client.get('id') })
    );
  },

  [authenticationActions.UPDATE_CLIENT_SUCCESS] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) =>
      nextState.setIn(['client', tabId], action.client)
        .setIn(['tabs', tabId], { clientId: action.client?.get('id') })
    );
  },

  [authenticationActions.UPDATE_CLIENT_FAILED] (state) {
    toast.error('Error saving the facility details');
    return state;
  },

  [authenticationActions.CHANGE_CLIENT_SUCCESS] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) =>
      nextState.setIn(['client', tabId], action.client)
        .setIn(['tabs', tabId], { clientId: action.client.get('id') })
    );
  },

  [userActions.UPDATE_USER_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      if (action.user.get('id') === nextState.getIn(['user', 'id'])) {
        return nextState.set('user', action.user);
      }

      return nextState;
    });
  },

  [authenticationActions.FETCH_USER_NOTIFICATION_PREFS] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['notificationPreferences', 'loading'], true);
    });
  },

  [authenticationActions.FETCH_USER_NOTIFICATION_PREFS_SUCCESS] (state, action) {
    const tabId = getTabId();

    return state.withMutations((nextState) => {
      const clientId = nextState.getIn(['client', tabId, 'id']);
      nextState.setIn(['notificationPreferences', 'disabledNotifications', clientId], action.notificationPrefs.get('data').map(x => x.get('notification_key')))
        .setIn(['notificationPreferences',  'loading'], false);
    });
  },

  [authenticationActions.FETCH_USER_NOTIFICATION_PREFS_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['notificationPreferences', 'loading'], false);
    });
  },

  [authenticationActions.UPDATE_USER_NOTIFICATION_PREFS_SUCCESS] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) => {
      const clientId = nextState.getIn(['client', tabId, 'id']);
      const notificationPref = action.notificationPref;
      const currentDisabledNotification = nextState.getIn(['notificationPreferences', 'disabledNotifications', clientId], []);
      nextState = nextState.setIn(['notificationPreferences', 'disabledNotifications', clientId], currentDisabledNotification.push(notificationPref.get('notification_key')));
      return nextState.setIn(['notificationPreferences', 'loading'], false);
    });
  },

  [authenticationActions.DELETE_USER_NOTIFICATION_PREFS_SUCCESS] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) => {
      const clientId = nextState.getIn(['client', tabId, 'id']);
      const current = nextState.getIn(['notificationPreferences', 'disabledNotifications', clientId], [] );
      return nextState.setIn(['notificationPreferences', 'disabledNotifications', clientId], current.filter((notification) => notification !== action.notificationKey));
    }
    );
  },

  [authenticationActions.FETCH_USER_NOTIFICATIONS] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['notificationPreferences', 'loading'], true)
    );
  },

  [authenticationActions.FETCH_USER_NOTIFICATIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn(['notificationPreferences', 'notifications'], action.notifications.get('data'))
        .setIn(['notificationPreferences', 'loading'], false)
    );
  },

  [authenticationActions.FETCH_USER_NOTIFICATIONS_FAILED] (state) {
    return state.withMutations((nextState) =>
      nextState.setIn(['notificationPreferences', 'loading'], false)
    );
  },
  [authenticationActions.UPDATE_TAB] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) =>
      nextState.setIn(['tabs', tabId], { clientId: action.clientId })
    );
  },
  [authenticationActions.OAUTH_STAGE_2] (state, action) {
    const tabId = getTabId();
    return state.withMutations((nextState) =>
      nextState.setIn(['client', tabId], fromJS({ id: action.clientId }))
        .set('isAuthenticated', true)
        .set('user', Map())
    );
  },


});



export default authenticationReducer;
