import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getRuleState = (state) => ({
  state: state.rule,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.rule) {
      return state.rule.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});
export const getRules = createSelector(
  [getRuleState],
  (rule) => {
    return rule.getIn(['rules', 'data']) || List();
  }
);

export const getLoadingRules = createSelector(
  [getRuleState],
  (rule) => {
    return rule.getIn(['rules', 'loading']) || false;
  }
);
