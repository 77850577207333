import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getFacilityState = (state) => ({
  state: state.facility,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.facility) {
      return state.facility.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  },
  get: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    return state.facility.getIn([keyPath, clientId], defaultValue);
  },
});

export const getIntegrityChecks = createSelector(
  [getFacilityState],
  (facility) => {
    return facility.getIn(['integrityChecks', 'data'], Map());
  }
);

export const getGuide = createSelector(
  [getFacilityState],
  (facility) => {
    return facility.get('guide', Map());
  }
);

export const getMetrics = createSelector(
  [getFacilityState],
  (alarm) => {
    return alarm.getIn(['metrics', 'data']);
  }
);

export const getReportSchedules = createSelector(
  [getFacilityState],
  (rule) => {
    return rule.getIn(['reportSchedules', 'data']) || List();
  }
);

export const getLoadingReportSchedules = createSelector(
  [getFacilityState],
  (rule) => {
    return rule.getIn(['reportSchedules', 'loading']) || false;
  }
);
