import './RecipeScreen.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, Input, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from 'src/component/UI/Button';
import PropTypes from 'prop-types';
import { updateRecipeCondition } from 'src/module/recipe/action';
import { Map } from 'immutable';
import { celsiusToFahrenheitConvertor, fahrenheitToCelsiusConvertor, recipeConditionOptions } from 'src/utils/utils';
import { isNumberString } from 'class-validator';
import InfoIcon from '@mui/icons-material/Info';
import { getDeviceWithSetting } from 'src/module/recipe/selector';
import { langLookUpText } from "src/utils/langLookUp";
import ComparisonSelector from 'src/component/UI/ComparisonSelector';
import { getTemperatureUnit } from 'src/module/authentication/selector';

export default function RecipeCondition (props) {
  const {
    condition,
    recipeId,
    labelOptions

  } = props;

  const dispatch = useDispatch();
  const temperatureUnit = useSelector(getTemperatureUnit);
  const [label, setLabel] = React.useState(condition.label);
  const [value, setValue] = React.useState(condition.value);
  const [compare, setCompare] = React.useState(condition.updateType ?? '=');
  const [selectOption, setSelectOption] = React.useState(recipeConditionOptions.find(opt => opt.value === label));
  const devices = useSelector(getDeviceWithSetting(condition.label));

  useEffect(() => {
    setLabel(condition.label);
    setValue(condition.value);
    setSelectOption(recipeConditionOptions.find(opt => opt.value === condition.label));
  }, [condition]);

  const updateLabel = (val) => {
    const selectOption = recipeConditionOptions.find(opt => opt.value === val.target.value);
    setLabel(val.target.value);
    setSelectOption(selectOption);
    setValue(null);

    let compareUpdate = compare;
    if (!selectOption.modificationOption.includes(compare)) {
      setCompare('=');
      compareUpdate = '=';
    }
    const updatedRecipe = {
      property: val.target.value,
      value: '',
      order: condition.order,
      updateType: compareUpdate,
    };
    dispatch(updateRecipeCondition(recipeId, Map(updatedRecipe)));
  };


  const updateValue = (val) => {
    if (val !== condition.value) {
      if (isNumberString(val.trim())) {
        setValue(parseFloat(val));
        const updatedRecipe = {
          property: label,
          value: parseFloat(val),
          order: condition.order,
          updateType: compare,
        };
        dispatch(updateRecipeCondition(recipeId, Map(updatedRecipe)));
      }
    }
  };

  const onDelete = () => {
    const updatedRecipe = {
      property: label,
      value: null
    };
    dispatch(updateRecipeCondition(recipeId, Map(updatedRecipe)));
  };

  const toolTipText = () => {
    let text = 'Devices that use this setting: \n';
    devices.forEach(element => {
      text = text + langLookUpText(element) + '\n';
    });
    return text;
  };

  const updateCompare = (val) => {
    setCompare(val);
    const updatedRecipe: any = {
      property: label,
      order: condition.order,
      updateType: val,
      value: value
    };
    dispatch(updateRecipeCondition(recipeId, Map(updatedRecipe)));
  };
  const valueUpdatedByUser = (val) => {
    if (temperatureUnit=== 'F' && selectOption.isTemp) {
      setValue(fahrenheitToCelsiusConvertor(val).toString());
    } else {

      setValue(val);
    }
  };
  return (
    <>
      <div className='recipeCondition'>
        <div className='select-recipe-setting'>
          <Select
            value={label}
            variant="standard"
            onChange={updateLabel}
          >
            {labelOptions.map(opt => (
              <MenuItem disabled={opt.disabled} key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>

          {
            devices.size ?
              <Tooltip
                data-testid='recipe-condition-tool-tip'
                title={<div style={{ whiteSpace: 'pre-line' }} >{toolTipText()}</div>}
                enterTouchDelay={0}>
                <InfoIcon style={{ fontSize: 20, cursor: 'pointer', color: '#fcd54c' }} />
              </Tooltip> : null
          }
        </div>
        <div className='comparison'>
          <ComparisonSelector
            orNull={condition.or_null}
            comparison={compare ?? '='}
            handleChange={updateCompare}
            options={selectOption.modificationOption}
            showOrNullOption={false}
          />
        </div>
        {selectOption.type === 'input' ?
          <Input
            id='input'
            type='text'
            onChange={(v) => valueUpdatedByUser(v.target.value)}
            onBlur={() => updateValue(value)}
            value={temperatureUnit === 'F' && selectOption.isTemp && value !== '' ? celsiusToFahrenheitConvertor(value)?.toString() : value}
            fullWidth
            size="small"
            margin="none"
            className='input'
          />
          : null}
        {selectOption.type === 'select' ?
          <Select
            value={value}
            variant="standard"
            onChange={(v) => updateValue(v.target.value)}
            className='select'
          >
            {selectOption.options.map(opt => (
              <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
            ))}
          </Select>
          : null}
        <div className='buttonRow'>
          <Button onClick={onDelete} icon={<DeleteIcon className='deleteIcon' />} />
        </div>
      </div>
      {label === 'forcedOn' ? (
        <div data-testid='recipe-condition-tool-tip' style={{ marginTop: '-15px', marginLeft: '30px', fontSize: '14px' }}>
          <span role="img" aria-label="warning" style={{ fontSize: '18px', marginRight: '8px' }}>
            ⚠️
          </span>
          <i>(Devices without forced-on capability will have their maintain temp set to 300)</i>
        </div>
      ) : null}
    </>
  );
}

RecipeCondition.propTypes = {
  condition: PropTypes.any,
  recipeId: PropTypes.string,
  labelOptions: PropTypes.any
};

RecipeCondition.defaultProps = {
};
