import { Map, List } from 'immutable';
import createTypedReducer from '../createTypedReducer';
import * as userActions from './action';
import * as authenticationActions from '../authentication/action';
import toast from 'src/utils/toast';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  users: List(),
  isInactive: List()
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState.setIn(['users'], Map())
  );
}

export const userReducer = createTypedReducer(initialState, {

  [userActions.FETCH_USERS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['users'], action.users)
    );
  },

  [userActions.INVITE_USER_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      return nextState.setIn(['users'], nextState.get('users').push(action.user));
    });
  },

  [userActions.REMOVE_USER_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {

      const index = nextState.getIn(['users']).state.findIndex((user) => user.get('id') === action.userId);

      if (index !== -1) {
        nextState = nextState.deleteIn(['users', index]);
      }

      return nextState;
    });
  },

  [userActions.REMOVE_USER_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const name = nextState.getIn(['users', action.userId, 'first_name'], nextState.getIn(['users', action.userId, 'first_name'], 'user'));
      toast.error(`Error removing ${name} from the facility`);
      return nextState;
    });
  },

  [userActions.UPDATE_USER_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const index = nextState.getIn(['users'], List()).findIndex( (user) => user.get('id') === action.user.get('id') );

      if (index !== -1) {
        return nextState.setIn(['users', index], action.user);
      } else {
        const users = nextState.getIn(['users'], List([])).push(action.user);
        return nextState.setIn(['users'], users);
      }
    });
  },
  [userActions.UPDATE_USER_NAV_BAR_PREF] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['users'], action.users)
    );
  },

  [userActions.UPDATE_USER_ROLE_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const index = nextState.getIn(['users'], List()).findIndex( (user) => user.get('id') === action.userId);

      if (index !== -1) {
        return nextState.setIn(['users', index, 'roles'], List([action.role]));
      }
    });
  },
  [userActions.TRIGGER_ACTIVITY] (state, action) {
    let routeList = state.getIn(['isInactive'], []);
    if (action.isInactive) {
      // add too list
      if (routeList?.findIndex(x => x===action.route) === -1) {
        routeList = routeList.push(action.route);
      }
    } else {
      // remove from list
      const index = routeList?.findIndex(x => x===action.route);
      if (index > -1) {
        routeList = routeList.filter(x => x !== action.route);
      }
    }
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['isInactive'], List(routeList))
    );
  },
  [authenticationActions.FETCH_USER_BY_EMAIL_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      return nextState.setIn(['users'], action.user);
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }

});

export default userReducer;
