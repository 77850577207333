import { fromJS, List, Map } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as workTicketActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  workTickets: Map({
    data: Map()
  }),
  page: Map({
    records: List(),
  }),
  newWorkTickets: Map({
    records: List(),
  })
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState
      .set('workTickets', Map())
      .set('page', Map())
      .set('newWorkTickets', Map())
  );
}

export const workTicketReducer = createTypedReducer(initialState, {
  [workTicketActions.FETCH_WORK_TICKETS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['page', 'loading'], true)
    );
  },
  [workTicketActions.FETCH_WORK_TICKETS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      const newDataIds = [];
      action.workTickets.map((ticket) => {
        newDataIds.push(ticket.get('id'));
        nextState.setIn(['workTickets', 'data', ticket.get('id')], {
          record: ticket,
          lastFetch: fetchDate,
        });
      });
      nextState.setIn(['page', 'records'], fromJS(newDataIds));
      nextState.setIn(['page', 'total'], action.total);
      nextState.setIn(['page', 'totalPages'], action.totalPages);
      nextState.setIn(['page', 'loading'], false);
    }
    );
  },

  [workTicketActions.FETCH_WORK_TICKETS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['page', 'loading'], false)
    );
  },

  [workTicketActions.FETCH_NEW_WORK_TICKETS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['newWorkTickets', 'loading'], true)
    );
  },

  [workTicketActions.FETCH_NEW_WORK_TICKETS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      const newDataIds = [];
      action.newWorkTickets.map((ticket) => {
        newDataIds.push(ticket.get('id'));
        nextState.setIn(['workTickets', 'data', ticket.get('id')], {
          record: ticket,
          lastFetch: fetchDate,
        });
      });
      nextState.setIn(['newWorkTickets', 'records'], fromJS(newDataIds));
      nextState.setIn(['newWorkTickets', 'loading'], false);
    });
  },

  [workTicketActions.FETCH_NEW_WORK_TICKETS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['newWorkTickets', 'loading'], false)
    );
  },

  [workTicketActions.UPDATE_WORK_TICKET_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState = nextState.setIn(['workTickets', 'data', action.workTicket.get('id'), 'record', 'note'], action.workTicket.get('note'))
        .setIn(['workTickets', 'data', action.workTicket.get('id'), 'record', 'revision'], action.workTicket.get('revision'))
        .setIn(['workTickets', 'data', action.workTicket.get('id'), 'record', 'description'], action.workTicket.get('description'))
        .setIn(['workTickets', 'data', action.workTicket.get('id'), 'record', 'sap_notification_id'], action.workTicket.get('sap_notification_id'));

      return nextState;
    });
  },
  [workTicketActions.LOAD_WORK_TICKETS_FROM_DEVICE] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      action.workTickets.map((ticket) => {
        nextState.setIn(['workTickets', 'data', ticket.get('id')], {
          record: ticket,
          lastFetch: fetchDate,
        });
      });
      return nextState;
    });
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }

});

export default workTicketReducer;
