import { List, Map, fromJS } from 'immutable';
import { take, takeEvery, takeLatest, all, put, call, fork, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import * as authApi from 'src/service/api/authentication';
import * as clientApi from 'src/service/api';
import * as authenticationActions from 'src/module/authentication/action';
import * as navigationActions from 'src/module/navigation/action';
import * as authenticationSelector from 'src/module/authentication/selector';
import * as authService from 'src/service/auth';
import { USER_STATUS } from 'src/utils/constants';
import toast from 'src/utils/toast';
import { createUserFriendlyErrorMessage, getTabId } from 'src/utils/utils';

export function* watchGetClients () {
  while (true) {
    const user = yield select(authenticationSelector.getAuthenticatedUser);

    if (!user || user.isEmpty()) {
      yield put(authenticationActions.authenticateFailed(
        null,
        List([
          Map({
            type: 'validation',
            message: 'No authenticate user',
            isRetriable: false
          })
        ])
      ));

      break;
    }
  }
}

function* requestPasswordReset () {
  while (true) {
    const action = yield take(authenticationActions.REQUEST_PASSWORD_RESET);
    yield call(authApi.sendPasswordResetEmail, action, action.email);
  }
}

function* validateJwt () {
  while (true) {
    const action = yield take(authenticationActions.VALIDATE_PASSWORD_JWT);
    try {
      const user = yield call(authApi.validatePasswordResetJWT, action, action.jwt);
      yield put(authenticationActions.validatePasswordJwtSuccess(action.jwt, user));
    } catch (err: any) {
      let errorMessage = 'Invalid password reset link';
      switch (err.response.data.errorCode) {
        case 'INVALID_TOKEN':
          errorMessage = 'The password reset link has expired, please try again.';
          break;
      }

      toast.warn(errorMessage);
      yield put(authenticationActions.validatePasswordJwtFailed(Map(err)));
    }
  }
}

function* validateInviteAccept () {
  while (true) {
    const action = yield take(authenticationActions.VALIDATE_INVITE);
    try {
      const jwtUser = yield call(authApi.validatePasswordResetJWT, action, action.jwt);
      const authUser = yield select(authenticationSelector.getAuthenticatedUser);
      if (authUser && (authUser.get('id') === jwtUser.get('id'))) {
        yield put(navigationActions.pushHistory('/'));
      } else if (jwtUser && jwtUser.get('status') === USER_STATUS.ACTIVE) {
        yield put(navigationActions.pushHistory('/login'));
      } else {
        yield put(authenticationActions.validateInviteSuccess(jwtUser));
      }
    } catch (err) {
      let errorMessage = 'We are having trouble validating your invitation, please double check the link that was emailed to you and try again.';
      if (err['response'] && err['response'].data && err['response'].data.type === 'ValidationError') {
        errorMessage = 'We are having trouble validating your invitation. You may have already accepted it. Please try to login or request a new invite if the invitation link is not working.';
      }
      yield put(authenticationActions.validateInviteFailed(Map({
        message: errorMessage
      })));
    }
  }
}

function* resetPassword () {
  while (true) {
    const action = yield take(authenticationActions.RESET_PASSWORD);
    const { jwt, password } = action;

    try {
      const user = yield call(authApi.resetPassword, action, jwt, password);
      if (user) {
        yield put(authenticationActions.authenticate('basic', { email: user.get('email'), password }));
        yield put(authenticationActions.resetPasswordSuccess());
        yield put(navigationActions.pushHistory('/profile'));
      } else {
        yield put(authenticationActions.validatePasswordJwtFailed());
      }
    } catch (err) {
      yield handleChangePasswordError(err, authenticationActions.resetPasswordFailed);
    }
  }
}

function* rehydrate (action) {
  try {
    const me = yield call(authApi.me, action);
    const user = me.get('user', Map());
    const client = me.get('client', Map());
    if (client) {
      yield put(authenticationActions.updateClientSuccess(client));
    }
    const clients = me.get('clients', Map());
    const abilities = me.get('abilities', List());
    const features = me.get('features', List());
    const hsIdentificationAccessToken = me.get('hsIdentificationAccessToken', null);
    const refreshToken = me.get('refreshToken');
    const sessionId = me.get('sessionId');
    const expiry = me.get('refreshTokenExpiry', '');
    if (refreshToken) {
      yield call(authService.setSessionId, sessionId);
      yield call(authService.setRefreshToken, refreshToken);
      yield call(authService.setRefreshTokenExpiry, expiry);
    }

    yield put(authenticationActions.rehydrateSuccess(user, client, clients, abilities, features, hsIdentificationAccessToken));
  } catch (err) {
    yield put(authenticationActions.rehydrateFailure(err));
  }
}

function* refreshAuthToken (action) {
  try {
    const me = yield call(authApi.refreshAuthToken, action);
    yield call(authService.setAccessToken, me.get('accessToken'));
    yield call(authService.setRefreshTokenExpiry, me.get('refreshTokenExpiry', ''));

    const clients = me.get('clients', Map());
    const abilities = me.get('abilities', List());
    const features = me.get('features', List());
    yield put(authenticationActions.refreshAuthTokenSuccess(clients, abilities, features));
  } catch (err) {
    yield put(authenticationActions.refreshAuthTokenFailure(err));
  }
}

function* oauthStage2 (action) {
  try {
    yield call(authService.setAccessToken, action.jwt);
    yield put(authenticationActions.oauthStage2Success(action.clientId));
  } catch (err) {
    yield put(authenticationActions.oauthStage2Failure(err));
  }
}

function* oauthStage2Success (action) {
  try {
    const key = uuid();
    yield put(authenticationActions.fetchClientSuccess(fromJS({ id: action.clientId })));
    yield put(authenticationActions.rehydrate());
    yield put(navigationActions.pushHistory({ pathname: `/`, key, state: { key } }));
  } catch (err) {
    yield put(authenticationActions.oauthStage2Failure(err));
  }
}

function* authenticateWithToken (action) {
  const { service, serviceParams } = action;

  let result;
  try {
    result = yield call(authApi.authenticateWithToken, action, service, serviceParams);
  } catch (err) {
    if (err['response']) {
      switch (err['response'].status) {
        case 401:
          yield put(authenticationActions.authenticateFailed(
            service,
            List([
              Map({
                type: 'validation',
                message: `Sorry, we couldn't automatically log you in. Please try again with your username and password.`,
                detailed: err,
                isRetriable: false
              })
            ])
          ));

          return;
      }
    }

    yield put(authenticationActions.authenticateFailed(
      service,
      List([
        Map({
          type: 'api',
          message: `We're having issues connecting, please try again.`,
          detailed: err,
          isRetriable: false
        })
      ])
    ));

    return;
  }

  if (!result.has('accessToken')) {
    yield put(authenticationActions.authenticateFailed(
      service,
      List([
        Map({
          type: 'api',
          message: `Sorry, we couldn't automatically log you in. Please try again with your username and password.`,
          isRetriable: false
        })
      ])
    ));

    return;
  }

  yield call(authService.setAccessToken, result.get('accessToken'));
  yield call(authService.setSessionId, result.get('sessionId'));
  yield call(authService.setRefreshToken, result.get('refreshToken'));
  yield call(authService.setRefreshTokenExpiry, result.get('refreshTokenExpiry'));

  yield put(authenticationActions.authenticated(
    result.get('user'),
    result.get('client'),
    result.get('clients'),
    result.get('abilities'),
    result.get('features')
  ));
}

export const authenticationRoot = function* () {
  yield takeEvery(authenticationActions.CLEAR, function* () {
    yield new Promise((resolve) => {
      authService.clear().then(resolve);
    });
  });

  yield takeLatest(authenticationActions.FETCH_CLIENT, fetchClient);
  yield takeLatest(authenticationActions.UPDATE_CLIENT, updateClient);
  yield takeLatest(authenticationActions.CHANGE_CLIENT, changeClient);
  yield takeEvery(authenticationActions.REHYDRATE, rehydrate);
  yield takeEvery(authenticationActions.OAUTH_STAGE_2, oauthStage2);
  yield takeEvery(authenticationActions.OAUTH_STAGE_2_SUCCESS, oauthStage2Success);
  yield takeEvery(authenticationActions.AUTHENTICATE_WITH_TOKEN, authenticateWithToken);
  yield takeEvery(authenticationActions.REFRESH_AUTH_TOKEN, refreshAuthToken);

  yield takeLatest(authenticationActions.FETCH_USER_NOTIFICATION_PREFS, fetchUserNotificationPrefs);
  yield takeLatest(authenticationActions.UPDATE_USER_NOTIFICATION_PREFS, updateUserNotificationPrefs);
  yield takeLatest(authenticationActions.FETCH_USER_NOTIFICATIONS, fetchUserNotifications);
  yield takeLatest(authenticationActions.DELETE_USER_NOTIFICATION_PREFS, deleteUserNotificationPrefs);
  yield takeLatest(authenticationActions.MULTI_FACTOR_AUTHENTICATE, multiFactorAuthenticate);
  yield takeLatest(authenticationActions.RESEND_MULTI_FACTOR_AUTHENTICATE, resendMultiFactorAuthenticate);
  yield takeLatest(authenticationActions.FETCH_USER_BY_EMAIL, fetchUserByEmail);
  yield takeLatest(authenticationActions.SET_UNVERIFIED_CONTACT_NUMBER, setUnverifiedContactNumber);
  yield takeLatest(authenticationActions.LOGOUT, logout);


  yield fork(function* () {
    while (true) {
      const action = yield take(authenticationActions.CHANGE_PASSWORD);

      try {
        yield call(authApi.changePassword, action, action.oldPassword, action.newPassword);

        yield put(authenticationActions.changePasswordSuccess());

        toast.success(`Your password has been updated`);
      } catch (err) {
        yield handleChangePasswordError(err, authenticationActions.changePasswordFailed);
      }
    }
  });

  yield all([
    fork(requestPasswordReset),
    fork(validateJwt),
    fork(validateInviteAccept),
    fork(resetPassword)
  ]);

  while (true) {
    let result;
    const action = yield take(authenticationActions.AUTHENTICATE);
    const { service, serviceParams } = action;

    try {
      result = yield call(authApi.authenticate, action, service, serviceParams);
      const tabId = getTabId();
      yield put(authenticationActions.updateTab(tabId, result.getIn(['client', 'id'])));
      yield put(authenticationActions.updateClientSuccess(result.getIn(['client'])));

    } catch (err) {
      if (err['response']) {
        switch (err['response'].status) {
          case 401:
            yield put(authenticationActions.authenticateFailed(
              service,
              List([
                Map({
                  type: 'validation',
                  message: `We can't find an account with that email and password combination.`,
                  detailed: err,
                  isRetriable: false
                })
              ])
            ));

            continue;
        }
      }

      yield put(authenticationActions.authenticateFailed(
        service,
        List([
          Map({
            type: 'api',
            message: `We're having issues connecting to EHT Group, please try again.`,
            detailed: err,
            isRetriable: false
          })
        ])
      ));

      continue;
    }
    if (result.get('authStatus', '') === 'MFA_required') {
      yield call(authService.setTempToken, result.get('temporaryToken'));
      yield put(authenticationActions.authenticateMfaRequired(result.get('MFAOptions', []), result.get('uri')));
      yield put(navigationActions.pushHistory("/mfa"));
      continue;
    }
    if (!result.has('accessToken')) {
      yield put(authenticationActions.authenticateFailed(
        service,
        List([
          Map({
            type: 'api',
            message: `Unable to find an account with that email and password combination.`,
            isRetriable: false
          })
        ])
      ));

      continue;
    }
    yield call(authService.setAccessToken, result.get('accessToken'));
    yield call(authService.setSessionId, result.get('sessionId'));
    yield call(authService.setRefreshToken, result.get('refreshToken'));
    yield call(authService.setRefreshTokenExpiry, result.get('refreshTokenExpiry'));
    yield put(authenticationActions.authenticated(
      result.get('user'),
      result.get('client'),
      result.get('clients'),
      result.get('abilities'),
      result.get('features'),
      result.get('hsIdentificationAccessToken')
    ));
  }

  function* multiFactorAuthenticate (action) {
    try {
      // clear an error messages
      yield put(authenticationActions.authenticateFailed(
        null,
        List([])
      ));
      const { code } = action;
      const result = yield call(authApi.authenticateMFA, action, code);
      yield put(authenticationActions.fetchClientSuccess(result.get('client')));
      yield call(authService.setAccessToken, result.get('accessToken'));
      yield call(authService.setSessionId, result.get('sessionId'));
      yield call(authService.setRefreshToken, result.get('refreshToken'));
      yield call(authService.setRefreshTokenExpiry, result.get('refreshTokenExpiry'));
      yield put(authenticationActions.authenticated(
        result.get('user'),
        result.get('client'),
        result.get('clients'),
        result.get('abilities'),
        result.get('features'),
        result.get('hsIdentificationAccessToken')
      ));
      yield call(authService.removeTempToken);

    } catch (err) {
      let message = 'An error occurred. Please try again.';
      if (_.get(err, 'response.status') === 401) {
        message = 'Code is invalid.';
      } else if (_.get(err, 'response.status') === 500) {
        message = 'Code is expired.';
      }
      yield put(authenticationActions.authenticateFailed(
        null,
        List([
          Map({
            type: 'api',
            message: message,
            isRetriable: false
          })
        ])
      ));

    }
  }
  function* resendMultiFactorAuthenticate (action) {
    try {
      const result = yield call(authApi.resendMFA, action);

      yield call(authService.setTempToken, result.get('temporaryToken'));
      yield put(authenticationActions.authenticateMfaRequired());
      yield put(navigationActions.pushHistory("/mfa"));
      toast.success('Successfully sent authentication code!');
      // clear an error messages
      yield put(authenticationActions.authenticateFailed(
        null,
        List([])
      ));
    } catch (e) {
      yield put(authenticationActions.authenticateFailed(
        null,
        List([
          Map({
            type: 'api',
            message: createUserFriendlyErrorMessage(e, 'Error sending code'),
            isRetriable: false
          })
        ])
      ));
    }

  }
};

function* handleChangePasswordError (err, failedAction) {
  if (err.response && err.response.data && err.response.data.type === 'ValidationError') {
    let errorMessage = 'Invalid password, please try again';
    switch (err.response.data.errorCode) {
      case 'REPEATED_PASSWORD':
        errorMessage = 'You may not re-use your previous password.';
        break;
      case 'PASSWORD_NOT_ALLOWED':
        errorMessage = 'Please use the SSO method for your facility.';
        break;
      case 'REPEATED_LAST_3_PASSWORDS':
        errorMessage = 'You may not re-use any of your 3 previous passwords.';
        break;
      case 'INVALID_PASSWORD_STRENGTH':
        errorMessage = 'Must be at least 8 characters, including a number, uppercase letter, lowercase letter, and a symbol';
        break;
    }

    toast.warn(errorMessage);

    yield put(failedAction(
      Map({
        type: 'validation',
        message: errorMessage,
        detailed: err,
        isRetriable: false
      })
    ));
  } else {
    yield put(failedAction(null));
    toast.error('Your password failed to reset. Please try again, refresh your page, or check your network connection.', err.response.status);
  }
}

function* fetchClient (action) {
  try {
    const client = yield call(clientApi.getClient, action);
    yield put(authenticationActions.fetchClientSuccess(client));
  } catch (err) {
    yield put(authenticationActions.fetchClientFailed(Map(err)));
  }
}

function* updateClient (action) {
  try {
    const client = yield call(clientApi.updateClient, action, action.name, action.preferences);
    yield put(authenticationActions.updateClientSuccess(client));
  } catch (err) {
    yield put(authenticationActions.updateClientFailed(Map(err)));
  }
}


function* changeClient (action) {
  try {
    const client = yield call(clientApi.getClient, action, action.clientId);
    const key = uuid();
    yield put(authenticationActions.fetchClientSuccess(client));
    yield put(authenticationActions.rehydrate());
    yield put(navigationActions.pushHistory({ pathname: `/`, key, state: { key } }));
  } catch (err) {
    yield put(authenticationActions.changeClientFailed(Map(err)));
  }
}

function* fetchUserNotificationPrefs (action) {
  try {
    const notificationPrefs = yield call(authApi.fetchUserNotificationPrefs, action);
    yield put(authenticationActions.fetchUserNotificationPrefsSuccess(notificationPrefs));
  } catch (err: any) {
    toast.error('Failed to fetch notification settings', err.response.status);
    yield put(authenticationActions.fetchUserNotificationPrefsFailed(Map(err)));
  }
}

function* updateUserNotificationPrefs (action) {
  try {
    const notification = yield call(authApi.updateUserNotificationPrefs, action);
    yield put(authenticationActions.updateUserNotificationPrefsSuccess(notification));
  } catch (err: any) {
    toast.error('Failed to update notification setting', err.response.status);
    yield put(authenticationActions.updateUserNotificationPrefsFailed(Map(err)));
  }
}
function* deleteUserNotificationPrefs (action) {
  try {
    yield call(authApi.deleteUserNotificationPrefs, action);
    yield put(authenticationActions.deleteUserNotificationPrefsSuccess(action.notificationKey));
  } catch (err: any) {
    toast.error('Failed to update notification setting', err.response.status);
    yield put(authenticationActions.deleteUserNotificationPrefsFailed(Map(err)));
  }
}

function* fetchUserNotifications (action) {
  try {
    const notifications = yield call(authApi.fetchUserNotifications, action);
    yield put(authenticationActions.fetchUserNotificationsSuccess(notifications));
  } catch (err: any) {
    toast.error('Failed to update fetch notifications', err.response.status);
    yield put(authenticationActions.fetchUserNotificationsFailed(Map(err)));
  }
}

function* fetchUserByEmail (action) {
  try {
    const user = yield call(authApi.getUserByEmail, action);
    yield put(authenticationActions.fetchUserByEmailSuccess(user));
  } catch (err: any) {
    toast.error(`Error fetching user`, err.response.status);
    yield put(authenticationActions.fetchUserByEmailFailed(Map(err)));
  }
}


function* setUnverifiedContactNumber (action) {
  try {
    yield call(authApi.setUnverifiedContactNumber, action);
  } catch (err: any) {
    toast.error(`Error setting the contact number`, err.response.status);
  }
}

function* logout () {
  yield new Promise((resolve) => {
    authService.clear().then(resolve);
  });
  yield put(navigationActions.pushHistory('/login'));
}
