import './SettingsMapPane.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Radio, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Assignment from './Assignment';
import Button from 'src/component/UI/Button';
import { getLabelForDeviceModel, isLangATemperature, isLangBand, langLookUpText } from 'src/utils/langLookUp';
import { getEnumeratedDeviceSettingsByType } from 'src/module/device/selector';
import { addDeviceUpgradeConditionBranch, deleteDeviceUpgrade, updateDeviceUpgrade } from 'src/module/upgrade/action';
import ConditionBranch from './ConditionBranch';
import { celsiusToFahrenheitConvertor, convertBand } from 'src/utils/utils';
import { getTemperatureUnit } from 'src/module/authentication/selector';


export default function SettingUpgrade (props) {
  const {
    settings,
    index,
    baseDevice,
    upgradeDevice,
  } = props;
  const deviceSettings = useSelector(getEnumeratedDeviceSettingsByType);
  const [defaultAssignmentType, setDefaultAssignmentType] = useState(settings?.get('default_value_type', 'select'));
  const dispatch: any = useDispatch();
  const temperatureUnit = useSelector(getTemperatureUnit);
  function buildSettings (deviceSettings) {
    return deviceSettings.get(baseDevice, List()).map((setting) => {
      return { deviceModel: baseDevice, setting };
    }).concat(buildAdditionalSettings(baseDevice));

  }

  const buildAdditionalSettings = (device) => {
    // get child settings
    if (device?.includes('Controller')) {
      const childDeviceModel = device?.replace('Controller', '');
      return deviceSettings.get(childDeviceModel, List()).map((setting) => {
        return { deviceModel: childDeviceModel, setting };
      });

    }
    // get parent settings
    else {
      const parentDeviceModel = device ? device + 'Controller' : '';
      return deviceSettings.get(parentDeviceModel, List()).map((setting) => {
        return { deviceModel: parentDeviceModel, setting };
      });
    }
  };

  const [baseSettings, setBaseSettings] = useState(buildSettings(deviceSettings));
  useEffect(() => {
    setBaseSettings(buildSettings(deviceSettings));
  }, [baseDevice, deviceSettings, upgradeDevice]);

  const handleDelete = () => {
    dispatch(deleteDeviceUpgrade(settings.get('id')));
  };

  const getCompletedStateIcon = () => {
    const conditionalVal = settings.get('conditional_value');
    const defaultVal = settings.get('default_value');
    if (conditionalVal || defaultVal) {
      return <CheckCircleIcon className='check-circle-completed' />;
    }
    else {
      return null;
    }
  };

  const addConditionBranch = () => {
    dispatch(addDeviceUpgradeConditionBranch(settings.get('id'), {}));
    return;
  };

  const buildSummary = (showButtons: boolean) => {

    const header = langLookUpText(settings.get('setting_path') ?? '');
    let value = null;
    if (settings?.get('default_value')) {
      let valConversion = settings.get('default_value');
      if (isLangATemperature(settings.get('setting_path')) && temperatureUnit === 'F') {
        valConversion = celsiusToFahrenheitConvertor(valConversion);
      }
      if (isLangBand(settings.get('setting_path')) && temperatureUnit === 'F') {
        valConversion = convertBand(valConversion, 'F');
      }
      value = `(${valConversion})`;
    }


    return (
      <table className='upgrade-title'>
        <tbody>
          <tr>
            <td className='upgrade-number'>{`${index + 1}`}&nbsp;&nbsp;{getCompletedStateIcon()}</td>
            <td className='setting-title'>
              <div className="container">
                <div className="settings-change-2">
                  <div>
                    <span>{header} {value}</span>
                    <div className="subtext">{getLabelForDeviceModel(settings.get('upgrade_device_factory'))}</div>
                  </div>
                </div>
              </div>
            </td>
            <td style={{ width: '80px' }}>
              <div className='upgrade-titleButtons'>{showButtons ? (<>
                <Button onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }} icon={<DeleteIcon className='deleteButton' />} />
              </>) : null}
              </div>
            </td>

          </tr>
        </tbody>
      </table>
    );
  };

  const handleDefaultValueUpdated = (value) => {
    dispatch(updateDeviceUpgrade(settings?.get('id'), { default_value: value }));
  };

  const handleDefaultValueTypeUpdated = (type) => {
    setDefaultAssignmentType(type);
    dispatch(updateDeviceUpgrade(settings?.get('id'), { default_value_type: type }));
  };

  return (<>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ flexDirection: "row-reverse" }}
      >
        {buildSummary(true)}
      </AccordionSummary>
      <AccordionDetails className='details-content'>
        <div style={{ marginBottom: '25px' }}>
          {/*  new */}
          <p className='title1'>Set {`${getLabelForDeviceModel(settings?.get('upgrade_device_factory')) || 'Device'} ${langLookUpText(settings?.get('setting_path') ?? 'Setting')}`} to</p>
          <FormControlLabel
            value="select"
            control={<Radio />}
            label={<Assignment inputType='select' initialValue={settings?.get('default_value_type') === 'select' ? settings?.get('default_value') : null} onValueUpdated={handleDefaultValueUpdated} disabled={defaultAssignmentType !== 'select'} baseDeviceModel={settings?.get('base_device_factory')} baseSettings={baseSettings} upgradeDeviceModel={settings?.get('upgrade_device_factory')} />}
            onChange={() => { handleDefaultValueTypeUpdated('select'); }}
            name={'defaultValue'}
            checked={defaultAssignmentType === 'select'}
          />
          <FormControlLabel
            value="input"
            control={<Radio />}
            label={<Assignment inputType='input' setting={settings?.get('setting_path')} initialValue={settings?.get('default_value_type') === 'input' ? settings?.get('default_value') : null} onValueUpdated={handleDefaultValueUpdated} disabled={defaultAssignmentType !== 'input'} baseDeviceModel={settings?.get('base_device_factory')} baseSettings={baseSettings} upgradeDeviceModel={settings?.get('upgrade_device_factory')} />}
            onChange={() => { handleDefaultValueTypeUpdated('input'); }}
            name={'defaultValue'}
            checked={defaultAssignmentType === 'input'}
          />
          {settings?.get('upgradeConditions')?.map((condition, index) => {
            return (
              <div key={`container-${condition?.get('id')}-${index}`}  className='condition-branch-container'>
                <ConditionBranch key={`${condition?.get('id')}-${index}`} setting={settings?.get('setting_path')}  index={index} conditionBranch={condition} baseDevice={settings?.get('base_device_factory')} upgradeDevice={settings?.get('upgrade_device_factory')} />
              </div>
            );
          })}
          <Button onClick={addConditionBranch} color="primary" variant="contained" fullWidth type="submit" cta>Add Condition</Button>
        </div>
      </AccordionDetails>
    </Accordion>
  </>);
}

SettingUpgrade.propTypes = {
  orderNumber: PropTypes.number,
  index: PropTypes.number,
  settings: ImmutablePropTypes.map,
  baseDevice: PropTypes.string,
  upgradeDevice: PropTypes.string,
};
