import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const getUserState = (state) => ({
  state: state.user,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.user) {
      return state.user.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});
export const getUsers = createSelector(
  [getUserState],
  (user) => {
    return user.getIn(['users'], List([]));
  }
);

export const getIsInactive = route => createSelector(
  [getUserState],
  (user) => {
    return user.getIn(['isInactive'], []).includes(route);
  }
);

export const getUserByEmail = (email) => createSelector(
  [getUserState],
  (user) => {
    const users = user.getIn(['users']);
    let userFound = Map();
    users?.map(rec => {
      if (rec?.get('email') === email) {
        userFound = rec;
      }
    });
    return userFound;
  }
);
