import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as alarmActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';
import moment from 'moment';

import { withScopedMutations } from 'src/utils/utils';

export const initialState = Map({
  alarmStats: Map(),
  devicesInAlarm: Map(),
});

function resetState (state) {
  return state.withMutations((nextState) =>
    nextState
      .set('devicesInAlarm', Map())
      .set('alarmStats', Map())
      .set('alarmHistoricalSummary', Map())
      .set('alarms', Map({
        data: Map()
      }))
      .set('alarmHistoryPage', Map())
      .set('alarmActivePage', Map())
  );
}

export const alarmReducer = createTypedReducer(initialState, {
  [alarmActions.FETCH_ALARM_STATS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmStats'], action.alarmStats)
        .setIn(['alarmStatsLoading'], false);
    });
  },
  [alarmActions.FETCH_ALARM_STATS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['alarmStats'], Map({}))
        .setIn(['alarmStatsLoading'], false)
    );
  },
  [alarmActions.CLEAR_ALARM_STATS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['alarmStatsLoading'], true)
    );
  },
  [alarmActions.FETCH_ALARMS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['alarmActivePage', 'loading'], true)
    );
  },
  [alarmActions.FETCH_ALARMS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      const newDataIds = [];
      action.alarms.map((alarm) => {
        newDataIds.push(alarm.get('id'));
        nextState.setIn(['alarms', 'data', alarm.get('id')], {
          record: alarm,
          lastFetch: fetchDate,
        });
      });

    });
  },
  [alarmActions.FETCH_ACTIVE_ALARMS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmActivePage', 'loading'], true);
    });
  },
  [alarmActions.FETCH_ACTIVE_ALARMS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmActivePage', 'records'], action.alarms.map((alarm) => alarm.get('id')));
      nextState.setIn(['alarmActivePage', 'total'], action.total);
      nextState.setIn(['alarmActivePage', 'totalPages'], action.numPages);
      nextState.setIn(['alarmActivePage', 'loading'], false);
    });
  },
  [alarmActions.FETCH_ARCHIVED_ALARMS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmHistoryPage', 'loading'], true);
    });
  },
  [alarmActions.FETCH_ARCHIVED_ALARMS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmHistoryPage', 'records'], action.alarms.map((alarm) => alarm.get('id')));
      nextState.setIn(['alarmHistoryPage', 'total'], action.total);
      nextState.setIn(['alarmHistoryPage', 'totalPages'], action.numPages);
      nextState.setIn(['alarmHistoryPage', 'loading'], false);
    });
  },
  [alarmActions.FETCH_ALARMS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['alarmActivePage', 'loading'], false)
    );
  },

  [alarmActions.FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['devicesInAlarm', 'loading'], true)
    );
  },
  [alarmActions.FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['devicesInAlarm', 'withoutNotification'], action.devicesInAlarmWithoutNotification); // to-do list device idds
      nextState.setIn(['alarmStats', 'criticalAlarmsWithoutNotification'], action.criticalAlarmsWithoutNotification); // to-do list device idds , taken care of in alarm stats
      nextState.setIn(['devicesInAlarm', 'loading'], false);
    });
  },
  [alarmActions.FETCH_DEVICES_IN_ALARM_WITHOUT_NOTIFICATION_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['devicesInAlarm', 'loading'], false)
    );
  },

  [alarmActions.INVALIDATE_DEVICE_IN_CRITICAL_ALARM] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['devicesInAlarm', 'withoutNotification', action.rowId, 'invalidated'], true)// to-do list device idds
    );
  },

  [alarmActions.FETCH_ALARM_HISTORICAL_SUMMARY] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmHistoricalSummary', 'loading'], true);

    });
  },
  [alarmActions.FETCH_ALARM_HISTORICAL_SUMMARY_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmHistoricalSummary', 'loading'], false);
      nextState.setIn(['alarmHistoricalSummary', 'data'], action.alarms);
    });
  },
  [alarmActions.FETCH_ALARM_HISTORICAL_SUMMARY_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmHistoricalSummary', 'loading'], true);

    });
  },
  [alarmActions.LOAD_ALARMS_FROM_DEVICE] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const fetchDate = new Date();
      action.alarms.map((alarm) => {
        nextState.setIn(['alarms', 'data', alarm.get('id')], {
          record: alarm,
          lastFetch: fetchDate,
        });
      });
      return nextState;
    });
  },

  [alarmActions.FETCH_ALARMS_EXPORT] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['exportAlarms', 'loading'], true);
    });
  },

  [alarmActions.FETCH_ALARMS_EXPORT_SUCCESS] (state, action) {
    const alarms = action.exportAlarms;
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const now = moment();
      const today = now.format('YYYY-MM-DD');

      const blob = new Blob([alarms], { type: 'text/csv;charset=utf-8,' });
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', `${today}-${action.facilityName}-alarms.csv`);
      link.click();
      return nextState.setIn(['exportAlarms', 'loading'], false)
        .setIn(['exportAlarms', 'data'], alarms);
    });
  },

  [alarmActions.FETCH_ALARMS_EXPORT_FAILED] (state) {
    return state.withMutations((nextState) => {
      nextState.setIn(['exportAlarms', 'loading'], false);
    });
  },

  [alarmActions.DISMISS_ALARMS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmActivePage', 'loading'], true);
    });
  },

  [alarmActions.DISMISS_ALARMS_SUCCESS] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      const remainingAlarms = nextState.getIn(['alarmActivePage', 'records'], List()).filter(id => !action.alarmIds.includes(id));

      nextState = nextState.setIn(['alarmActivePage', 'records'], remainingAlarms)
        .setIn(['alarmActivePage', 'loading'], false);

      const total = nextState.getIn(['alarmActivePage', 'total'], 0);

      return nextState.setIn(['alarmActivePage', 'total'], total - action.alarmIds.length);
    });
  },

  [alarmActions.DISMISS_ALARMS_FAILED] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) => {
      nextState.setIn(['alarmActivePage', 'loading'], false);
    });
  },


  [alarmActions.VALIDATE_DEVICE_IN_CRITICAL_ALARM] (state, action) {
    return withScopedMutations(state, action.currentClientId, (nextState) =>
      nextState.setIn(['devicesInAlarm', 'withoutNotification', action.rowId, 'invalidated'], false)
    );
  },

  [CLEAR] (state) {
    return resetState(state);
  },

  [CHANGE_CLIENT] (state) {
    return resetState(state);
  }
});

export default alarmReducer;
