import { getTabId } from 'src/utils/utils';
import createSelector from '../createSelectorCreator';
import { Map } from 'immutable';

export const getFilterState = (state) =>
  state.filter;

export const selectCurrentClientId = (state) => state.authentication.getIn(['tabs', getTabId(), 'clientId']);


export const getSelectedUnit = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedUnit']);
  }
);

export const getSelectedSystem = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedSystem']);
  }
);

export const getSelectedPriority = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedPriority'], null);
  }
);

export const getSelectedCommodity = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedCommodity']);
  }
);

export const getSelectedLocation = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedLocation']);
  }
);

export const getSelectedController = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedController'], {});
  }
);

export const getSelectedSetting = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedSetting']);
  }
);

export const getSelectedDeadLeg = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedDeadLeg'], '');
  }
);
export const getSelectedDeviceGroup = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'selectedDeviceGroup'], '');
  }
);
export const getUnits = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'units']);
  }
);

export const getSystems = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'systems']);
  }
);

export const getCommodities = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'commodities']);
  }
);

export const getLocations = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'locations']);
  }
);

export const getPriorities = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'priorities']);
  }
);

export const getControllers = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'controllers']);
  }
);
export const getDeviceGroups = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return filter.getIn([clientId, 'deviceGroups']);
  }
);
export const getDevicesSelectedFilters = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return Map({
      controller: filter.getIn([clientId, 'selectedController'])?.id,
      controllerTag: filter.getIn([clientId, 'selectedController'])?.tag,
      unit: filter.getIn([clientId, 'selectedUnit'], null),
      system: filter.getIn([clientId, 'selectedSystem'], null),
      priority: filter.getIn([clientId, 'selectedPriority'], null),
      commodity: filter.getIn([clientId, 'selectedCommodity'], null),
      location: filter.getIn([clientId, 'selectedLocation'], null),
      deadleg: filter.getIn([clientId, 'selectedDeadLeg'], ''),
      deviceGroup: filter.getIn([clientId, 'selectedDeviceGroup']),
    });
  }
);
export const getDevicesSelectedFiltersObj = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    return {
      controller: filter.getIn([clientId, 'selectedController'])?.id,
      controllerTag: filter.getIn([clientId, 'selectedController'])?.tag,
      unit: filter.getIn([clientId, 'selectedUnit'], null),
      system: filter.getIn([clientId, 'selectedSystem'], null),
      priority: filter.getIn([clientId, 'selectedPriority'], null),
      commodity: filter.getIn([clientId, 'selectedCommodity'], null),
      location: filter.getIn([clientId, 'selectedLocation'], null),
      deadleg: filter.getIn([clientId, 'selectedDeadLeg'], ''),
      deviceGroup: filter.getIn([clientId, 'selectedDeviceGroup']),
    };
  }
);
export const getNumberOfFiltersApplied = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    let tempCount = 0;
    if (filter.getIn([clientId, 'selectedPriority'], '') !== '') {
      tempCount++;
    }
    if (filter.getIn([clientId, 'selectedSystem'], '') !== '') {
      tempCount++;
    }
    if (filter.getIn([clientId, 'selectedCommodity'], '') !== '') {
      tempCount++;
    }
    if (filter.getIn([clientId, 'selectedLocation'], '') !== '') {
      tempCount++;
    }
    if (filter.getIn([clientId, 'selectedController'])?.id !== undefined) {
      tempCount++;
    }
    if (filter.getIn([clientId, 'selectedDeadLeg'], '') !== '') {
      tempCount++;
    }
    if (filter.getIn([clientId, 'selectedDeviceGroup'], '') !== '') {
      tempCount++;
    }
    return tempCount;
  }
);

export const getIsAllFiltersEmpty = createSelector(
  [getFilterState, selectCurrentClientId],
  (filter, clientId) => {
    const isEmpty = filter.getIn([clientId, 'selectedUnit'], null) == "" &&
      filter.getIn([clientId, 'selectedSystem'], null) == "" &&
      filter.getIn([clientId, 'selectedPriority'], null) == "" &&
      filter.getIn([clientId, 'selectedCommodity'], null) == "" &&
      filter.getIn([clientId, 'selectedLocation'], null) == "" &&
      filter.getIn([clientId, 'selectedDeadLeg'], null) == "" &&
      typeof filter.getIn([clientId, 'selectedController'], null)?.id == "undefined";

    return Boolean(isEmpty);
  }
);
