import { Map, List } from 'immutable';

import createTypedReducer from '../createTypedReducer';
import * as filterActions from './action';
import { CHANGE_CLIENT, CLEAR } from '../authentication/action';

export const initialState = Map({

});

function resetState (state, action) {
  return state.withMutations((nextState) => {
    if (action.currentClientId) {
      nextState
        .setIn([action.currentClientId, 'units'], List())
        .setIn([action.currentClientId, 'systems'], List())
        .setIn([action.currentClientId, 'commodities'], List())
        .setIn([action.currentClientId, 'priorities'], List())
        .setIn([action.currentClientId, 'controllers'], List())
        .setIn([action.currentClientId, 'selectedUnit'], '')
        .setIn([action.currentClientId, 'selectedSystem'], '')
        .setIn([action.currentClientId, 'selectedCommodity'], '')
        .setIn([action.currentClientId, 'selectedLocation'], '')
        .setIn([action.currentClientId, 'selectedPriority'], '')
        .setIn([action.currentClientId, 'selectedController'], {})
        .setIn([action.currentClientId, 'selectedDeadLeg'], '');
    }
  });
}

export const filterReducer = createTypedReducer(initialState, {
  [filterActions.SET_SELECTED_UNIT] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedUnit'], action.unit || '')
    );
  },

  [filterActions.SET_SELECTED_SYSTEM] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedSystem'], action.system || '')
    );
  },

  [filterActions.SET_SELECTED_COMMODITY] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedCommodity'], action.commodity || '')
    );
  },

  [filterActions.SET_SELECTED_LOCATION] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedLocation'], action.location || '')
    );
  },

  [filterActions.SET_SELECTED_PRIORITY] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedPriority'], action.priority || '')
    );
  },

  [filterActions.SET_SELECTED_CONTROLLER] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedController'], action.controller || '')
    );
  },
  [filterActions.SET_SELECTED_DEVICE_GROUP] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedDeviceGroup'], action.deviceGroup|| '')
    );
  },
  [filterActions.SET_SELECTED_DEAD_LEG] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedDeadLeg'], action.deadLeg || '')
    );
  },

  [filterActions.SET_SELECTED_SETTING] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'selectedSetting'], action.setting || '')
    );
  },

  [filterActions.FETCH_UNITS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'units'], action.units)
    );
  },

  [filterActions.FETCH_SYSTEMS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'systems'], action.systems)
    );
  },

  [filterActions.FETCH_COMMODITIES_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'commodities'], action.commodities)
    );
  },

  [filterActions.FETCH_LOCATIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'locations'], action.locations)
    );
  },

  [filterActions.FETCH_PRIORITIES_SUCCESS] (state, action) {
    return state.withMutations((nextState) =>
      nextState.setIn([action.currentClientId, 'priorities'], action.priorities)
    );
  },

  [filterActions.FETCH_DEVICE_GROUPS_OPTIONS_SUCCESS] (state, action) {
    return state.withMutations((nextState) => {
      nextState.setIn([action.currentClientId, 'deviceGroups'], action.deviceGroups || '');
    });
  },
  [CLEAR] (state, action) {
    return resetState(state, action);
  },

  [CHANGE_CLIENT] (state, action) {
    return resetState(state, action);
  }
});

export default filterReducer;
