import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { getTabId } from 'src/utils/utils';

export const selectCurrentClientId = (state) => state.authentication.getIn(['tabs', getTabId(), 'clientId']);
export const getBatchScheduleState = (state) => ({
  state: state.batchSchedule,
  getIn: (keyPath, defaultValue = null) => {
    const clientId = state.authentication.getIn(['tabs', getTabId(), 'clientId']);
    if (clientId && state.batchSchedule) {
      return state.batchSchedule.getIn([keyPath[0], clientId, ...keyPath.slice(1)], defaultValue);
    }
    return defaultValue;
  }
});
export const getBatchScheduleStateBasic = (state) =>
  state.batchSchedule;

export const getBatchSchedules = tab =>  createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {

    const allData = batchSchedule?.getIn(['batches', 'data'], List());
    const pageIds = batchSchedule?.getIn([tab, 'records'], List());

    let pageData = List();
    pageIds.map((id) => {
      if (allData.getIn([id, 'record', 'validated_at']) || tab === 'device-groups') {
        pageData = pageData.push(allData.getIn([id, 'record']));

      }
    });
    return List(pageData);
  }
);

export const getLoadingBatchSchedules = createSelector(
  [getBatchScheduleStateBasic, selectCurrentClientId],
  (batchSchedule, clientId) => {
    return batchSchedule.getIn(['loading', clientId], false);
  }
);

export const getNumBatchSchedulesPages = (tab) => createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {
    return batchSchedule.getIn([tab, 'totalPages'], 0);
  }
);

export const getTotalBatchSchedules  = (tab) => createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {
    return batchSchedule.getIn([tab, 'total'], 0);
  }
);

export const getBatchStatus = (batchId) => createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {
    return batchSchedule.getIn(['batches', 'data', batchId, 'record', 'status'], Map());
  }
);


export const getDevicesBatchLastUpdate = batchId => createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {
    return batchSchedule.getIn(['batches', 'batch', batchId, 'lastUpdate'], null);
  }
);

export const getBatchRecipeId = batchId =>  createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {
    return batchSchedule.getIn(['batches', 'data', batchId, 'record', 'recipe_id'], null);
  }
);

export const getBatch = batchId =>  createSelector(
  [getBatchScheduleState],
  (batchSchedule) => {
    return batchSchedule.getIn(['batches', 'data', batchId, 'record'], null);
  }
);
